/**
 * 系统中所有权限，必须写注释
 *
 * 多个中文使用同一权限单词时，在注释中以 / 表明
 * 枚举名单词根据产品提供的中文(即：xx权限)，发至内部研发群讨论，禁止自行添加
 *
 * 权限对应的按钮文档（项目中的 README.md）
 */
export enum AccessEnum {
  /** 查询 */
  Query = 'query',
  /** 设置配送方案 */
  SetDeliveryPlan = 'setdeliveryplan',
  /** 设置销售门店 */
  SetSaleStore = 'setsalestore',
  /** 导出 */
  Export = 'export',
  /** 导入 */
  Import = 'import',
  /** 新增编辑/新增任务/操作/提交订货/重置/维护 */
  NewEdit = 'newedit',
  /** 删除 */
  Delete = 'delete',
  /** 设置分类商品 */
  SetGoods = 'setgoods',
  /** 导出授权码 */
  ExportLicence = 'exportlicence',
  /** 查看授权码 */
  QueryLicence = 'querylicence',
  /** 移除/激活 */
  RemoveActivation = 'removeactivation',
  /** 维护模板 */
  MaintainTemplate = 'maintaintemplate',
  /** 设置权限 */
  SetFunctionPermissions = 'setfunctionpermissions',
  /** 设置 */
  Config = 'config',
  /** 移动员工 */
  MobileEmployees = 'mobileemployees',
  /** 冻结解冻 */
  FreezeThaw = 'freezethaw',
  /** 冻结 */
  Freeze = 'freeze',
  /** 挂失 */
  LossCard = 'losscard',
  /** 批量制卡 */
  BatchCreate = 'batchCreate',
  /** 启用/停用 */
  EnableDisable = 'enabledisable',
  /** 设置售卖渠道 */
  SetSaleChannel = 'setsalechannel',
  /** 复制 */
  Copy = 'copy',
  /** 发售 */
  OnSale = 'onsale',
  /** 制卡 */
  MakeCard = 'makecard',
  /** 审核 */
  Audit = 'audit',
  /** 打印 */
  Print = 'print',
  /** 截止 */
  End = 'end',
  /** 直配提单 */
  WorkTwo = 'worktwo',
  /** 仓配提单 */
  WorkOne = 'workone',
  /** 全部提单 */
  WorkAll = 'workall',
  /** 提交 */
  Commit = 'commit',
  /** 发货/收货/退货，优先用下面的精准匹配 */
  Confirm = 'confirm',
  /** 发货 */
  SendGoods = 'sendgoods',
  /** 收货 */
  ReceiptGoods = 'receiptgoods',
  /** 退货 */
  ReturnGoods = 'returngoods',
  /** 登记发票 */
  Invoice = 'invoice',
  /** 作废任务 */
  Abort = 'abort',
  /** 作废 */
  Invalidate = 'invalidate',
  /** 完成核对 */
  Check = 'check',
  /** 盘点作业 */
  CheckWork = 'checkWork',
  /** 生成结算单 */
  GenerateBill = 'generatebill',
  /** 充值 */
  Recharge = 'recharge',
  /** 补卡 */
  CardReplacement = 'cardreplacement',
  /** 撤回/返还 */
  Cancel = 'cancel',
  /** 付款/确认付款 */
  Pay = 'pay',
  /** 收款 */
  ReceiptPayment = 'receiptpayment',
  /** 新增编辑（这是后端的锅，优先用 NewEdit） */
  Edit = 'edit',
  /** 发布 */
  Publish = 'publish',
  /** 上架下架 */
  PutawayAndSoldOut = 'putawayandsoldout',
  /** 信用额度调整 */
  AdjustCreditLimit = 'adjustcreditlimit',
  /** 保证金调整 */
  AdjustDeposit = 'adjustdeposit',
  /** 绑定解绑 */
  BindUnbind = 'bindunbind',
  /** 处理退款权限 */
  Refund = 'refund',
  /** 管理订单 */
  ManageOrder = 'manageorder',
  /** 设置柜组 */
  SetCounterUnit = 'setcounterunit',
  /**传秤权限 */
  TransScales = 'transScales',
  /**开通权限 */
  Open = 'open',
  /**允许修改进价 */
  AllowModifyInPrice = 'allowModifyInPrice',
  /**允许修改退价价 */
  AllowModifyReturnPrice = 'allowModifyReturnPrice',
  /** 立即同步 */
  Sync = 'sync',
  /** 配送管理 */
  DeliveryManage = 'deliveryManage',
  /**供应商选择 */
  SupplierSelect = 'supplierSelect',
  /**试算 */
  Trial = 'trial',
  /** 回复 */
  Reply = 'reply',
  /**供应商确认 */
  SupplierConfirm = 'supplierConfirm',
  /** 设置最小起订量 */
  SetMinOrderQty = 'setMinOrderQty',
  /** 去越库 */
  CrossWarehouse = 'crossWarehouse',
  /** 提示 */
  Tips = 'tips',
  /** 门店导入 */
  StroeImport = 'stroeImport',
  /** 仓库 分货 */
  Distribute = 'distribute',
  /** 仓库分货 重分 */
  Repartition = 'repartition',
  /** 商品档案 设置协议 */
  Setprotocol = 'setprotocol',
  /** 波次操作 */
  WavesManager = 'wavesmanager',
  /** 设备管理 */
  EquipmentManager = 'equipmentmanager'
}

export enum AccessRolePricePermEnum {
  //wosheading#cx.local.role.price#inPrice
  /**
   * 该死接口返回少了个price少了个e
   */
  /** 进价 */
  CxLocalRolePriceInPrice = 'wosheading#cx.local.role.pric#inPrice',
  /** 成本价 */
  CxLocalRolePriceCostPrice = 'wosheading#cx.local.role.pric#costPrice'
}
