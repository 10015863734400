/**
 * 页面路径管理
 * 层级为 3 层
 *
 * 规则依然优先以贴近接口路径为准
 * 接口路径不满足时，按一二级菜单
 *
 * 若模块的页面只有一个（如总部设置下的各个模块），且页面长得不像常规列表，则命名为 index
 *
 * 路由配置、页面跳转路径均由此引用
 *
 * ！！！注意：页面跳转路径引用此处，禁止自行写死路径，即 history.push(headquartersBackend.xxx.xxx.detail)
 *
 * 权限配置说明 ----------
 *
 * 模块下同时需要维护模块权限，规则为: { _access: createAccessMap('/总部或门店后台/一层标识/二层标识') }
 * 【查询】权限不需要配置
 * 注：首页这类特殊的只写2层即可
 *
 */

/* 控制台捞全部权限
var aa = [...temp1.hqList, ...temp1.storeList].filter(i => i.type === 'perm').map(i => ({ id: i.id, name: i.name }))
console.log(JSON.stringify(aa))
 */

import Access from '@/components/Access';
import { AccessEnum } from '@/dataModel/access/enum';
import { createAccessMap } from '@/utils/access';

const isDev = process.env.NODE_ENV === 'development';

/** 总部后台 */
export const headquartersBackend = {
  /**总部门店 */
  store: {
    /** 门店档案 */
    store: {
      _access: createAccessMap('/headquarters-backend/store/list', [
        AccessEnum.NewEdit,
        AccessEnum.Import,
        AccessEnum.Export,
        AccessEnum.EnableDisable
      ]),
      list: '/headquarters-backend/store/list',
      edit: '/headquarters-backend/store/edit',
      add: '/headquarters-backend/store/add',
      detail: '/headquarters-backend/store/detail'
    },
    /**店型 */
    storeModel: {
      _access: createAccessMap('/headquarters-backend/store-model/list', [AccessEnum.NewEdit]),
      list: '/headquarters-backend/store-model/list'
    },
    /** 门店柜组 */
    mdataCabinet: {
      _access: createAccessMap('/headquarters-backend/store/mdata-cabinet', [AccessEnum.NewEdit]),
      list: '/headquarters-backend/store/mdata-cabinet',
      configs: '/headquarters-backend/store/mdata-cabinet/configs'
    },

    /** 网店管理 */
    onlineStore: {
      _access: createAccessMap('/headquarters-backend/store/online-store', [
        AccessEnum.NewEdit,
        AccessEnum.BindUnbind,
        AccessEnum.Import,
        AccessEnum.DeliveryManage
      ]),
      list: '/headquarters-backend/store/online-store'
    },
    /** 电子秤 */
    ews: {
      _access: createAccessMap('/headquarters-backend/store/ews', [AccessEnum.Delete, AccessEnum.MaintainTemplate]),
      list: '/headquarters-backend/store/ews',
      managementList: '/headquarters-backend/store/ews/management',
      managementDetail: '/headquarters-backend/store/ews-detail',
      templateList: '/headquarters-backend/store/ews/template',
      ruleSpeacil: '/headquarters-backend/store/ews/ruleSpeacil',
      templateDetail: '/headquarters-backend/store/ews-template/detail',
      add: '/headquarters-backend/store/ews-template/add',
      addDahua: '/headquarters-backend/store/ews-template/add/dahua',
      addToledo: '/headquarters-backend/store/ews-template/add/toledo',
      addAclas: '/headquarters-backend/store/ews-template/add/aclas'
    },
    /** 价签模版 */
    tag: {
      _access: createAccessMap('/headquarters-backend/store/tag/dashboard', [AccessEnum.MaintainTemplate]),
      index: '/headquarters-backend/store/tag',
      dashboard: '/headquarters-backend/store/tag/dashboard',
      add: '/headquarters-backend/store/tag/add'
    },
    /** 总部-价签打印 */
    priceTag: {
      _access: createAccessMap('/headquarters-backend/store/price-tag', [
        AccessEnum.Print,
        AccessEnum.Delete,
        AccessEnum.NewEdit,
        AccessEnum.Import
      ]),
      index: '/headquarters-backend/store/price-tag',
      preview: '/headquarters-backend/store/price-tag/preview-print'
    },
    /** 门店退货通知 */
    returnNotify: {
      _access: createAccessMap('/headquarters-backend/store/return-notify', [
        AccessEnum.NewEdit,
        AccessEnum.Delete,
        AccessEnum.End
      ]),
      list: '/headquarters-backend/store/return-notify',
      add: '/headquarters-backend/store/return-notify/add',
      edit: '/headquarters-backend/store/return-notify/edit',
      detail: '/headquarters-backend/store/return-notify/detail'
    },

    /** 门店退货申请 */
    storeReturn: {
      _access: createAccessMap('/headquarters-backend/store/store-return/list', [AccessEnum.Audit]),
      list: '/headquarters-backend/store/store-return/list',
      detail: '/headquarters-backend/store/store-return/detail'
    },
    /** 门店报损 */
    storeLoss: {
      _access: createAccessMap('/headquarters-backend/store/store-loss', [AccessEnum.Audit, AccessEnum.Print]),
      list: '/headquarters-backend/store/store-loss',
      waitingAuditDetail: '/headquarters-backend/store/store-loss/waiting-audit-detail',
      historyAuditDetail: '/headquarters-backend/store/store-loss/history-audit-detail'
    },
    /** 门店报溢 */
    storeGain: {
      _access: createAccessMap('/headquarters-backend/store/store-gain', [AccessEnum.Audit, AccessEnum.Print]),
      list: '/headquarters-backend/store/store-gain',
      waitingAuditDetail: '/headquarters-backend/store/store-gain/waiting-audit-detail',
      historyAuditDetail: '/headquarters-backend/store/store-gain/history-audit-detail'
    },
    /** 收银机 */
    cashRegister: {
      _access: createAccessMap('/headquarters-backend/store/cash-register', [
        AccessEnum.RemoveActivation,
        AccessEnum.Delete,
        AccessEnum.QueryLicence,
        AccessEnum.ExportLicence
      ]),
      list: '/headquarters-backend/store/cash-register',
      cashManage: '/headquarters-backend/store/cash-register/cash-manage',
      authorization: '/headquarters-backend/store/cash-register/authorization',
      cashMonitor: '/headquarters-backend/store/cash-register/cash-monitor',
      distribute: '/headquarters-backend/store/cash-register/distribute',
      distributeNew: '/headquarters-backend/store/cash-register/distribute-new',
      cashDetail: '/headquarters-backend/store/cash-detail',
      monitorDetail: '/headquarters-backend/store/monitor-detail'
    },
    /** 手持设备 */
    holder: {
      _access: createAccessMap('/headquarters-backend/store/holder', [AccessEnum.Export, AccessEnum.RemoveActivation]),
      list: '/headquarters-backend/store/holder',
      detail: '/headquarters-backend/store/holder/detail'
    },
    /** 电子价签 */
    electronic: {
      _access: createAccessMap('/headquarters-backend/store/electronic', [AccessEnum.EnableDisable, AccessEnum.Sync]),
      list: '/headquarters-backend/store/electronic'
    },
    /** 门店促销 */
    storePromoteSales: {
      _access: createAccessMap('/headquarters-backend/store/store-promote-sales/list', [
        AccessEnum.NewEdit,
        AccessEnum.Copy,
        AccessEnum.Abort,
        AccessEnum.Delete
      ]),
      list: '/headquarters-backend/store/store-promote-sales/list',
      detail: '/headquarters-backend/store/store-promote-sales/detail',
      edit: '/headquarters-backend/store/store-promote-sales/edit',
      copy: '/headquarters-backend/store/store-promote-sales/copy',
      add: '/headquarters-backend/store/store-promote-sales/add',
      discountAdd: '/headquarters-backend/store/store-promote-sales/discount/add',
      discountDetail: '/headquarters-backend/store/store-promote-sales/discount/detail',
      discountEdit: '/headquarters-backend/store/store-promote-sales/discount/edit',
      discountCopy: '/headquarters-backend/store/store-promote-sales/discount/copy'
    },
    /** 小票配置 */
    ticketTemplate: {
      _access: createAccessMap('/headquarters-backend/store/ticket-template/list', [
        AccessEnum.Config,
        AccessEnum.NewEdit,
        AccessEnum.Copy
      ]),
      list: '/headquarters-backend/store/ticket-template/list',
      edit: '/headquarters-backend/store/ticket-template/edit',
      add: '/headquarters-backend/store/ticket-template/add'
    }
  },
  /** 零售 */
  retail: {
    /** 小票模版 */
    ticketTemplate: {
      _access: createAccessMap('/headquarters-backend/retail/ticketTemplate', [AccessEnum.Config, AccessEnum.Delete]),
      list: '/headquarters-backend/retail/ticket-template',
      add: '/headquarters-backend/retail/ticket-template/add',
      edit: '/headquarters-backend/retail/ticket-template/edit',
      explain: '/headquarters-backend/retail/ticket-template/explain'
    },
    /** 销售面板 */
    sale: {
      _access: createAccessMap('/headquarters-backend/retail/sale', [AccessEnum.Config]),
      list: '/headquarters-backend/retail/sale',
      edit: '/headquarters-backend/retail/sale/edit',
      detail: '/headquarters-backend/retail/sale/detail',
      saleSelfEdit: '/headquarters-backend/retail/sale-self/edit',
      saleSelfDetail: '/headquarters-backend/retail/sale-self/detail'
    },
    /** 副屏设置 */
    secscreen: {
      _access: createAccessMap('/headquarters-backend/retail/secscreen/list', [AccessEnum.Config]),
      list: '/headquarters-backend/retail/secscreen/list',
      detail: '/headquarters-backend/retail/secscreen/detail',
      add: '/headquarters-backend/retail/secscreen/add',
      edit: '/headquarters-backend/retail/secscreen/edit',
      materialManage: '/headquarters-backend/retail/secscreen/material-manage'
    },
    /** 付款方式 */
    paymentMethod: {
      _access: createAccessMap('/headquarters-backend/retail/payment-method/list', [AccessEnum.Config]),
      list: '/headquarters-backend/retail/payment-method/list'
    },
    /** 收银机参数设置 */
    posSettings: {
      _access: createAccessMap('/headquarters-backend/retail/pos-settings', [AccessEnum.Config]),
      index: '/headquarters-backend/retail/pos-settings'
    },
    /** 整单折扣规则 */
    wholeOrderDiscountRules: {
      _access: createAccessMap('/headquarters-backend/retail/whole-order-discount-rules/list', [
        AccessEnum.Config,
        AccessEnum.Export,
        AccessEnum.Import
      ]),
      list: '/headquarters-backend/retail/whole-order-discount-rules/list',
      detail: '/headquarters-backend/retail/whole-order-discount-rules/detail'
    },
    /** 仓库商品调价 */
    adjustPrice: {
      _access: createAccessMap('/headquarters-backend/retail/adjust-price', [
        AccessEnum.NewEdit,
        AccessEnum.Delete,
        AccessEnum.Import,
        AccessEnum.Export,
        AccessEnum.Invalidate,
        AccessEnum.Audit,
        AccessEnum.Copy
      ]),
      list: '/headquarters-backend/retail/adjust-price',
      add: '/headquarters-backend/retail/adjust-price/add',
      detail: '/headquarters-backend/retail/adjust-price/detail'
    },
    /**订单管理 */
    orderManagement: {
      _access: createAccessMap('/headquarters-backend/retail/order-management', [AccessEnum.Config, AccessEnum.Export]),
      list: '/headquarters-backend/retail/order-management',
      detail: '/headquarters-backend/retail/order-management/detail'
    },
    /**退款管理 */
    reformManagement: {
      _access: createAccessMap('/headquarters-backend/retail/reform-management', [AccessEnum.Refund]),
      list: '/headquarters-backend/retail/reform-management',
      detail: '/headquarters-backend/retail/reform-management/detail'
    },
    /**交易流水 */
    businessRecord: {
      _access: createAccessMap('/headquarters-backend/retail/business-record', [AccessEnum.Export]),
      list: '/headquarters-backend/retail/business-record'
    },
    /**键盘设置 */
    keyboardSettings: {
      _access: createAccessMap('/headquarters-backend/retail/keyboard-settings', [
        AccessEnum.NewEdit,
        AccessEnum.Print
      ]),
      list: '/headquarters-backend/retail/keyboard-settings'
    },
    onlineOrderComment: {
      _access: createAccessMap('/headquarters-backend/retail/online-order-comment', [
        AccessEnum.Export,
        AccessEnum.Reply
      ]),
      list: '/headquarters-backend/retail/online-order-comment'
    },
    /**员工统计拣货查询 */
    queryStat: {
      _access: createAccessMap('/headquarters-backend/retail/query-stat', [AccessEnum.Export]),
      list: '/headquarters-backend/retail/query-stat'
    },
    /**POS订单*/
    posOrder: {
      _access: createAccessMap('/headquarters-backend/retail/pos-order', [AccessEnum.Export]),
      list: '/headquarters-backend/retail/pos-order',
      detail: '/headquarters-backend/retail/pos-order/detail',
      retreatDetail: '/headquarters-backend/retail/pos-order/retreatdetail'
    }
    /** 收银流水查询 */
    /*   cashierSearch: {
        _access: createAccessMap('/headquarters-backend/retail/cashier-search', [AccessEnum.Config]),
        index: '/headquarters-backend/retail/cashier-search'
      }, */
  },
};

/** 门店后台 */
export const storeBackend = {
  /** 门店后台-设备 */
  equipment: {
    /** 价签打印 */
    tagPrint: {
      _access: createAccessMap('/store-backend/equipment/tag-print', [AccessEnum.Print, AccessEnum.MaintainTemplate]),
      index: '/store-backend/equipment/tag-print',
      preview: '/store-backend/equipment/tag-print/preview-print'
    },
    /** 销售面板 */
    sale: {
      _access: createAccessMap('/store-backend/equipment/sale', [AccessEnum.MaintainTemplate, AccessEnum.Delete]),
      list: '/store-backend/equipment/sale',
      edit: '/store-backend/equipment/sale/edit',
      detail: '/store-backend/equipment/sale/detail',
      saleSelfEdit: '/store-backend/equipment/sale-self/edit',
      saleSelfDetail: '/store-backend/equipment/sale-self/detail'
    },
    /** 小票设置 */
    ticketStore: {
      _access: createAccessMap('/store-backend/equipment/ticket-store', [AccessEnum.Config]),
      index: '/store-backend/equipment/ticket-store'
    },
    /** 电子秤 */
    ews: {
      _access: createAccessMap('/store-backend/equipment/ews/list', [
        AccessEnum.NewEdit,
        AccessEnum.Delete,
        AccessEnum.TransScales
      ]),
      list: '/store-backend/equipment/ews/list',
      detail: '/store-backend/equipment/ews/list/detail',
      add: '/store-backend/equipment/ews/add',
      addDahua: '/store-backend/equipment/ews/add/dahua',
      addToledo: '/store-backend/equipment/ews/add/toledo',
      addAclas: '/store-backend/equipment/ews/add/aclas'
    }
  },
  system: {
    /**pos操作日志 */
    posLog: {
      _access: createAccessMap('/store-backend/system/pos-log', [AccessEnum.Export]),
      index: '/store-backend/system/pos-log'
    }
  }
};

/**供应商协同平台 */
export const supplierBackend = {
  /**订单 */
  order: {
    purchaseOrder: {
      list: '/supplier-backend/order/purchase-order',
      detail: '/supplier-backend/order/purchase-order/detail'
    }
  },
  /**数据 */
  information: {
    jointGoodsInv: {
      index: '/supplier-backend/information/joint-goods-inv'
    },
    jointGoodsSale: {
      index: '/supplier-backend/information/joint-goods-sale'
    }
  }
};

if (isDev) {
  console.warn(' 总部 __权限____  ', headquartersBackend);
  console.warn(' 门店 __权限____  ', storeBackend);
}
