import { dynamicWrapper, createRouteConfig } from '@/utils/utils';
import { storeBackend } from '../routers.map';

const { tagPrint, sale, ticketStore, ews } = storeBackend.equipment;

const routerConfig = (app: any) => [
  {
    ...createRouteConfig('价签打印', tagPrint.index),
    component: dynamicWrapper(app, [], () => import('@/pages/StoreBackend/Equipment/TagPrint'))
  },
  {
    ...createRouteConfig('价签预览', tagPrint.preview),
    component: dynamicWrapper(
      app,
      [import('@/models/headquartersBackend/store/tag')],
      () => import('@/pages/StoreBackend/Equipment/TagPrint/components/PreviewPrint')
    )
  },
  {
    ...createRouteConfig('销售面板设置', sale.list),
    component: dynamicWrapper(app, [], () => import('@/pages/StoreBackend/Equipment/Sale/List'))
  },
  {
    ...createRouteConfig('设置模板商品', sale.edit),
    component: dynamicWrapper(app, [], () => import('@/pages/StoreBackend/Equipment/Sale/Edit'))
  },
  {
    ...createRouteConfig('模板详情', sale.detail),
    component: dynamicWrapper(app, [], () => import('@/pages/StoreBackend/Equipment/Sale/Detail'))
  },
  {
    ...createRouteConfig('设置模板商品', sale.saleSelfEdit),
    component: dynamicWrapper(app, [], () => import('@/pages/StoreBackend/Equipment/Sale/SaleSelfEdit'))
  },
  {
    ...createRouteConfig('模板详情', sale.saleSelfDetail),
    component: dynamicWrapper(app, [], () => import('@/pages/StoreBackend/Equipment/Sale/SaleSelfDetail'))
  },
  {
    ...createRouteConfig('小票设置', ticketStore.index),
    component: dynamicWrapper(app, [], () => import('@/pages/StoreBackend/Equipment/TicketStore'))
  },

  {
    ...createRouteConfig('小票设置', ticketStore.index),
    component: dynamicWrapper(app, [], () => import('@/pages/StoreBackend/Equipment/TicketStore'))
  },
  {
    ...createRouteConfig('小票设置', ticketStore.index),
    component: dynamicWrapper(app, [], () => import('@/pages/StoreBackend/Equipment/TicketStore'))
  },
  {
    ...createRouteConfig('电子秤管理', ews.list),
    component: dynamicWrapper(
      app,
      [import('@/models/Equipment/EwsList')],
      () => import('@/pages/StoreBackend/Equipment/Ews/List')
    )
  },
  {
    ...createRouteConfig('新增电子秤', ews.add),
    exact: false,
    component: dynamicWrapper(
      app,
      [import('@/models/Equipment/EwsAdd')],
      () => import('@/pages/StoreBackend/Equipment/Ews/Add')
    ),
    children: [
      { path: '/dahua', name: '新增电子秤', exact: true },
      { path: '/toledo', name: '新增电子秤', exact: true },
      { path: '/aclas', name: '新增电子秤', exact: true },
      { path: '/rongta', name: '新增电子秤', exact: true }
    ]
  },
  {
    ...createRouteConfig('电子秤详情', ews.detail),
    component: dynamicWrapper(app, [], () => import('@/pages/StoreBackend/Equipment/Ews/Detail'))
  }
];
export default routerConfig;
