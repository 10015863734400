import { createRouteConfig, dynamicWrapper } from '@/utils/utils';
import { headquartersBackend, storeBackend } from '../routers.map';

const {
  store,
  tag,
  returnNotify,
  storeReturn,
  storeLoss,
  storeGain,
  ews,
  cashRegister,
  onlineStore,
  holder,
  electronic,
  mdataCabinet,
  storeModel,
  priceTag,
  storePromoteSales,
  ticketTemplate
} = headquartersBackend.store;
const { tagPrint } = storeBackend.equipment;
const routerConfig = (app: any) => [
  {
    ...createRouteConfig('门店', store.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/Store/List'))
  },
  {
    ...createRouteConfig('门店详情', store.detail),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/Store/Detail'))
  },
  {
    ...createRouteConfig('新增门店', store.add),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/Store/Edit'))
  },
  {
    ...createRouteConfig('编辑门店', store.edit),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/Store/Edit'))
  },
  {
    ...createRouteConfig('店型', storeModel.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/StoreModel/List'))
  },
  // 门店设备
  {
    ...createRouteConfig('电子秤', ews.list, [
      { path: '/management', name: '电子秤管理', exact: true },
      { path: '/template', name: '电子秤模板', exact: true },
      { path: '/ruleSpeacil', name: '秤码规则', exact: true }
    ]),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/Ews'))
  },
  {
    ...createRouteConfig('新增电子秤模板', ews.add, [
      { path: '/dahua', name: '新增电子秤模板', exact: true },
      { path: '/toledo', name: '新增电子秤模板', exact: true },
      { path: 'aclas', name: '新增电子秤模板', exact: true }
    ]),
    component: dynamicWrapper(
      app,
      [import('@/models/Store/EwsTemplateAdd')],
      () => import('@/pages/HeadquartersBackend/Store/EwsTemplateAdd')
    )
  },
  {
    ...createRouteConfig('电子秤模板详情', ews.templateDetail),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/EwsTemplateDetail'))
  },
  {
    ...createRouteConfig('电子秤详情', ews.managementDetail),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/EwsDetail'))
  },
  {
    ...createRouteConfig('价签模板', tag.dashboard),
    component: dynamicWrapper(
      app,
      [import('@/models/headquartersBackend/store/tag')],
      () => import('@/pages/HeadquartersBackend/Store/TagDashboard')
    )
  },
  {
    ...createRouteConfig('总部价签', priceTag.index),
    component: dynamicWrapper(
      app,
      [import('@/models/headquartersBackend/store/tag')],
      () => import('@/pages/HeadquartersBackend/Store/PriceTag')
    )
  },
  {
    ...createRouteConfig('总部价签预览', priceTag.preview),
    component: dynamicWrapper(
      app,
      [import('@/models/headquartersBackend/store/tag')],
      () => import('@/pages/HeadquartersBackend/Store/PriceTag/components/PreviewPrint')
    )
  },
  {
    ...createRouteConfig('新增价签模板', tag.add),
    component: dynamicWrapper(
      app,
      [import('@/models/headquartersBackend/store/tag')],
      () => import('@/pages/HeadquartersBackend/Store/TagAdd')
    )
  },
  {
    ...createRouteConfig('门店退货通知', returnNotify.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/ReturnNotify/List'))
  },
  {
    ...createRouteConfig('新增退货通知单', returnNotify.add),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/ReturnNotify/Modify'))
  },
  {
    ...createRouteConfig('编辑退货通知单', returnNotify.edit),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/ReturnNotify/Modify'))
  },
  {
    ...createRouteConfig('退货通知详情', returnNotify.detail),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/ReturnNotify/Detail'))
  },
  {
    ...createRouteConfig('门店退货申请', storeReturn.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/StoreReturn/List'))
  },
  {
    ...createRouteConfig('退货申请详情', storeReturn.detail),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/StoreReturn/Detail'))
  },
  {
    ...createRouteConfig('收银机', cashRegister.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/CashRegister/List')),
    children: [
      createRouteConfig('收银机管理', cashRegister.cashManage),
      createRouteConfig('授权码管理', cashRegister.authorization),
      createRouteConfig('收银机监控', cashRegister.cashMonitor),
      createRouteConfig('资料下发', cashRegister.distribute),
      createRouteConfig('资料下发（新）', cashRegister.distributeNew)
    ],
    exact: false
  },
  {
    ...createRouteConfig('收银机详情', cashRegister.cashDetail),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/CashRegister/CashDetail'))
  },
  {
    ...createRouteConfig('收银机监控详情', cashRegister.monitorDetail),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/CashRegister/MonitorDetail'))
  },
  {
    ...createRouteConfig('网店管理', onlineStore.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/OnlineStore'))
  },
  {
    ...createRouteConfig('门店促销', storePromoteSales.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/StorePromoteSales'))
  },
  {
    ...createRouteConfig('编辑出清立折', storePromoteSales.edit),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/StorePromoteSales/Edit'))
  },
  {
    ...createRouteConfig('新增出清立折', storePromoteSales.add),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/StorePromoteSales/Edit'))
  },
  {
    ...createRouteConfig('复制出清立折', storePromoteSales.copy),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/StorePromoteSales/Edit'))
  },
  {
    ...createRouteConfig('出清立折详情', storePromoteSales.detail),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/StorePromoteSales/Detail'))
  },
  {
    ...createRouteConfig('新增限时活动', storePromoteSales.discountAdd),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/StorePromoteSales/Discount/Add'))
  },
  {
    ...createRouteConfig('编辑限时活动', storePromoteSales.discountEdit),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/StorePromoteSales/Discount/Add'))
  },
  {
    ...createRouteConfig('复制限时活动', storePromoteSales.discountCopy),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/StorePromoteSales/Discount/Add'))
  },
  {
    ...createRouteConfig('限时活动详情', storePromoteSales.discountDetail),
    component: dynamicWrapper(
      app,
      [],
      () => import('@/pages/HeadquartersBackend/Store/StorePromoteSales/Discount/Detail')
    )
  },
  {
    ...createRouteConfig('小票配置', ticketTemplate.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/TicketTemplate/List'))
  },
  {
    ...createRouteConfig('编辑小票配置', ticketTemplate.edit),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/TicketTemplate/Edit'))
  },
  {
    ...createRouteConfig('新增小票配置', ticketTemplate.add),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Store/TicketTemplate/Edit'))
  }
];

export default routerConfig;
