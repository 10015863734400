export const contentWidth = 'Fluid';
export const menu = {
  disableLocal: true,
};
export const title = '微盟微前端';
export const iconfontUrl = '';

export default {
  contentWidth: 'Fluid',
  menu: {
    disableLocal: true,
  },
  title: '微盟微前端',
  iconfontUrl: '',
};
