export enum DictKeyEnum {
  AbnormalGoodsOlderType = 'abnormalGoodsOlderType',
  /** 账户所有者类型 */
  AccountOwnerType = 'accountOwnerType',
  AccountStatus = 'accountStatus',
  AdapterType = 'adapterType',
  /** 调价类型 */
  AdjustPriceSource = 'adjustPriceSource',
  /** 价格试算生成调价单任务状态 */
  AdjustPriceTaskStatus = 'adjustPriceTaskStatus',
  /** 全部平台必填信息缺失 */
  AllPlatformRequiredLack = 'allPlatformRequiredLack',
  /** 配货通知单详情下游链路类型 */
  AllocOrderDownstreamTypeQuery = 'allocOrderDownstreamTypeQuery',
  /** 下游链路类型 */
  AllocOrderDownstreamTypes = 'allocOrderDownstreamTypes',
  AllocOutStatusEnum = 'allocOutStatusEnum',
  AllocOutType = 'allocOutType',
  /** 业务设置-进价设置 */
  AllowInPriceRule = 'allowInPriceRule',
  AllowMemberRule = 'allowMemberRule',
  AppInfo = 'appInfo',
  /** 应用中心显示模式 */
  AppInfoShowType = 'appInfoShowType',
  AuthorizationStatus = 'authorizationStatus',
  BackGroundLogStatus = 'backGroundLogStatus',
  BackstageModule = 'backstageModule',
  BackstageSource = 'backstageSource',
  /** 储值业务类型 */
  BalanceBusinessClass = 'balanceBusinessClass',
  /** 储值渠道来源 */
  BalanceChannel = 'balanceChannel',
  BankAccountBusinessType = 'bankAccountBusinessType',
  BarcodePrintStatus = 'barcodePrintStatus',
  BarcodePrintType = 'barcodePrintType',
  BaseValidateResult = 'baseValidateResult',
  BaseValidateTarget = 'baseValidateTarget',
  /** 批量任务单据类型 */
  BatchTaskBillClass = 'batchTaskBillClass',
  BiReportEnToCnTemplate = 'biReportEnToCNTemplate',
  BiReportIdToEnTemplate = 'biReportIdToEnTemplate',
  /** 单据最大行系统配置 */
  BillConfig = 'billConfig',
  BillType = 'billType',
  BizDataGroupStatus = 'bizDataGroupStatus',
  BizDistributionPlatformStoreStatus = 'bizDistributionPlatformStoreStatus',
  BizMtpsStatus = 'bizMtpsStatus',
  BizOnlineOrderRTypeEnum = 'bizOnlineOrderRTypeEnum',
  BizUserStatus = 'bizUserStatus',
  /** 称重计件的读重模式，取值：default-取资料重量，scale-读秤重量 */
  BulkPcsSkuWeightMode = 'bulkPcsSkuWeightMode',
  BulkType = 'bulkType',
  /** 业务设置类型 */
  BusinessConfigType = 'businessConfigType',
  BusinessInvGoodsOrder = 'businessInvGoodsOrder',
  /** 商品业务类型 */
  BusinessModel = 'businessModel',
  /** 商品范围业务类型 */
  BusinessTypeEnum = 'businessTypeEnum',
  /** 礼品卡卡余额排序枚举 */
  CardBalanceOrderBy = 'cardBalanceOrderBy',
  /** 礼品卡按售卡业务员排序枚举 */
  CardBySalerOrderBy = 'cardBySalerOrderBy',
  /** 礼品卡汇总排序枚举 */
  CardDrptAllOrderBy = 'cardDrptAllOrderBy',
  /** 门店礼品卡流水排序枚举 */
  CardDrptStoreOrderBy = 'cardDrptStoreOrderBy',
  /** 售卡交易类型 */
  CardServiceOrderBillClas = 'cardServiceOrderBillClas',
  Channel = 'channel',
  CheckCondition = 'checkCondition',
  CheckFlowBillClass = 'checkFlowBillClass',
  CheckFlowStatus = 'checkFlowStatus',
  CheckInDetailStatus = 'checkInDetailStatus',
  /** 盘点盈亏单来源类型 */
  CheckInSourceClass = 'checkInSourceClass',
  CheckInStatus = 'checkInStatus',
  CheckOrg = 'checkOrg',
  CheckRule = 'checkRule',
  CheckTaskBillClass = 'checkTaskBillClass',
  CheckTaskConditionType = 'checkTaskConditionType',
  CheckTaskGoodsScope = 'checkTaskGoodsScope',
  CheckTaskRuleOutOfScope = 'checkTaskRuleOutOfScope',
  CheckTaskRuleUnCheckQty = 'checkTaskRuleUnCheckQty',
  CheckTaskSearchType = 'checkTaskSearchType',
  CheckTaskSourceClass = 'checkTaskSourceClass',
  CheckTaskStatus = 'checkTaskStatus',
  CheckTaskWorkStatus = 'checkTaskWorkStatus',
  /** 商品码打印样式 */
  CodeStyle = 'codeStyle',
  /** 条码类型 */
  CodeType = 'codeType',
  Configs = 'configs',
  ContentGroup = 'contentGroup',
  CostType = 'costType',
  CrossDockingStatus = 'crossDockingStatus',
  CurrencyPosDeviceType = 'currencyPosDeviceType',
  CurrencySchemaDetailStatus = 'currencySchemaDetailStatus',
  CurrencySchemaStoreScope = 'currencySchemaStoreScope',
  CurrencySchemaSystemDefault = 'currencySchemaSystemDefault',
  CurrencySchemaSystemDefaultDefault = 'currencySchemaSystemDefaultDefault',
  CurrencySchemaSystemDefaultRemove = 'currencySchemaSystemDefaultRemove',
  CustomerAccountBillType = 'customerAccountBillType',
  CustomerBillCheckBillType = 'customerBillCheckBillType',
  CustomerBillCheckSettleType = 'customerBillCheckSettleType',
  CustomerBillCheckStatus = 'customerBillCheckStatus',
  CustomerOrderType = 'customerOrderType',
  /** 付款凭证状态 */
  CustomerRechargeStatus = 'customerRechargeStatus',
  /** 客户来源 */
  CustomerSource = 'customerSource',
  /** 客户台账单据类型 */
  CustomerStandingBookType = 'customerStandingBookType',
  CustomerStatus = 'customerStatus',
  /** 客户类型 */
  CustomerType = 'customerType',
  /** 出账周期类型 */
  CycleType = 'cycleType',
  DataCheckBusinessType = 'dataCheckBusinessType',
  DataCheckResultStatus = 'dataCheckResultStatus',
  DataCheckTaskStatus = 'dataCheckTaskStatus',
  Days = 'days',
  /** 扣率类型 */
  DeductRateType = 'deductRateType',
  DeductType = 'deductType',
  DicMap = 'dicMap',
  DirectAllocBackBillClass = 'directAllocBackBillClass',
  DirectAllocBackStatus = 'directAllocBackStatus',
  DirectBackStatus = 'directBackStatus',
  DirectOutStatus = 'directOutStatus',
  DisDiffReason = 'disDiffReason',
  /** 折扣规则 */
  DiscountRule = 'discountRule',
  /** 优惠类型 */
  DiscountType = 'discountType',
  DistBatchStatus = 'distBatchStatus',
  DistFxTypeEnum = 'distFxTypeEnum',
  /** 财务配货类型 */
  DistInoutFlowDistType = 'distInoutFlowDistType',
  /** 财务配货业务动作 */
  DistInoutFlowOccurType = 'distInoutFlowOccurType',
  DistType = 'distType',
  DistributionDiffStatus = 'distributionDiffStatus',
  /** 配货毛利类型枚举 */
  DistributionMarginType = 'distributionMarginType',
  DistributionPlatformType = 'distributionPlatformType',
  /** 分账单单据状态 */
  DivisionBillStatus = 'divisionBillStatus',
  /** 日志动作 */
  DivisionLogAction = 'divisionLogAction',
  /** 门店范围 */
  DivisionRuleStoreRange = 'divisionRuleStoreRange',
  /** 分账算法 */
  DivisionRuleStrategy = 'divisionRuleStrategy',
  /** 分账方案类型 */
  DivisionRuleType = 'divisionRuleType',
  /** 分帐结算单状态 */
  DivisionSettleBillStatus = 'divisionSettleBillStatus',
  /** 提现状态 */
  DivisionWithdrawStatus = 'divisionWithdrawStatus',
  EffectInvBillClass = 'effectInvBillClass',
  /** 电子价签提供商枚举 */
  ElectPriceTagChannel = 'electPriceTagChannel',
  ElectronicPriceTagConfigStatus = 'electronicPriceTagConfigStatus',
  EntityCardStatus = 'entityCardStatus',
  EwsCodeConfig = 'ewsCodeConfig',
  EwsPriceType = 'ewsPriceType',
  EwsTemplateCodeType = 'ewsTemplateCodeType',
  EwsTemplateStoreScope = 'ewsTemplateStoreScope',
  EwsTransferChannel = 'ewsTransferChannel',
  EwsTransferStatusType = 'ewsTransferStatusType',
  EwsType = 'ewsType',
  /** 费用单-关联单据类型 */
  FeeBillClass = 'feeBillClass',
  /** 费用精度进位类型 */
  FeeCarryType = 'feeCarryType',
  FeeCreateStatus = 'feeCreateStatus',
  FeeItemPayDirection = 'feeItemPayDirection',
  FeeItemPayType = 'feeItemPayType',
  FeeItemSource = 'feeItemSource',
  FeeItemType = 'feeItemType',
  /** 费用精度 */
  FeePrecision = 'feePrecision',
  FeeSubjectType = 'feeSubjectType',
  FinanceInvGoodsOrder = 'financeInvGoodsOrder',
  /** 财务销售渠道 */
  FinanceSaleChannelEnum = 'financeSaleChannelEnum',
  FontAlign = 'fontAlign',
  /** 运费类型枚举 */
  FreightType = 'freightType',
  FxFormulaEnum = 'fxFormulaEnum',
  /** 取整类型 */
  GiveChangeType = 'giveChangeType',
  GoodsAdjustButtonEnum = 'goodsAdjustButtonEnum',
  GoodsDistType = 'goodsDistType',
  GoodsLockInvTypeEnum = 'goodsLockInvTypeEnum',
  /** 市调日期枚举 */
  GoodsMarketPriceDay = 'goodsMarketPriceDay',
  GoodsMeasurementModeEnum = 'goodsMeasurementModeEnum',
  GoodsOrderType = 'goodsOrderType',
  GoodsPriceAddMode = 'goodsPriceAddMode',
  GoodsPriceAdjustStatus = 'goodsPriceAdjustStatus',
  GoodsPriceGoodsPositioning = 'goodsPriceGoodsPositioning',
  /** 价格试算-调价日期类型 */
  GoodsPriceTrialAdjustPriceType = 'goodsPriceTrialAdjustPriceType',
  /** 价格试算-试算记录状态 */
  GoodsPriceTrialRecordStatus = 'goodsPriceTrialRecordStatus',
  GoodsPriceType = 'goodsPriceType',
  GoodsPriceTypeForAdd = 'goodsPriceTypeForAdd',
  GoodsRemoveProcess = 'goodsRemoveProcess',
  GoodsStatus = 'goodsStatus',
  GoodsSync = 'goodsSync',
  GoodsType = 'goodsType',
  HolderAuthCodeStatusType = 'holderAuthCodeStatusType',
  HolderOnlineStatusType = 'holderOnlineStatusType',
  HolderStatusType = 'holderStatusType',
  HttpLogResultType = 'httpLogResultType',
  InoutBillClass = 'inoutBillClass',
  InvFlowBillClassStore = 'invFlowBillClassStore',
  InvFlowBillClassWrh = 'invFlowBillClassWrh',
  InvPriceAdjustReason = 'invPriceAdjustReason',
  InvPriceAdjustStatus = 'invPriceAdjustStatus',
  IoBatchBusinessType = 'ioBatchBusinessType',
  IoInPlate = 'ioInPlate',
  IoInTemplate = 'ioInTemplate',
  IoOutBusinessType = 'ioOutBusinessType',
  IoOutPlate = 'ioOutPlate',
  IoOutStatus = 'ioOutStatus',
  IoTaskInStatus = 'ioTaskInStatus',
  /** iwms物流模式 */
  IwmsLogisticMode = 'iwmsLogisticMode',
  JoinAccountStatus = 'joinAccountStatus',
  /** 加盟协议状态 */
  JoinAgreementStatus = 'joinAgreementStatus',
  JoinDeductReason = 'joinDeductReason',
  JoinDeductionStatus = 'joinDeductionStatus',
  JoinFlowOccurType = 'joinFlowOccurType',
  JoinFlowTradeType = 'joinFlowTradeType',
  JoinPayReason = 'joinPayReason',
  JoinPayType = 'joinPayType',
  JoinPaymentStatus = 'joinPaymentStatus',
  KeyboardCashFunction = 'keyboardCashFunction',
  KeyboardPayFunction = 'keyboardPayFunction',
  KeyboardSettingsType = 'keyboardSettingsType',
  /** 提单来源枚举 */
  LadingSource = 'ladingSource',
  LadingTimeType = 'ladingTimeType',
  LadingType = 'ladingType',
  LastSettleNo = 'lastSettleNo',
  LevelMode = 'levelMode',
  /** 批发价格枚举 */
  LevelPriceType = 'levelPriceType',
  Line = 'line',
  /** 损溢报表组织类型 */
  LossGainReportOrgType = 'lossGainReportOrgType',
  /** 损溢报表类型 */
  LossGainReportType = 'lossGainReportType',
  LossReportDimension = 'lossReportDimension',
  ManualSyncType = 'manualSyncType',
  MdataType = 'mdataType',
  MemberBalanceTradeType = 'memberBalanceTradeType',
  MemberCardStoredFlowActionType = 'memberCardStoredFlowActionType',
  MemberCardStoredFlowChannelType = 'memberCardStoredFlowChannelType',
  MemberCardType = 'memberCardType',
  MemberCardUnionStatus = 'memberCardUnionStatus',
  MemberChannel = 'memberChannel',
  MemberConsumeChannelType = 'memberConsumeChannelType',
  MemberCouponStatus = 'memberCouponStatus',
  MemberCouponType = 'memberCouponType',
  MemberPointTradeType = 'memberPointTradeType',
  /** 会员价规则 */
  MemberPriceRule = 'memberPriceRule',
  MemberRegisterChannelType = 'memberRegisterChannelType',
  MemberStatus = 'memberStatus',
  MemberTradeType = 'memberTradeType',
  MerchantPackType = 'merchantPackType',
  MessageBusinessType = 'messageBusinessType',
  /** 美团必填信息缺失 */
  MtRequiredLack = 'mtRequiredLack',
  Munits = 'munits',
  /** 负毛利商品查询对应渠道枚举 */
  NegativeProfitPlatform = 'negativeProfitPlatform',
  /** 订货商城客户申请状态 */
  OmallCustomerApplyStatus = 'omallCustomerApplyStatus',
  /** 订货商城首页装修跳转类型 */
  OmallHomeJumpType = 'omallHomeJumpType',
  OnlineGoodsSearchType = 'onlineGoodsSearchType',
  OnlineGoodsStatus = 'onlineGoodsStatus',
  /** 线上同步操作 */
  OnlineGoodsSyncOperate = 'onlineGoodsSyncOperate',
  OnlineInvRuleType = 'onlineInvRuleType',
  OnlineOrderCancelReason = 'onlineOrderCancelReason',
  OnlineOrderCommentScope = 'onlineOrderCommentScope',
  OnlineOrderCommentType = 'onlineOrderCommentType',
  OnlineOrderDeliverModes = 'onlineOrderDeliverModes',
  OnlineOrderRDeliverModes = 'onlineOrderRDeliverModes',
  OnlineOrderRRefuseReason = 'onlineOrderRRefuseReason',
  OnlineOrderRRefuseReceiveReason = 'onlineOrderRRefuseReceiveReason',
  OnlineOrderRStatus = 'onlineOrderRStatus',
  OnlineOrderRType = 'onlineOrderRType',
  OnlineOrderRefuseOrderReason = 'onlineOrderRefuseOrderReason',
  OnlineOrderRefuseReason = 'onlineOrderRefuseReason',
  OnlineOrderRefuseRefundReason = 'onlineOrderRefuseRefundReason',
  OnlineOrderStatus = 'onlineOrderStatus',
  OnlineOrderType = 'onlineOrderType',
  OnlinePlatform = 'onlinePlatform',
  OnlinePriceRuleType = 'onlinePriceRuleType',
  OnlineRuleType = 'onlineRuleType',
  OnlineStoreStatus = 'onlineStoreStatus',
  OnlineTaskGoodsAbnormalReason = 'onlineTaskGoodsAbnormalReason',
  OrderBillClass = 'orderBillClass',
  OrgType = 'orgType',
  OrgValidateField = 'orgValidateField',
  OutStatusViewEnum = 'outStatusViewEnum',
  PayBillClass = 'payBillClass',
  PayBusinessType = 'payBusinessType',
  PaySettleTypeEnum = 'paySettleTypeEnum',
  PayStatus = 'payStatus',
  PayType = 'payType',
  PayedStatus = 'payedStatus',
  PaymentSchema = 'paymentSchema',
  PermTableNodeType = 'permTableNodeType',
  PermType = 'permType',
  /** 拣货单拣货商品明细行来源 */
  PickingDetailSource = 'pickingDetailSource',
  /** 拣货模式 */
  PickingModel = 'pickingModel',
  /** 一键出库批次状态 */
  PickingOutboundStatus = 'pickingOutboundStatus',
  /** 一键出库类型 */
  PickingOutboundType = 'pickingOutboundType',
  /** 拣货单来源 */
  PickingSource = 'pickingSource',
  /** 拣货单状态 */
  PickingStatus = 'pickingStatus',
  /** 波次单来源 */
  PickingWaveSource = 'pickingWaveSource',
  /** 波次单状态 */
  PickingWaveStatus = 'pickingWaveStatus',
  /** 提货券实例状态 */
  PickupCardEntityStatusType = 'pickupCardEntityStatusType',
  /** 提货券实例发售渠道 */
  PickupCardSaleChannelType = 'pickupCardSaleChannelType',
  /** 提货券类型门店类型 */
  PickupCardStoreType = 'pickupCardStoreType',
  /** 提货券类型启用状态 */
  PickupCardTypeStatus = 'pickupCardTypeStatus',
  /** 提货卡流水类型 */
  PickupFlowType = 'pickupFlowType',
  PointGoodsOrderByEnum = 'pointGoodsOrderByEnum',
  PosAuthCodeStatus = 'posAuthCodeStatus',
  PosDataDistributeRangeType = 'posDataDistributeRangeType',
  /** 收银机设备枚举 */
  PosDeviceFieldEnum = 'posDeviceFieldEnum',
  PosDeviceType = 'posDeviceType',
  PosDistributeTaskStatus = 'posDistributeTaskStatus',
  PosLockStatus = 'posLockStatus',
  PosLogModule = 'posLogModule',
  PosLogOperation = 'posLogOperation',
  PosMonitorStatus = 'posMonitorStatus',
  PosOnlineStatus = 'posOnlineStatus',
  PosSchemaPriceTag = 'posSchemaPriceTag',
  PosSchemaPrinterModel = 'posSchemaPrinterModel',
  PosSchemaRoundMode = 'posSchemaRoundMode',
  PosSchemaScreenMode = 'posSchemaScreenMode',
  PosStatus = 'posStatus',
  /** 预盘单状态 */
  PreCheckStatus = 'preCheckStatus',
  PrePayCardActionType = 'prePayCardActionType',
  PrePayCardCashActionType = 'prePayCardCashActionType',
  PrePayCardDump = 'prePayCardDump',
  PrePayCardOrderStatus = 'prePayCardOrderStatus',
  PrePayCardSaleChannelType = 'prePayCardSaleChannelType',
  /** 礼品卡状态值 */
  PrePayCardStatusActionType = 'prePayCardStatusActionType',
  PrePayCardStoreType = 'prePayCardStoreType',
  PrePayCardTypeStatus = 'prePayCardTypeStatus',
  PriceTagBarCode = 'priceTagBarCode',
  /** 价签店务单据 */
  PriceTagBusinessBillType = 'priceTagBusinessBillType',
  PriceTagDirect = 'priceTagDirect',
  PriceTagFix = 'priceTagFix',
  PriceTagGoods = 'priceTagGoods',
  PriceTagPaperDirect = 'priceTagPaperDirect',
  /** 价签模版-拣货商品信息 */
  PriceTagPickingGoods = 'priceTagPickingGoods',
  /** 价签模版-拣货信息 */
  PriceTagPickingInfo = 'priceTagPickingInfo',
  PriceTagPlugFile = 'priceTagPlugFile',
  PriceTagQrcode = 'priceTagQrcode',
  PriceTagStatus = 'priceTagStatus',
  /** 价签模版-门店信息 */
  PriceTagStoreInfo = 'priceTagStoreInfo',
  PriceTagType = 'priceTagType',
  PriceType = 'priceType',
  /** 打印状态 */
  PrintStatus = 'printStatus',
  PrintingBillClass = 'printingBillClass',
  PrintingTemp = 'printingTemp',
  /** 本地促销类型 */
  PromoteActivityClass = 'promoteActivityClass',
  /** 本地促销状态 */
  PromoteActivityStatus = 'promoteActivityStatus',
  PromoteBillStatus = 'promoteBillStatus',
  PromoteBillType = 'promoteBillType',
  /** 促销渠道 */
  PromoteChannel = 'promoteChannel',
  /** 促销类型 */
  PromoteClass = 'promoteClass',
  /** 本地促销条件类型 */
  PromoteConditionType = 'promoteConditionType',
  /** 本地促销创建来源 */
  PromoteCreateSource = 'promoteCreateSource',
  /** 本地促销适用人群 */
  PromotePersonCondition = 'promotePersonCondition',
  /** 本地促销适用商品 */
  PromoteSkuCondition = 'promoteSkuCondition',
  /** 本地促销商品条件类型 */
  PromoteSkuConditionClass = 'promoteSkuConditionClass',
  /** 本地促销适用门店 */
  PromoteStoreCondition = 'promoteStoreCondition',
  PromotionType = 'promotionType',
  PurchaseBackStatus = 'purchaseBackStatus',
  /** 采购进货单据类型 */
  PurchaseBillType = 'purchaseBillType',
  /** 采购进货业务类型 */
  PurchaseBusinessType = 'purchaseBusinessType',
  PurchaseGoodsBackStatus = 'purchaseGoodsBackStatus',
  PurchaseGoodsDimension = 'purchaseGoodsDimension',
  PurchaseInStatus = 'purchaseInStatus',
  PurchaseMonitRecStatus = 'purchaseMonitRecStatus',
  PurchaseOrdBillClass = 'purchaseOrdBillClass',
  PurchaseOrdStatus = 'purchaseOrdStatus',
  PurchaseOrderBillClass = 'purchaseOrderBillClass',
  /** 采购订货参考-按商品查询-排序条件 */
  PurchaseOrderByGoodsOrderBy = 'purchaseOrderByGoodsOrderBy',
  /** 订单来源 */
  PurchaseOrderSource = 'purchaseOrderSource',
  PurchaseOrderStatus = 'purchaseOrderStatus',
  /** 采购计划单查询类型 */
  PurchasePlanDetailQueryType = 'purchasePlanDetailQueryType',
  PurchasePlanDetailStatus = 'purchasePlanDetailStatus',
  /** 采购计划商品异常原因 */
  PurchasePlanErrorReason = 'purchasePlanErrorReason',
  PurchasePlanStatus = 'purchasePlanStatus',
  /** 进货报表单据类型 */
  PurchaseReportBillType = 'purchaseReportBillType',
  /** 进货报表业务类型 */
  PurchaseReportBusinessType = 'purchaseReportBusinessType',
  /** 采购进货采购类型 */
  PurchaseSubjectType = 'purchaseSubjectType',
  /** 数量范围 */
  QtyRangeModel = 'qtyRangeModel',
  ReasonType = 'reasonType',
  ReasonTypeRemark = 'reasonTypeRemark',
  ReceiptBillType = 'receiptBillType',
  ReceiptBusinessType = 'receiptBusinessType',
  ReceiptStatus = 'receiptStatus',
  ReceiptedStatus = 'receiptedStatus',
  ReceptionLogStatus = 'receptionLogStatus',
  RechargeBillClass = 'rechargeBillClass',
  RecipeInvType = 'recipeInvType',
  /** 加工模版适用范围 */
  RecipeScope = 'recipeScope',
  RecipeType = 'recipeType',
  RecordOrderBillClass = 'recordOrderBillClass',
  RegularType = 'regularType',
  /** 补货库存 */
  ReplenishInv = 'replenishInv',
  /** 补货库存减数 */
  ReplenishInvSubtrahend = 'replenishInvSubtrahend',
  ReplenishSchemaFormulaType = 'replenishSchemaFormulaType',
  ReplenishSchemaGoodsEffectiveStatus = 'replenishSchemaGoodsEffectiveStatus',
  ReplenishSchemaGoodsStatus = 'replenishSchemaGoodsStatus',
  ReplenishSchemaRuleType = 'replenishSchemaRuleType',
  ReplenishSchemaSortRuleStatus = 'replenishSchemaSortRuleStatus',
  ReplenishSchemaStatus = 'replenishSchemaStatus',
  /** 商品补货状态 */
  ReplenishStatus = 'replenishStatus',
  /** 补货触发配置 */
  ReplenishTriggerConfig = 'replenishTriggerConfig',
  /** 补货触发库存 */
  ReplenishTriggerInv = 'replenishTriggerInv',
  ReportConfigType = 'reportConfigType',
  ReportCpts = 'reportCpts',
  /** POS单据打印模板 */
  ReportPosCpts = 'reportPosCpts',
  RequireOrderStatus = 'requireOrderStatus',
  RequireQueryOrdersDimension = 'requireQueryOrdersDimension',
  RetailPriceAdjustStatus = 'retailPriceAdjustStatus',
  /** 原价规则枚举 */
  RetailPriceRule = 'retailPriceRule',
  ReturnNotifyLimitType = 'returnNotifyLimitType',
  ReturnNotifyStoreScope = 'returnNotifyStoreScope',
  ReturnOrderType = 'returnOrderType',
  ReturnReason = 'returnReason',
  RevenueFlowBillClassType = 'revenueFlowBillClassType',
  RolePricePermEnum = 'rolePricePermEnum',
  RoleValidateField = 'roleValidateField',
  /** 扣率生效历史类型 */
  RuleGoodsLogType = 'ruleGoodsLogType',
  SaleBillClass = 'saleBillClass',
  /** 销售商品加工排序枚举 */
  SaleDrptOrder = 'saleDrptOrder',
  SaleMethod = 'saleMethod',
  /** 锁定现金类枚举 */
  SalePayType = 'salePayType',
  SaleType = 'saleType',
  /** 商品搜索关键字类型 */
  SearchGoodsType = 'searchGoodsType',
  SecScreenCycleModeType = 'secScreenCycleModeType',
  SecScreenDeviceType = 'secScreenDeviceType',
  SecScreenEffectStatus = 'secScreenEffectStatus',
  SecScreenFormatStatus = 'secScreenFormatStatus',
  SecScreenMediaType = 'secScreenMediaType',
  SecScreenStoreScopeStatus = 'secScreenStoreScopeStatus',
  SellUnionAccountByDetailBillType = 'sellUnionAccountByDetailBillType',
  SellUnionAccountByGoodsBillType = 'sellUnionAccountByGoodsBillType',
  SellbuyBillType = 'sellbuyBillType',
  SellbuySettleGoodsBillClass = 'sellbuySettleGoodsBillClass',
  SellbuySettleInvoiceType = 'sellbuySettleInvoiceType',
  SellbuySettlePayStatus = 'sellbuySettlePayStatus',
  SellbuySettleStatus = 'sellbuySettleStatus',
  SellunionAgreementSettleMode = 'sellunionAgreementSettleMode',
  SellunionAgreementStatus = 'sellunionAgreementStatus',
  SellunionAgreementStoreScope = 'sellunionAgreementStoreScope',
  SellunionBackStatus = 'sellunionBackStatus',
  SellunionInStatus = 'sellunionInStatus',
  ServiceOrderChannlType = 'serviceOrderChannlType',
  ServiceOrderRevenueChannlType = 'serviceOrderRevenueChannlType',
  ServiceOrderType = 'serviceOrderType',
  SettleBatchStatus = 'settleBatchStatus',
  SettleNo = 'settleNo',
  SortClass = 'sortClass',
  /** 分类维度 */
  SortDimension = 'sortDimension',
  StandingBookBillType = 'standingBookBillType',
  Stock = 'stock',
  StoreBackStatus = 'storeBackStatus',
  /** 门店退货状态枚举 */
  StoreBackViewStatus = 'storeBackViewStatus',
  StoreCustomerAccountBillType = 'storeCustomerAccountBillType',
  StoreDirectStatus = 'storeDirectStatus',
  StoreGainReason = 'storeGainReason',
  StoreGainStatus = 'storeGainStatus',
  StoreInSaveTargetStatus = 'storeInSaveTargetStatus',
  StoreInStatus = 'storeInStatus',
  StoreInType = 'storeInType',
  StoreIncomeStatus = 'storeIncomeStatus',
  StoreLevel = 'storeLevel',
  StoreLockStatus = 'storeLockStatus',
  StoreLossReason = 'storeLossReason',
  StoreLossStatus = 'storeLossStatus',
  StoreMarketStatus = 'storeMarketStatus',
  StorePaymentSettings = 'storePaymentSettings',
  StorePaymentType = 'storePaymentType',
  /** 拣货售罄后生成报损报溢单的状态 */
  StorePickingOutOrderStatus = 'storePickingOutOrderStatus',
  StoreProcessStatus = 'storeProcessStatus',
  /** 门店补货状态 */
  StoreReplenishStatus = 'storeReplenishStatus',
  /** 退货单历史单据状态 */
  StoreReturnHisStatus = 'storeReturnHisStatus',
  StoreReturnNotifyStatus = 'storeReturnNotifyStatus',
  StoreReturnSource = 'storeReturnSource',
  StoreReturnStatus = 'storeReturnStatus',
  StoreStatus = 'storeStatus',
  StoreTransferStatus = 'storeTransferStatus',
  StoreType = 'storeType',
  StoreUseStatus = 'storeUseStatus',
  /** 补差协议提单方式类型 */
  SubdiffAgreementLadingMode = 'subdiffAgreementLadingMode',
  /** 补差协议状态 */
  SubdiffAgreementStatus = 'subdiffAgreementStatus',
  /** 补差单来源 */
  SubdiffSource = 'subdiffSource',
  /** 补差单状态 */
  SubdiffStatus = 'subdiffStatus',
  SupplierAgreementEffectStatus = 'supplierAgreementEffectStatus',
  SupplierAgreementQueryBusiness = 'supplierAgreementQueryBusiness',
  SupplierAuthEnum = 'supplierAuthEnum',
  SupplierBackPrice = 'supplierBackPrice',
  SupplierBillStatus = 'supplierBillStatus',
  SupplierBillType = 'supplierBillType',
  SupplierContractStatus = 'supplierContractStatus',
  SupplierFeeStatus = 'supplierFeeStatus',
  SupplierOrderType = 'supplierOrderType',
  SupplierPrepayPayType = 'supplierPrepayPayType',
  SupplierPrepayStatus = 'supplierPrepayStatus',
  SupplierPurchaseModels = 'supplierPurchaseModels',
  SupplierPurchaseOrderStatus = 'supplierPurchaseOrderStatus',
  /** 供应商关系权限 */
  SupplierRelationRoles = 'supplierRelationRoles',
  /** 供应商关系状态 */
  SupplierRelationStatus = 'supplierRelationStatus',
  /** 供应商台账变动类型 */
  SupplierSbChangeType = 'supplierSbChangeType',
  /** 供应商台账费用明细单据类型 */
  SupplierSbFeeBillType = 'supplierSbFeeBillType',
  /** 供应商台账预付明细单据类型 */
  SupplierSbPrepayBillType = 'supplierSbPrepayBillType',
  /** 供应商台账应付明细单据类型 */
  SupplierSbShouldPayBillType = 'supplierSbShouldPayBillType',
  SupplierSellUnionSettleInvoiceMarkType = 'supplierSellUnionSettleInvoiceMarkType',
  SupplierSellUnionSettleInvoiceType = 'supplierSellUnionSettleInvoiceType',
  SupplierSellUnionSettlePayMarkType = 'supplierSellUnionSettlePayMarkType',
  SupplierSellUnionSettleStatus = 'supplierSellUnionSettleStatus',
  SupplierSettleBatchStatus = 'supplierSettleBatchStatus',
  SupplierSettleBatchType = 'supplierSettleBatchType',
  /** 供应商结算模式 */
  SupplierSettleMode = 'supplierSettleMode',
  SupplierSettleType = 'supplierSettleType',
  SupplierStatus = 'supplierStatus',
  SupplierType = 'supplierType',
  SupportStatus = 'supportStatus',
  SystemIdEnum = 'systemIdEnum',
  TagType = 'tagType',
  TargetsSortType = 'targetsSortType',
  TaxRateEnum = 'taxRateEnum',
  TemplateType = 'templateType',
  ThirdPlatformDeliverLogisticsStatus = 'thirdPlatformDeliverLogisticsStatus',
  TicketBusinessType = 'ticketBusinessType',
  TicketTemplateDigitalNumber = 'ticketTemplateDigitalNumber',
  TicketTemplateDigitalNumberPosition = 'ticketTemplateDigitalNumberPosition',
  TicketTemplateFieldType = 'ticketTemplateFieldType',
  TicketTemplateMode = 'ticketTemplateMode',
  TicketWidth = 'ticketWidth',
  TitleBizType = 'titleBizType',
  TransactionChannelType = 'transactionChannelType',
  TransferBillClass = 'transferBillClass',
  TransferReason = 'transferReason',
  UnderLine = 'underLine',
  UrgeSaleEnum = 'urgeSaleEnum',
  /** 礼品卡类型限用次数 */
  UseCountType = 'useCountType',
  UserFormType = 'userFormType',
  /** 效期库存查询排序枚举 */
  ValidDateInvOrder = 'validDateInvOrder',
  WarehouseStatus = 'warehouseStatus',
  WeatherEnum = 'weatherEnum',
  WeimobPaymentChannel = 'weimobPaymentChannel',
  /** 微盟必填信息缺失 */
  WeimobRequiredLack = 'weimobRequiredLack',
  WholeSaleBackStatus = 'wholeSaleBackStatus',
  WholeSaleOutInvoiceType = 'wholeSaleOutInvoiceType',
  WholeSaleOutSettleType = 'wholeSaleOutSettleType',
  WholeSaleOutStatus = 'wholeSaleOutStatus',
  WholeSaleReportDimension = 'wholeSaleReportDimension',
  /** 批发销售统计报表发货主体类型 */
  WholeSaleSenderSubject = 'wholeSaleSenderSubject',
  WholesaleBillType = 'wholesaleBillType',
  /** 批发订单转单类型 */
  WholesaleOrderOutType = 'wholesaleOrderOutType',
  /** 批发订单结算类型 */
  WholesaleOrderSettleType = 'wholesaleOrderSettleType',
  /** 批发订单来源单据类型 */
  WholesaleOrderSourceClass = 'wholesaleOrderSourceClass',
  /** 批发订单状态 */
  WholesaleOrderStatus = 'wholesaleOrderStatus',
  WholesaleSettleBatchStatus = 'wholesaleSettleBatchStatus',
  WholesaleSettleStatus = 'wholesaleSettleStatus',
  /** WMS启用枚举 */
  WmsConfig = 'wmsConfig',
  /** 总库存区间查询类型枚举 */
  WmsInvCheckAllStockQueryType = 'wmsInvCheckAllStockQueryType',
  WrhAllocBackStatus = 'wrhAllocBackStatus',
  /** 门店退仓通知单状态枚举 */
  WrhAllocNoticeBackStatus = 'wrhAllocNoticeBackStatus',
  /** 配货通知单业务类型 */
  WrhAllocOrderBusinessType = 'wrhAllocOrderBusinessType',
  WrhAllocOrderSource = 'wrhAllocOrderSource',
  WrhAllocOrderStatus = 'wrhAllocOrderStatus',
  WrhAllocOutRule = 'wrhAllocOutRule',
  WrhAllocOutSource = 'wrhAllocOutSource',
  /** 仓库配货模式 */
  WrhDistMode = 'wrhDistMode',
  /** 仓库零售云配货类型 */
  WrhDistType = 'wrhDistType',
  WrhGainStatus = 'wrhGainStatus',
  WrhLockStatus = 'wrhLockStatus',
  WrhLossStatus = 'wrhLossStatus',
  WrhOutStatus = 'wrhOutStatus',
  /** 仓库商品拣货类型 */
  WrhPickingType = 'wrhPickingType',
  /** 仓库加工单状态 */
  WrhProcessStatus = 'wrhProcessStatus',
  WrhTransferStatus = 'wrhTransferStatus',
  /** 仓库领用核算方式 */
  WrhUseAccountType = 'wrhUseAccountType',
  /** 仓库领用状态 */
  WrhUseStatus = 'wrhUseStatus',
  WrhWholeSaleBackStatus = 'wrhWholeSaleBackStatus',
  WrhWholeSaleOutModifyStatusEnum = 'wrhWholeSaleOutModifyStatusEnum'
}

export enum AbnormalGoodsOlderTypeEnum {
  /** 大于90天 */
  Ninety = 'ninety',
  /** 大于180天 */
  HalfYear = 'halfYear',
  /** 大于365天 */
  Year = 'year'
}

/** 账户所有者类型 */
export enum AccountOwnerTypeEnum {
  /** 门店 */
  Store = 'store',
  /** 客户 */
  Customer = 'customer'
}

export enum AccountStatusEnum {
  /** 未出账 */
  UnAccount = 'unAccount',
  /** 已出账 */
  Accounted = 'accounted'
}

export enum AdapterTypeEnum {
  /** 牵牛花 */
  QianNiuHua = 'qianNiuHua'
}

/** 调价类型 */
export enum AdjustPriceSourceEnum {
  /** 总部调价 */
  HeadAdjust = 'headAdjust',
  /** 门店改价 */
  StoreAdjust = 'storeAdjust'
}

/** 价格试算生成调价单任务状态 */
export enum AdjustPriceTaskStatusEnum {
  /** 进行中 */
  Doing = 'doing',
  /** 已结束 */
  End = 'end'
}

/** 全部平台必填信息缺失 */
export enum AllPlatformRequiredLackEnum {
  /** 无图 */
  NoImage = 'noImage',
  /** 无分组 */
  NoGroup = 'noGroup',
  /** 无类目 */
  NoCategory = 'noCategory',
  /** 未关联规格 */
  NoAttribute = 'noAttribute',
  /** 无条码 */
  NoBarcode = 'noBarcode',
  /** 无重量 */
  NoWeight = 'noWeight'
}

/** 配货通知单详情下游链路类型 */
export enum AllocOrderDownstreamTypeQueryEnum {
  /** wms */
  Wms = 'wms',
  /** 拣货（标品拣货） */
  Sandard = 'sandard',
  /** 拣货（称重分拣） */
  Weight = 'weight'
}

/** 下游链路类型 */
export enum AllocOrderDownstreamTypesEnum {
  /** wms */
  Wms = 'wms',
  /** 拣货 */
  FreshPicking = 'freshPicking'
}

export enum AllocOutStatusEnumEnum {
  /** 待提交 */
  Init = 'init',
  /** 待发货 */
  Approved = 'approved',
  /** 待收货 */
  Sended = 'sended',
  /** 已收货 */
  Received = 'received',
  /** 已完成 */
  Finished = 'finished',
  /** 待收货 */
  Verified = 'verified',
  /** 已作废 */
  Abolished = 'abolished',
  /** 进行中 */
  Doing = 'doing'
}

export enum AllocOutTypeEnum {
  /** 仓配 */
  Wrh = 'wrh',
  /** 直配 */
  Direct = 'direct',
  /** 直配 */
  Order = 'order'
}

/** 业务设置-进价设置 */
export enum AllowInPriceRuleEnum {
  /** 只能调低 */
  OnlyLower = 'onlyLower',
  /** 不启用 */
  None = 'none'
}

export enum AllowMemberRuleEnum {
  /** 统一会员价 */
  Unite = 'unite',
  /** 等级会员价 */
  Level = 'level',
  /** 多等级标准会员价 */
  Hierarchical = 'hierarchical',
  /** 不启用 */
  None = 'none'
}

export enum AppInfoEnum {
  /** 安卓收银系统 */
  Pos = 'pos',
  /** 自助收银系统 */
  SelfPos = 'selfPos',
  /** 店务助手 */
  Pda = 'pda',
  /** PC收银系统 */
  PcPos = 'pcPos',
  /** 门店助手 */
  Assistant = 'assistant',
  /** 传秤助手 */
  ScaleTool = 'scaleTool'
}

/** 应用中心显示模式 */
export enum AppInfoShowTypeEnum {
  /** 二维码模式 */
  QrCode = 'qrCode',
  /** 单下载模式 */
  Download1 = 'download1',
  /** 双下载模式 */
  Download2 = 'download2',
  /** 开通模式 */
  Open = 'open'
}

export enum AuthorizationStatusEnum {
  /** 未授权 */
  Unauthorized = 'unauthorized',
  /** 使用中 */
  Active = 'active',
  /** 已禁用 */
  Disabled = 'disabled'
}

export enum BackGroundLogStatusEnum {
  /** 全部 */
  All = 'all',
  /** web(总部) */
  WebHead = 'webHead',
  /** web(门店) */
  WebStore = 'webStore',
  /** 供应商协同平台 */
  SupplierCooperatePlatform = 'supplierCooperatePlatform'
}

export enum BackstageModuleEnum {
  /** 商品档案 */
  Goods = 'goods',
  /** 商品分类 */
  GoodsSort = 'goodsSort',
  /** 品牌 */
  Brand = 'brand',
  /** 加工模板 */
  Recipe = 'recipe',
  /** 计量单位 */
  Munit = 'munit',
  /** 规格管理 */
  Spec = 'spec',
  /** 门店商品库 */
  StoreGoods = 'storeGoods',
  /** 配送方案 */
  DistSchema = 'distSchema',
  /** 线上商品库 */
  OnlineGoods = 'onlineGoods',
  /** 线上商品分组 */
  OnlineGroup = 'onlineGroup',
  /** 发布规则 */
  OnlineRule = 'onlineRule',
  /** 电子秤管理 */
  EquipmentEws = 'equipmentEws',
  /** 支付配置 */
  CurrencySchema = 'currencySchema'
}

export enum BackstageSourceEnum {
  /** Web(总部) */
  WebHead = 'webHead',
  /** Web(门店) */
  WebStore = 'webStore'
}

/** 储值业务类型 */
export enum BalanceBusinessClassEnum {
  /** 充值 */
  Recharge = 'recharge',
  /** 充值赠送 */
  RechargeGive = 'rechargeGive',
  /** 储值退 */
  BalanceReturn = 'balanceReturn',
  /** 消费 */
  Consume = 'consume',
  /** 消费退 */
  ConsumeReturn = 'consumeReturn',
  /** 转储 */
  Dump = 'dump',
  /** 其它 */
  Other = 'other',
  /** 营销赠送 */
  MarketGive = 'marketGive',
  /** 营销回收 */
  MarketReturn = 'marketReturn',
  /** 调整 */
  Adjust = 'adjust'
}

/** 储值渠道来源 */
export enum BalanceChannelEnum {
  /** POS */
  Pos = 'pos',
  /** 线上 */
  Online = 'online'
}

export enum BankAccountBusinessTypeEnum {
  /** 供应商往来 */
  Supplier = 'supplier',
  /** 门店往来 */
  Store = 'store',
  /** 客户往来 */
  Customer = 'customer'
}

export enum BarcodePrintStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已打印 */
  Printed = 'printed'
}

export enum BarcodePrintTypeEnum {
  /** 促销码 */
  Promotion = 'promotion'
}

export enum BaseValidateResultEnum {
  /** 校验通过! */
  Success = 'success',
  /** 数据不可为空 */
  Blank = 'blank',
  /** 数据重复,请重新输入! */
  Duplicated = 'duplicated',
  /** 数据不存在,请重新输入! */
  NotExist = 'notExist',
  /** 长度过大 */
  LenBig = 'lenBig',
  /** 长度过小 */
  LenLess = 'lenLess',
  /** 格式不正确 */
  FormatError = 'formatError'
}

export enum BaseValidateTargetEnum {
  /** 编码 */
  Id = 'id',
  /** 名称 */
  Name = 'name'
}

/** 批量任务单据类型 */
export enum BatchTaskBillClassEnum {
  /** 配货单 */
  Dist = 'dist',
  /** 总部门店商品库 */
  HeadStoreGoods = 'headStoreGoods',
  /** 总部门店商品库 */
  StoreStoreGoods = 'storeStoreGoods',
  /** 仓库调拨 */
  WrhTransfer = 'wrhTransfer',
  /** 采购订单 */
  PurchaseOrder = 'purchaseOrder',
  /** 门店退仓单 */
  WrhAllocBack = 'wrhAllocBack',
  /** 配货通知单 */
  WrhAllocOrder = 'wrhAllocOrder',
  /** 仓库批发单 */
  WrhWholesaleOut = 'wrhWholesaleOut',
  /** 门店采购单据复核 */
  StoreSupplierCheck = 'storeSupplierCheck',
  /** 经销结算 */
  SellbuySettle = 'sellbuySettle',
  /** 加盟账户缴款 */
  JoinPayment = 'joinPayment',
  /** 分账单审核 */
  DivisionBillApprove = 'divisionBillApprove',
  /** 供应商预付款 */
  SupplierPrepay = 'supplierPrepay',
  /** 促销码 */
  PromoteBill = 'promoteBill'
}

export enum BiReportEnToCnTemplateEnum {
  /** 品类综合分析 */
  CategorySynthesis = 'categorySynthesis',
  /** 商品ABC趋势分析 */
  ProductAbcTrend = 'productABCTrend',
  /** 商品毛利分析 */
  ProductGrossProfit = 'productGrossProfit',
  /** 生鲜损耗分析 */
  FreshLossSynthesis = 'freshLossSynthesis',
  /** 新品表现分析 */
  NewProductPower = 'newProductPower',
  /** 供应商综合分析 */
  SupplierSynthesis = 'supplierSynthesis',
  /** 仓库库存综合分析 */
  WarehouseStockSynthesis = 'warehouseStockSynthesis',
  /** 门店库存综合分析 */
  StoreStockSynthesis = 'storeStockSynthesis',
  /** 门店库存周转分析 */
  StoreStockRevolve = 'storeStockRevolve',
  /** 门店库存预警 */
  StoreStockWarning = 'storeStockWarning',
  /** 门店销售日报 */
  StoreSellDaily = 'storeSellDaily',
  /** 门店商品销售日报 */
  StoreProductSellDaily = 'storeProductSellDaily',
  /** 门店销售汇总 */
  StoreSellSummary = 'storeSellSummary',
  /** 品类销售排行 */
  CategorySellRanked = 'categorySellRanked',
  /** 时段销售分析 */
  TimeSellSynthesis = 'timeSellSynthesis',
  /** 关键行为分析 */
  KeyBehaviorSynthesis = 'keyBehaviorSynthesis',
  /** 消费偏好分析 */
  ConsumerPreferenceSynthesis = 'consumerPreferenceSynthesis',
  /** 门店端品类销售排行 */
  CategorySynthesisStore = 'categorySynthesisStore',
  /** 门店端门店销售汇总 */
  StoreSellSummaryStore = 'storeSellSummaryStore',
  /** 门店端品类时段分析 */
  TimeSellSynthesisStore = 'timeSellSynthesisStore',
  /** 门店端生鲜损耗分析 */
  FreshLossSynthesisStore = 'freshLossSynthesisStore',
  /** 门店端商品毛利分析 */
  ProductGrossProfitStore = 'productGrossProfitStore'
}

export enum BiReportIdToEnTemplateEnum {
  /** 10961630931055181 */
  CategorySynthesis = 'categorySynthesis',
  /** 10961630931061528 */
  ProductAbcTrend = 'productABCTrend',
  /** 10961630931033575 */
  ProductGrossProfit = 'productGrossProfit',
  /** 10961645496156634 */
  FreshLossSynthesis = 'freshLossSynthesis',
  /** 10961631176572878 */
  NewProductPower = 'newProductPower',
  /** 10961630930976040 */
  SupplierSynthesis = 'supplierSynthesis',
  /** 10961630930918908 */
  WarehouseStockSynthesis = 'warehouseStockSynthesis',
  /** 10961630931040236 */
  StoreStockSynthesis = 'storeStockSynthesis',
  /** 10961630931022206 */
  StoreStockRevolve = 'storeStockRevolve',
  /** 10961630930992771 */
  StoreStockWarning = 'storeStockWarning',
  /** 10961630930941978 */
  StoreSellDaily = 'storeSellDaily',
  /** 10961631090594705 */
  StoreProductSellDaily = 'storeProductSellDaily',
  /** 10961630930962303 */
  StoreSellSummary = 'storeSellSummary',
  /** 10961630930985240 */
  CategorySellRanked = 'categorySellRanked',
  /** 10961630930950674 */
  TimeSellSynthesis = 'timeSellSynthesis',
  /** 10961636702007696 */
  KeyBehaviorSynthesis = 'keyBehaviorSynthesis',
  /** 10961636701996193 */
  ConsumerPreferenceSynthesis = 'consumerPreferenceSynthesis',
  /** 10961637116212123 */
  CategorySynthesisStore = 'categorySynthesisStore',
  /** 10961641952763017 */
  StoreSellSummaryStore = 'storeSellSummaryStore',
  /** 10961641952938292 */
  TimeSellSynthesisStore = 'timeSellSynthesisStore',
  /** 10961645496178056 */
  FreshLossSynthesisStore = 'freshLossSynthesisStore',
  /** 10961647849808336 */
  ProductGrossProfitStore = 'productGrossProfitStore'
}

/** 单据最大行系统配置 */
export enum BillConfigEnum {
  /** 门店进货单 */
  DirectAllocOut = 'directAllocOut',
  /** 门店退货单 */
  DirectAllocBack = 'directAllocBack',
  /** 仓库批发单 */
  WrhWholeSaleOut = 'wrhWholeSaleOut',
  /** 采购退货单 */
  PurchaseBack = 'purchaseBack',
  /** 批发出货单 */
  WholeSaleOut = 'wholeSaleOut',
  /** 采购订单 */
  PurchaseOrder = 'purchaseOrder',
  /** 门店改价单 */
  StoreRetailPriceAdjust = 'storeRetailPriceAdjust',
  /** 商品调价单 */
  GoodsRetailPriceAdjust = 'goodsRetailPriceAdjust',
  /** 采购进货单 */
  PurchaseIn = 'purchaseIn',
  /** 联营协议 */
  SupplierSellunionAgreement = 'supplierSellunionAgreement',
  /** 补差协议 */
  SupplierSubDiffAgreement = 'supplierSubDiffAgreement',
  /** 补差单 */
  SupplierSubDiff = 'supplierSubDiff',
  /** 自采入库单 */
  StoreIn = 'storeIn',
  /** 自采退货单 */
  StoreInBack = 'storeInBack',
  /** 采购协议 */
  SupplierAgreement = 'supplierAgreement',
  /** 配货出货单 */
  WrhAllocOut = 'wrhAllocOut',
  /** 门店退仓单 */
  WrhAllocBack = 'wrhAllocBack',
  /** 门店退仓通知单 */
  WrhAllocNoticeBack = 'wrhAllocNoticeBack',
  /** 配货通知单 */
  WrhAllocOrder = 'wrhAllocOrder',
  /** 仓库报损单 */
  WrhLoss = 'wrhLoss',
  /** 仓库报溢单 */
  WrhGain = 'wrhGain',
  /** 仓库调拨单 */
  WrhTransfer = 'wrhTransfer',
  /** 门店盘点流水单 */
  StoreCheckFlow = 'storeCheckFlow',
  /** 总部库存盘点流水单 */
  WrhCheckFlow = 'wrhCheckFlow',
  /** 门店退货申请单 */
  StoreReturn = 'storeReturn',
  /** 门店退货通知单 */
  StoreReturnNotify = 'storeReturnNotify',
  /** 门店报损单 */
  StoreLoss = 'storeLoss',
  /** 门店报溢单 */
  StoreGain = 'storeGain',
  /** 门店调拨单 */
  StoreTransfer = 'storeTransfer',
  /** 批发退货单 */
  WholeSaleBack = 'wholeSaleBack',
  /** 批发订单 */
  WholesaleOrder = 'wholesaleOrder',
  /** 仓库加工模板 */
  WrhProcessTemplate = 'wrhProcessTemplate',
  /** 促销单 */
  PromoteBill = 'promoteBill',
  /** 领用单 */
  StoreUse = 'storeUse',
  /** 仓库领用单 */
  WrhUse = 'wrhUse',
  /** 联营进货 */
  SellunionIn = 'sellunionIn',
  /** 联营退货 */
  SellunionBack = 'sellunionBack',
  /** 仓库批发退货单 */
  WrhWholeSaleBack = 'wrhWholeSaleBack',
  /** 拣货单 */
  Picking = 'picking',
  /** 门店直送收货 */
  StoreDirectReceive = 'storeDirectReceive',
  /** 门店直送退货 */
  StoreDirectReturn = 'storeDirectReturn',
  /** 客户设置-约定批发价 */
  CustomOmallPrice = 'customOmallPrice',
  /** 配送方案 */
  DistributionSchema = 'distributionSchema',
  /** 门店采购订单 */
  StorePurchaseOrder = 'storePurchaseOrder',
  /** 门店补货方案 */
  StoreReplenishPlan = 'storeReplenishPlan'
}

export enum BillTypeEnum {
  /** 采购协议 */
  SupplierAgreement = 'supplierAgreement',
  /** 生效采购协议 */
  SupplierAgreementEffect = 'supplierAgreementEffect',
  /** 联营协议 */
  SupplierSellunionAgreement = 'supplierSellunionAgreement',
  /** 补差协议 */
  SupplierSubDiffAgreement = 'supplierSubDiffAgreement',
  /** 补差单 */
  SupplierSubDiff = 'supplierSubDiff',
  /** 采购订单 */
  PurchaseOrder = 'purchaseOrder',
  /** 采购进货单 */
  PurchaseIn = 'purchaseIn',
  /** 采购退货单 */
  PurchaseBack = 'purchaseBack',
  /** 自采入库单 */
  StoreIn = 'storeIn',
  /** 自采退货单 */
  StoreInBack = 'storeInBack',
  /** 门店进货单 */
  DirectAllocOut = 'directAllocOut',
  /** 门店退货单 */
  DirectAllocBack = 'directAllocBack',
  /** 配货出货单 */
  WrhAllocOut = 'wrhAllocOut',
  /** 配货差异单 */
  WrhAllocDiff = 'wrhAllocDiff',
  /** 门店退仓单 */
  WrhAllocBack = 'wrhAllocBack',
  /** 门店退仓通知单 */
  WrhAllocNoticeBack = 'wrhAllocNoticeBack',
  /** 配货通知单 */
  WrhAllocOrder = 'wrhAllocOrder',
  /** 仓库报损单 */
  WrhLoss = 'wrhLoss',
  /** 仓库报溢单 */
  WrhGain = 'wrhGain',
  /** 仓库调拨单 */
  WrhTransfer = 'wrhTransfer',
  /** 盘点任务单 */
  CheckTask = 'checkTask',
  /** 盘点流水单 */
  CheckFlow = 'checkFlow',
  /** 盘点盈亏单 */
  CheckIn = 'checkIn',
  /** 预盘单 */
  PreCheck = 'preCheck',
  /** 门店成本调整单 */
  StoreInvPriceAdjust = 'storeInvPriceAdjust',
  /** 仓库成本调整单 */
  WrhInvPriceAdjust = 'wrhInvPriceAdjust',
  /** 门店订货单 */
  StoreRequire = 'storeRequire',
  /** 门店提单 */
  StoreLading = 'storeLading',
  /** 门店退货申请单 */
  StoreReturn = 'storeReturn',
  /** 门店退货通知单 */
  StoreReturnNotify = 'storeReturnNotify',
  /** 门店报损单 */
  StoreLoss = 'storeLoss',
  /** 门店报溢单 */
  StoreGain = 'storeGain',
  /** 门店调拨单 */
  StoreTransfer = 'storeTransfer',
  /** 门店补货方案 */
  ReplenishSchema = 'replenishSchema',
  /** 费用单 */
  SupplierFee = 'supplierFee',
  /** 预付款单 */
  SupplierPrepay = 'supplierPrepay',
  /** 联营结算单 */
  SupplierSellunionSettle = 'supplierSellunionSettle',
  /** 经销结算单 */
  SupplierSellbuySettle = 'supplierSellbuySettle',
  /** 收款单 */
  Receipt = 'receipt',
  /** 付款单 */
  Pay = 'pay',
  /** 加盟商缴款单 */
  JoinPayment = 'joinPayment',
  /** 加盟商扣款单 */
  JoinDeduction = 'joinDeduction',
  /** 付款凭证 */
  CustomerRecharge = 'customerRecharge',
  /** 门店改价单 */
  StoreRetailPriceAdjust = 'storeRetailPriceAdjust',
  /** 商品调价单 */
  GoodsRetailPriceAdjust = 'goodsRetailPriceAdjust',
  /** 门店日记账单 */
  StoreIncome = 'storeIncome',
  /** 门店缴款单 */
  StorePayment = 'storePayment',
  /** 批发出货单 */
  WholeSaleOut = 'wholeSaleOut',
  /** 批发退货单 */
  WholeSaleBack = 'wholeSaleBack',
  /** 批发订单 */
  WholesaleOrder = 'wholesaleOrder',
  /** 批发结算单 */
  WholesaleSettle = 'wholesaleSettle',
  /** 加工单 */
  StoreProcess = 'storeProcess',
  /** 仓库加工单 */
  WrhProcess = 'wrhProcess',
  /** 特殊条码打印 */
  BarcodePrint = 'barcodePrint',
  /** 促销单 */
  PromoteBill = 'promoteBill',
  /** 领用单 */
  StoreUse = 'storeUse',
  /** 仓库领用单 */
  WrhUse = 'wrhUse',
  /** 联营进货 */
  SellunionIn = 'sellunionIn',
  /** 联营退货 */
  SellunionBack = 'sellunionBack',
  /** 供应商合约 */
  SupplierContract = 'supplierContract',
  /** 仓库批发单 */
  WrhWholeSaleOut = 'wrhWholeSaleOut',
  /** 仓库批发退货单 */
  WrhWholeSaleBack = 'wrhWholeSaleBack',
  /** 采购计划单 */
  PurchasePlan = 'purchasePlan',
  /** 越库作业 */
  CrossDocking = 'crossDocking',
  /** 出清记录 */
  StoreClear = 'storeClear',
  /** 价签打印任务 */
  PricePrint = 'pricePrint',
  /** 期初库存导入 */
  InvInit = 'invInit',
  /** 库存合并单 */
  StoreBatchMerge = 'storeBatchMerge',
  /** 门店库存批号合并单 */
  StoreBatchNoMerge = 'storeBatchNoMerge',
  /** 仓库库存批号合并单 */
  WrhBatchNoMerge = 'wrhBatchNoMerge',
  /** 拣货波次单 */
  PickingWave = 'pickingWave',
  /** 拣货单 */
  Picking = 'picking',
  /** 提单记录表 */
  LadingRecord = 'ladingRecord',
  /** 门店结算协议 */
  StoreSettleAgreement = 'storeSettleAgreement',
  /** 分账单 */
  DivisionBill = 'divisionBill',
  /** 云资金缴款单 */
  StoreCashSettle = 'storeCashSettle',
  /** 提现申请单 */
  Withdraw = 'withdraw'
}

export enum BizDataGroupStatusEnum {
  /** 仓库 */
  Wrh = 'wrh',
  /** 门店 */
  Store = 'store',
  /** 供应商 */
  Supplier = 'supplier',
  /** 全部 */
  All = 'all',
  /** 指定范围 */
  Assign = 'assign'
}

export enum BizDistributionPlatformStoreStatusEnum {
  /** 已启用 */
  Enabled = 'enabled',
  /** 已停用 */
  Disabled = 'disabled',
  /** 未启用 */
  NotEnabled = 'notEnabled'
}

export enum BizMtpsStatusEnum {
  /** 审核中 */
  Audit = 'audit',
  /** 审核失败 */
  Failure = 'failure',
  /** 审核成功 */
  Success = 'success'
}

export enum BizOnlineOrderRTypeEnumEnum {
  /** 仅退款 */
  RefundOnly = 'refundOnly',
  /** 退货退款 */
  Returns = 'returns'
}

export enum BizUserStatusEnum {
  /** 仓库 */
  Wrh = 'wrh',
  /** 门店 */
  Store = 'store',
  /** 供应商 */
  Supplier = 'supplier',
  /** 全部 */
  All = 'all',
  /** 指定范围 */
  Assign = 'assign'
}

/** 称重计件的读重模式，取值：default-取资料重量，scale-读秤重量 */
export enum BulkPcsSkuWeightModeEnum {
  /** 取资料重量 */
  Default = 'default',
  /** 取秤读重 */
  Scale = 'scale'
}

export enum BulkTypeEnum {
  /** 重量码 */
  Qty = 'qty',
  /** 金额码 */
  Total = 'total',
  /** 金额重量码 */
  TotalQty = 'totalQty',
  /** 重量金额码 */
  QtyTotal = 'qtyTotal'
}

/** 业务设置类型 */
export enum BusinessConfigTypeEnum {
  /** 商品设置信息 */
  GoodsConfig = 'goodsConfig',
  /** 采配设置信息 */
  PurchaseDistConfig = 'purchaseDistConfig',
  /** 仓库设置信息 */
  WarehouseConfig = 'warehouseConfig',
  /** 批发设置信息 */
  WholeSaleConfig = 'wholeSaleConfig',
  /** 财务设置信息 */
  FinanceConfig = 'financeConfig',
  /** 收银设置信息 */
  CashierConfig = 'cashierConfig',
  /** 店务设置信息 */
  StoreBusinessConfig = 'storeBusinessConfig',
  /** 其他设置信息 */
  OthersConfig = 'othersConfig'
}

export enum BusinessInvGoodsOrderEnum {
  /** 默认排序 */
  CreatedDesc = 'createdDesc',
  /** 库存数量升序 */
  TotalQtyAsc = 'totalQtyAsc',
  /** 库存数量降序 */
  TotalQtyDesc = 'totalQtyDesc'
}

/** 商品业务类型 */
export enum BusinessModelEnum {
  /** 经销 */
  Distributor = 'distributor',
  /** 联营 */
  Jointor = 'jointor'
}

/** 商品范围业务类型 */
export enum BusinessTypeEnumEnum {
  /** 采购-总部可采购商品（仓采） */
  PurchaseWrhSale = 'purchaseWrhSale',
  /** 总部可采购退货商品（仓采） */
  PurchaseWrhSaleReturn = 'purchaseWrhSaleReturn',
  /** 采购-门店可采购商品（直配/直送/自采） */
  PurchaseStoreSale = 'purchaseStoreSale',
  /** 采购-门店可采购退货商品（直配/直送/自采） */
  PurchaseStoreSaleReturn = 'purchaseStoreSaleReturn',
  /** 批发-可批发商品（商城下单） */
  WholeMallSale = 'wholeMallSale',
  /** 批发-总部可批发商品（后台录单） */
  WholeWrhSale = 'wholeWrhSale',
  /** 批发-总部可批发退货商品（后台录单） */
  WholeWrhSaleReturn = 'wholeWrhSaleReturn',
  /** 批发-门店可批发商品（后台录单） */
  WholeStoreSale = 'wholeStoreSale',
  /** 批发-门店可批发退货商品（后台录单） */
  WholeStoreSaleReturn = 'wholeStoreSaleReturn'
}

/** 礼品卡卡余额排序枚举 */
export enum CardBalanceOrderByEnum {
  /** 默认排序 */
  CreatedDesc = 'createdDesc',
  /** 期初余额升序 */
  BeginBalanceAsc = 'beginBalanceAsc',
  /** 期初余额降序 */
  BeginBalanceDesc = 'beginBalanceDesc',
  /** 期末余额升序 */
  BalanceAsc = 'balanceAsc',
  /** 期末余额降序 */
  BalanceDesc = 'balanceDesc'
}

/** 礼品卡按售卡业务员排序枚举 */
export enum CardBySalerOrderByEnum {
  /** 默认排序 */
  DefaultAsc = 'defaultAsc',
  /** 售卡实付金额升序 */
  CardAmountAsc = 'cardAmountAsc',
  /** 售卡实付金额降序 */
  CardAmountDesc = 'cardAmountDesc'
}

/** 礼品卡汇总排序枚举 */
export enum CardDrptAllOrderByEnum {
  /** 默认排序 */
  DefaultAsc = 'defaultAsc',
  /** 制卡数量升序 */
  MakeCountAsc = 'makeCountAsc',
  /** 制卡数量降序 */
  MakeCountDesc = 'makeCountDesc',
  /** 售卡实付金额升序 */
  AllPayAmountAsc = 'allPayAmountAsc',
  /** 售卡实付金额降序 */
  AllPayAmountDesc = 'allPayAmountDesc',
  /** 消费金额升序 */
  ConsumeAmountAsc = 'consumeAmountAsc',
  /** 消费金额降序 */
  ConsumeAmountDesc = 'consumeAmountDesc'
}

/** 门店礼品卡流水排序枚举 */
export enum CardDrptStoreOrderByEnum {
  /** 默认排序 */
  DefaultAsc = 'defaultAsc',
  /** POS售卡实付金额升序 */
  PosPayAmountAsc = 'posPayAmountAsc',
  /** POS售卡实付金额降序 */
  PosPayAmountDesc = 'posPayAmountDesc',
  /** 消费金额升序 */
  ConsumeAmountAsc = 'consumeAmountAsc',
  /** 消费金额降序 */
  ConsumeAmountDesc = 'consumeAmountDesc',
  /** 退款金额升序 */
  RefundAmountAsc = 'refundAmountAsc',
  /** 退款金额降序 */
  RefundAmountDesc = 'refundAmountDesc',
  /** 转储金额升序 */
  ConvertAmountAsc = 'convertAmountAsc',
  /** 转储金额降序 */
  ConvertAmountDesc = 'convertAmountDesc'
}

/** 售卡交易类型 */
export enum CardServiceOrderBillClasEnum {
  /** 发售 */
  Sale = 'sale',
  /** 发售冲账 */
  SaleReturn = 'saleReturn'
}

export enum ChannelEnum {
  /** 小程序 */
  Weimob = 'weimob',
  /** 美团 */
  Meituan = 'meituan',
  /** 饿了么 */
  Eleme = 'eleme',
  /** 京东到家 */
  Jddj = 'jddj'
}

export enum CheckConditionEnum {
  /** 按金额 */
  Total = 'total',
  /** 按件数 */
  QtyStr = 'qtyStr'
}

export enum CheckFlowBillClassEnum {
  /** 初盘 */
  Primary = 'primary',
  /** 复盘 */
  Secondary = 'secondary',
  /** 复盘(随机抽盘) */
  RandomSecondary = 'randomSecondary',
  /** 系统 */
  SystemCheckFlow = 'systemCheckFlow'
}

export enum CheckFlowStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已提交 */
  Submited = 'submited',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum CheckInDetailStatusEnum {
  /** 允许盘入 */
  Yes = 'yes',
  /** 拒绝盘入 */
  No = 'no',
  /** 拒绝盘入并打回重新盘 */
  NoRepeat = 'noRepeat'
}

/** 盘点盈亏单来源类型 */
export enum CheckInSourceClassEnum {
  /** 自建 */
  Create = 'create',
  /** 总部下发 */
  HqCreate = 'hqCreate',
  /** 审核重盘 */
  Recheck = 'recheck',
  /** 手机快速盘点 */
  CheckQuick = 'checkQuick'
}

export enum CheckInStatusEnum {
  /** 待审核 */
  Init = 'init',
  /** 审核中 */
  Checking = 'checking',
  /** 已审核 */
  Submited = 'submited',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum CheckOrgEnum {
  /** 自审 */
  OwnCheck = 'ownCheck',
  /** 总部审核 */
  HqCheck = 'hqCheck'
}

export enum CheckRuleEnum {
  /** 无需审核 */
  NotCheck = 'notCheck',
  /** 需要审核 */
  NeedCheck = 'needCheck',
  /** 满足条件才审核 */
  SatisfyCondition = 'satisfyCondition'
}

export enum CheckTaskBillClassEnum {
  /** 门店盘点 */
  Store = 'store',
  /** 仓库盘点 */
  Wrh = 'wrh'
}

export enum CheckTaskConditionTypeEnum {
  /** 分类 */
  Sort = 'sort',
  /** 商品 */
  Goods = 'goods',
  /** 仓库 */
  Wrh = 'wrh',
  /** 门店 */
  Store = 'store'
}

export enum CheckTaskGoodsScopeEnum {
  /** 全部 */
  All = 'all',
  /** 按类别 */
  BySort = 'bySort',
  /** 按商品 */
  ByGoods = 'byGoods'
}

export enum CheckTaskRuleOutOfScopeEnum {
  /** 不允许盘点 */
  NotAllow = 'notAllow',
  /** 允许盘点 */
  Allow = 'allow'
}

export enum CheckTaskRuleUnCheckQtyEnum {
  /** 零库存 */
  Zero = 'zero',
  /** 当前库存 */
  Inv = 'inv'
}

export enum CheckTaskSearchTypeEnum {
  /** 未盘 */
  Normal = 'normal',
  /** 盘亏 */
  Less = 'less',
  /** 盘平 */
  Eq = 'eq',
  /** 盘盈 */
  More = 'more'
}

export enum CheckTaskSourceClassEnum {
  /** 自建 */
  Create = 'create',
  /** 总部下发 */
  HqCreate = 'hqCreate',
  /** 审核重盘 */
  Recheck = 'recheck'
}

export enum CheckTaskStatusEnum {
  /** 商品加载中 */
  GoodsLoading = 'goodsLoading',
  /** 待开始 */
  Init = 'init',
  /** 快照加载中 */
  SnapshotLoading = 'snapshotLoading',
  /** 进行中 */
  Doing = 'doing',
  /** 已结束 */
  Finished = 'finished',
  /** 已过期 */
  Expired = 'expired',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum CheckTaskWorkStatusEnum {
  /** 初盘 */
  Primary = 'primary',
  /** 复盘 */
  Secondary = 'secondary'
}

/** 商品码打印样式 */
export enum CodeStyleEnum {
  /** 竖条+文本 */
  VerticalText = 'verticalText',
  /** 竖条 */
  Vertical = 'vertical',
  /** 文本 */
  Text = 'text'
}

/** 条码类型 */
export enum CodeTypeEnum {
  /** CODE-128 */
  Code = 'code',
  /** EAN-13/EAN-8 */
  Ean = 'ean'
}

export enum ConfigsEnum {
  /** https://soms-test.qianfan123.com/iwms-report/decision/view/report */
  ReportUrl = 'reportUrl',
  /** fresh */
  PackType = 'packType',
  /** disabled */
  IsEnableWms = 'isEnableWms',
  /** false */
  Origin = 'origin',
  /** false */
  Sort = 'sort',
  /** false */
  IsEndMonth = 'isEndMonth',
  /** 27 */
  MonthDate = 'monthDate',
  /** true */
  SupplierNegativeSettle = 'supplierNegativeSettle',
  /** true */
  SellUnionSettleMergeStore = 'sellUnionSettleMergeStore',
  /** storeGrade */
  WrhAllocOutRule = 'wrhAllocOutRule',
  /** supplierAgreement */
  SupplierBackPrice = 'supplierBackPrice',
  /** allAllow */
  PrePayCardDump = 'prePayCardDump',
  /** notCheck */
  StoreCheckRule = 'storeCheckRule',
  /** notCheck */
  WrhCheckRule = 'wrhCheckRule',
  /** 海鼎零售云QA */
  CompanyName = 'companyName',
  /** true */
  IsCashierPayment = 'isCashierPayment',
  /** byDay */
  StorePaymentType = 'storePaymentType',
  /** partDark */
  PaymentSettings = 'paymentSettings',
  /** S00003,S00035 */
  StoreIds = 'storeIds',
  /** true */
  IsAllowMultiIn = 'isAllowMultiIn',
  /** true */
  IsAllowMoreIn = 'isAllowMoreIn',
  /** false */
  IsAgreementLimitOrder = 'isAgreementLimitOrder',
  /** unite */
  AllowMemberRule = 'allowMemberRule',
  /** 0 */
  MemberLevelCount = 'memberLevelCount',
  /** none */
  AllowInPriceRule = 'allowInPriceRule',
  /** true */
  IsAllowWhole = 'isAllowWhole',
  /** storeGoodsPrice */
  EwsCodeConfig = 'ewsCodeConfig',
  /** 30 */
  LadingReferDays = 'ladingReferDays',
  /** false */
  EnablePayPassword = 'enablePayPassword',
  /** 4763608 */
  DefaultPurchaserUuid = 'defaultPurchaserUuid',
  /** 25984e7e7b08466ca37fb10340f24910 */
  DefaultSupplierUuid = 'defaultSupplierUuid',
  /** true */
  EnableNegativeSales = 'enableNegativeSales',
  /** approved */
  PickingOutOrderStatus = 'pickingOutOrderStatus',
  /** 15:00 */
  ClearTime = 'clearTime',
  /** 2 */
  Big = 'big',
  /** 4 */
  Medium = 'medium',
  /** 6 */
  Small = 'small',
  /** true */
  IsAllowTransferAdjustPrice = 'isAllowTransferAdjustPrice',
  /** false */
  IsLadingQtyRound = 'isLadingQtyRound',
  /** false */
  EnableTransferAutoSubmit = 'enableTransferAutoSubmit',
  /** 72 */
  TransferAutoSubmitDuration = 'transferAutoSubmitDuration',
  /** 1 */
  OnlineOrderPrintCopies = 'onlineOrderPrintCopies',
  /** 3.0 */
  DefaultInputTaxRate = 'defaultInputTaxRate',
  /** 9.0 */
  DefaultOutputTaxRate = 'defaultOutputTaxRate',
  /** alloc */
  DefaultDeliverMode = 'defaultDeliverMode',
  /** false */
  DefaultSupplierRequired = 'defaultSupplierRequired',
  /** false */
  EnableRequireOrderAutoSubmit = 'enableRequireOrderAutoSubmit',
  /** scale */
  BulkPcsSkuWeightMode = 'bulkPcsSkuWeightMode',
  /** 10 */
  AllocDiffAutoSubmitTime = 'allocDiffAutoSubmitTime',
  /** true */
  IsEnableStoreDataPrivilege = 'isEnableStoreDataPrivilege',
  /** false */
  IsEnableWrhDataPrivilege = 'isEnableWrhDataPrivilege',
  /** store */
  PurchaseOrderBillClass = 'purchaseOrderBillClass',
  /** single */
  GoodsType = 'goodsType',
  /** true */
  IsAllowWrhInv = 'isAllowWrhInv',
  /** local */
  ConnetModel = 'connetModel',
  /** true */
  OneBarcodeForManySkuEnabled = 'oneBarcodeForManySkuEnabled',
  /** false */
  AllowSingleDecimal = 'allowSingleDecimal',
  /** 0.0 */
  FreshPickingStockThreshold = 'freshPickingStockThreshold',
  /** false */
  EnableWrhAllocAutoReceive = 'enableWrhAllocAutoReceive',
  /** false */
  IsSkipDiffForCheckTask = 'isSkipDiffForCheckTask',
  /** 08:00 */
  WmsInvCheckTime = 'wmsInvCheckTime',
  /** 0 */
  BillPrintingType = 'billPrintingType',
  /** false */
  AllowCouponFavour = 'allowCouponFavour',
  /** true */
  AllowPointFavour = 'allowPointFavour',
  /** true */
  WarningErrorPick = 'warningErrorPick',
  /** true */
  AllowAllPick = 'allowAllPick',
  /** false */
  IsGoodsLife = 'isGoodsLife',
  /** false */
  IsPurchaseOrder = 'isPurchaseOrder',
  /** false */
  IsSupplierAgreement = 'isSupplierAgreement',
  /** false */
  IsJoinAccount = 'isJoinAccount'
}

export enum ContentGroupEnum {
  /** 商品码 */
  Barcode = 'barcode',
  /** 商品信息 */
  Goods = 'goods',
  /** 商品信息 */
  PickingGoods = 'pickingGoods',
  /** 门店信息 */
  StoreInfo = 'storeInfo',
  /** 拣货信息 */
  PickingInfo = 'pickingInfo',
  /** 固定内容 */
  Fix = 'fix',
  /** 二维码 */
  Qrcode = 'qrcode'
}

export enum CostTypeEnum {
  /** 移动加权平均 */
  Shift = 'shift',
  /** 批次核算 */
  Batch = 'batch'
}

export enum CrossDockingStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 提交中 */
  SubmitDoing = 'submitDoing',
  /** 已越库 */
  Finished = 'finished'
}

export enum CurrencyPosDeviceTypeEnum {
  /** 收银机 */
  Pos = 'pos',
  /** 自助收银机 */
  SelfPos = 'selfPos',
  /** 收银机/自助收银机 */
  AllPos = 'allPos'
}

export enum CurrencySchemaDetailStatusEnum {
  /** 启用 */
  Enabled = 'enabled',
  /** 停用 */
  Disabled = 'disabled'
}

export enum CurrencySchemaStoreScopeEnum {
  /** 全部门店 */
  All = 'all',
  /** 指定门店 */
  Assign = 'assign'
}

export enum CurrencySchemaSystemDefaultEnum {
  /** 现金 */
  Cash = 'cash',
  /** 鼎付通 */
  HdPay = 'hdPay',
  /** 积分 */
  Score = 'score',
  /** 礼品卡 */
  Card = 'card',
  /** 提货卡 */
  Pickup = 'pickup',
  /** 电子礼品卡 */
  ElecGiftCard = 'elecGiftCard',
  /** 储值 */
  Balance = 'balance',
  /** 券支付 */
  Coupon = 'coupon',
  /** 银行卡记账 */
  BankCard = 'bankCard'
}

export enum CurrencySchemaSystemDefaultDefaultEnum {
  /** 1 */
  Cash = 'cash',
  /** 1 */
  HdPay = 'hdPay',
  /** 0 */
  Score = 'score',
  /** 0 */
  Card = 'card',
  /** 0 */
  Pickup = 'pickup',
  /** 0 */
  ElecGiftCard = 'elecGiftCard',
  /** 0 */
  Balance = 'balance',
  /** 0 */
  Coupon = 'coupon',
  /** 1 */
  BankCard = 'bankCard',
  /** 0 */
  GlCardPay = 'glCardPay',
  /** 0 */
  GlScorePay = 'glScorePay'
}

export enum CurrencySchemaSystemDefaultRemoveEnum {
  /** 0 */
  Cash = 'cash',
  /** 0 */
  HdPay = 'hdPay',
  /** 0 */
  Score = 'score',
  /** 0 */
  Card = 'card',
  /** 0 */
  Pickup = 'pickup',
  /** 0 */
  ElecGiftCard = 'elecGiftCard',
  /** 0 */
  Balance = 'balance',
  /** 0 */
  Coupon = 'coupon',
  /** 0 */
  BankCard = 'bankCard',
  /** 0 */
  SandPay = 'sandPay',
  /** 0 */
  GlCardPay = 'glCardPay',
  /** 0 */
  GlScorePay = 'glScorePay'
}

export enum CustomerAccountBillTypeEnum {
  /** 门店批发单 */
  WholeSaleOut = 'wholeSaleOut',
  /** 仓库批发单 */
  WrhWholeSaleOut = 'wrhWholeSaleOut',
  /** 门店批发退货单 */
  WholeSaleBack = 'wholeSaleBack',
  /** 仓库批发退货单 */
  WrhWholeSaleBack = 'wrhWholeSaleBack'
}

export enum CustomerBillCheckBillTypeEnum {
  /** 门店批发单 */
  WholeSaleOut = 'wholeSaleOut',
  /** 仓库批发单 */
  WrhWholeSaleOut = 'wrhWholeSaleOut',
  /** 门店批发退货单 */
  WholeSaleBack = 'wholeSaleBack',
  /** 仓库批发退货单 */
  WrhWholeSaleBack = 'wrhWholeSaleBack'
}

export enum CustomerBillCheckSettleTypeEnum {
  /** 现结 */
  ByNow = 'byNow',
  /** 账期结算 */
  ByPeriod = 'byPeriod'
}

export enum CustomerBillCheckStatusEnum {
  /** 已退货 */
  Returned = 'returned',
  /** 已收货 */
  Received = 'received',
  /** 已完成 */
  Finished = 'finished'
}

export enum CustomerOrderTypeEnum {
  /** 创建时间降序 */
  CreatedDesc = 'createdDesc',
  /** 创建时间升序 */
  CreatedAsc = 'createdAsc',
  /** 更新时间降序 */
  LastModifiedDesc = 'lastModifiedDesc',
  /** 更新时间升序 */
  LastModifiedAsc = 'lastModifiedAsc',
  /** 编码降序 */
  IdDesc = 'idDesc',
  /** 编码升序 */
  IdAsc = 'idAsc'
}

/** 付款凭证状态 */
export enum CustomerRechargeStatusEnum {
  /** 待审核 */
  Init = 'init',
  /** 已同意 */
  Approved = 'approved',
  /** 已拒绝 */
  Refused = 'refused'
}

/** 客户来源 */
export enum CustomerSourceEnum {
  /** 手工创建 */
  Manual = 'manual',
  /** 导入 */
  Import = 'import',
  /** 小程序注册 */
  OmallRegister = 'omallRegister'
}

/** 客户台账单据类型 */
export enum CustomerStandingBookTypeEnum {
  /** 门店批发单 */
  WholeSaleOut = 'wholeSaleOut',
  /** 仓库批发单 */
  WrhWholeSaleOut = 'wrhWholeSaleOut',
  /** 门店批发退货单 */
  WholeSaleBack = 'wholeSaleBack',
  /** 仓库批发退货单 */
  WrhWholeSaleBack = 'wrhWholeSaleBack',
  /** 收款单 */
  Receipt = 'receipt'
}

export enum CustomerStatusEnum {
  /** 已启用 */
  Enabled = 'enabled',
  /** 已停用 */
  Disabled = 'disabled'
}

/** 客户类型 */
export enum CustomerTypeEnum {
  /** 普通客户 */
  General = 'general',
  /** 协议客户 */
  Agreement = 'agreement'
}

/** 出账周期类型 */
export enum CycleTypeEnum {
  /** 按月出账 */
  Month = 'month',
  /** 按周出账 */
  Week = 'week'
}

export enum DataCheckBusinessTypeEnum {
  /** 删除商品资料 */
  GoodsRemove = 'goodsRemove',
  /** 删除商品资料-批量 */
  GoodsRemoveBatch = 'goodsRemoveBatch',
  /** 删除商品SKU资料 */
  SkuRemove = 'skuRemove'
}

export enum DataCheckResultStatusEnum {
  /** 待处理 */
  Init = 'init',
  /** 进行中 */
  Doing = 'doing',
  /** 成功 */
  Success = 'success',
  /** 失败 */
  Fail = 'fail'
}

export enum DataCheckTaskStatusEnum {
  /** 待处理 */
  Init = 'init',
  /** 进行中 */
  Doing = 'doing',
  /** 完成 */
  Finished = 'finished',
  /** 失败 */
  Failed = 'failed'
}

/** 扣率类型 */
export enum DeductRateTypeEnum {
  /** 普通扣率 */
  Normal = 'normal',
  /** 特殊扣率 */
  Special = 'special'
}

export enum DeductTypeEnum {
  /** 常规扣率 */
  Goods = 'goods',
  /** 阶梯扣率 */
  Stage = 'stage',
  /** 特殊扣率 */
  Special = 'special',
  /** 普通扣率 */
  Normal = 'normal',
  /** 综合扣率 */
  Combine = 'combine'
}

export enum DirectAllocBackBillClassEnum {
  /** 直配 */
  Alloc = 'alloc',
  /** 直送 */
  Direct = 'direct'
}

export enum DirectAllocBackStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待退货 */
  Returning = 'returning',
  /** 已发货 */
  Sended = 'sended',
  /** 已退货 */
  Returned = 'returned',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Invalid = 'invalid'
}

export enum DirectBackStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已退货 */
  Sended = 'sended',
  /** 待退货 */
  Returning = 'returning',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Invalid = 'invalid'
}

export enum DirectOutStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待收货 */
  Verified = 'verified',
  /** 已收货 */
  Received = 'received',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Abolished = 'abolished'
}

export enum DisDiffReasonEnum {
  /** 少送 */
  UnKnowWordc6087e18 = '少送',
  /** 多送 */
  UnKnowWord5cead3e7 = '多送',
  /** 配货差异名称恶霸霸霸霸霸 */
  UnKnowWord055dd280 = '配货差异名称恶霸霸霸霸霸'
}

/** 折扣规则 */
export enum DiscountRuleEnum {
  /** 折上折 */
  FoldUp = 'foldUp',
  /** 互斥 */
  Exclusion = 'exclusion'
}

/** 优惠类型 */
export enum DiscountTypeEnum {
  /** 促销价 */
  FixedPrice = 'fixedPrice',
  /** 折扣 */
  FixedDiscount = 'fixedDiscount',
  /** 减价 */
  FixedReduction = 'fixedReduction'
}

export enum DistBatchStatusEnum {
  /** 执行中 */
  Doing = 'doing',
  /** 已完成 */
  Done = 'done',
  /** 结束 */
  End = 'end'
}

export enum DistFxTypeEnumEnum {
  /** 乘法 */
  Multiply = 'multiply',
  /**  加法 */
  Addition = 'addition'
}

/** 财务配货类型 */
export enum DistInoutFlowDistTypeEnum {
  /** 仓配 */
  Dist = 'dist',
  /** 直配 */
  Alloc = 'alloc',
  /** 直送 */
  Direct = 'direct'
}

/** 财务配货业务动作 */
export enum DistInoutFlowOccurTypeEnum {
  /** 仓配 */
  Dist = 'dist',
  /** 直配 */
  Alloc = 'alloc',
  /** 直送 */
  Direct = 'direct'
}

export enum DistTypeEnum {
  /** 仓配 */
  Wrh = 'wrh',
  /** 直配 */
  Alloc = 'alloc',
  /** 直送 */
  Direct = 'direct'
}

export enum DistributionDiffStatusEnum {
  /** 待审核 */
  Init = 'init',
  /** 已审核 */
  Approved = 'approved'
}

/** 配货毛利类型枚举 */
export enum DistributionMarginTypeEnum {
  /** 仓配 */
  Wrh = 'wrh',
  /** 直配 */
  Direct = 'direct'
}

export enum DistributionPlatformTypeEnum {
  /** 麦芽田配送 */
  Maiyatian = 'maiyatian'
}

/** 分账单单据状态 */
export enum DivisionBillStatusEnum {
  /** 待审核 */
  WaitApprove = 'waitApprove',
  /** 已审核 */
  Approve = 'approve',
  /** 已作废 */
  Abort = 'abort',
  /** 已完成 */
  Finish = 'finish'
}

/** 日志动作 */
export enum DivisionLogActionEnum {
  /** 生成分账单 */
  CreateBill = 'createBill',
  /** 结算 */
  Settle = 'settle',
  /** 提现 */
  Withdraw = 'withdraw',
  /** 门店缴款 */
  StoreCashSettle = 'storeCashSettle'
}

/** 门店范围 */
export enum DivisionRuleStoreRangeEnum {
  /** 全部门店 */
  All = 'all',
  /** 部分门店 */
  Part = 'part'
}

/** 分账算法 */
export enum DivisionRuleStrategyEnum {
  /** 按照配货价分账 */
  Allocation = 'allocation',
  /** 按销售扣点分账 */
  Discount = 'discount'
}

/** 分账方案类型 */
export enum DivisionRuleTypeEnum {
  /** 营业款分账 */
  Revenue = 'revenue',
  /** 商品销售分账 */
  Sales = 'sales'
}

/** 分帐结算单状态 */
export enum DivisionSettleBillStatusEnum {
  /** 待结算 */
  WaitSettle = 'waitSettle',
  /** 结算中 */
  Doing = 'doing',
  /** 结算成功 */
  Succeed = 'succeed',
  /** 结算失败 */
  Fail = 'fail'
}

/** 提现状态 */
export enum DivisionWithdrawStatusEnum {
  /** 未提现 */
  Init = 'init',
  /** 提现失败 */
  Fail = 'FAIL',
  /** 已提现 */
  Finish = 'finish'
}

export enum EffectInvBillClassEnum {
  /** 采购进货 */
  PurchaseIn = 'purchaseIn',
  /** 采购退货 */
  PurchaseBack = 'purchaseBack',
  /** 自采入库 */
  StoreIn = 'storeIn',
  /** 自采退货 */
  StoreInBack = 'storeInBack',
  /** 配货出货 */
  WrhAllocOutWrh = 'wrhAllocOutWrh',
  /** 配货收货 */
  WrhAllocOutStore = 'wrhAllocOutStore',
  /** 配货差异 */
  WrhAllocDiffWrh = 'wrhAllocDiffWrh',
  /** 配货差异 */
  WrhAllocDiffStore = 'wrhAllocDiffStore',
  /** 门店退仓 */
  WrhAllocBackWrh = 'wrhAllocBackWrh',
  /** 门店退仓 */
  WrhAllocBackStore = 'wrhAllocBackStore',
  /** 门店退仓通知单 */
  WrhAllocNoticeBackStore = 'wrhAllocNoticeBackStore',
  /** 库存转出 */
  InventoryTransferOut = 'inventoryTransferOut',
  /** 库存转入 */
  InventoryTransferIn = 'inventoryTransferIn',
  /** 门店进货 */
  DirectAllocOut = 'directAllocOut',
  /** 门店退货 */
  DirectAllocBack = 'directAllocBack',
  /** 直配配进 */
  DirectAllocOutWrhIn = 'directAllocOutWrhIn',
  /** 直配配出 */
  DirectAllocOutWrhOut = 'directAllocOutWrhOut',
  /** 直配退配进 */
  DirectAllocBackWrhIn = 'directAllocBackWrhIn',
  /** 直配退配出 */
  DirectAllocBackWrhOut = 'directAllocBackWrhOut',
  /** 仓库分货 */
  WrhAllotPool = 'wrhAllotPool',
  /** 配货通知单 */
  WrhAllocOrder = 'wrhAllocOrder',
  /** 拣货单 */
  WrhPicking = 'wrhPicking',
  /** 仓库报损 */
  WrhLoss = 'wrhLoss',
  /** 仓库报溢 */
  WrhGain = 'wrhGain',
  /** 仓库调拨调入 */
  WrhTransferIn = 'wrhTransferIn',
  /** 仓库调拨调出 */
  WrhTransferOut = 'wrhTransferOut',
  /** 仓库加工单原料出库 */
  WrhProcessOut = 'wrhProcessOut',
  /** 仓库加工单成品入库 */
  WrhProcessIn = 'wrhProcessIn',
  /** 仓库领用 */
  WrhUseOut = 'wrhUseOut',
  /** 仓库领用退 */
  WrhUseBack = 'wrhUseBack',
  /** 门店报损 */
  StoreLoss = 'storeLoss',
  /** 门店报溢 */
  StoreGain = 'storeGain',
  /** 门店调拨调入 */
  StoreTransferIn = 'storeTransferIn',
  /** 门店调拨调出 */
  StoreTransferOut = 'storeTransferOut',
  /** 加工单原料出库 */
  StoreProcessOut = 'storeProcessOut',
  /** 加工单成品入库 */
  StoreProcessIn = 'storeProcessIn',
  /** 门店领用 */
  StoreUseOut = 'storeUseOut',
  /** 门店领用退 */
  StoreUseBack = 'storeUseBack',
  /** 盘点盘亏 */
  CheckInLoss = 'checkInLoss',
  /** 盘点盘盈 */
  CheckInGain = 'checkInGain',
  /** 批发 */
  SaleOut = 'saleOut',
  /** 批发退货 */
  SaleBack = 'saleBack',
  /** 仓库批发出货 */
  WrhWholeSaleOut = 'wrhWholeSaleOut',
  /** 仓库批发退货 */
  WrhWholeSaleBack = 'wrhWholeSaleBack',
  /** 成本调整 */
  AdjustCost = 'adjustCost',
  /** 负库存差异 */
  DiffCost = 'diffCost',
  /** 批次合并 */
  BatchMerge = 'batchMerge',
  /** 零售 */
  RetailOut = 'retailOut',
  /** 零售退货 */
  RetailBack = 'retailBack',
  /** 门店期初库存导入 */
  StoreInvInit = 'storeInvInit',
  /** 仓库期初库存导入 */
  WrhInvInit = 'wrhInvInit',
  /** 联营进货 */
  SellunionIn = 'sellunionIn',
  /** 联营退货 */
  SellunionBack = 'sellunionBack'
}

/** 电子价签提供商枚举 */
export enum ElectPriceTagChannelEnum {
  /** 汉朔 */
  Hanshow = 'hanshow',
  /** 商米 */
  Sunmi = 'sunmi',
  /** 思飞 */
  Sifei = 'sifei'
}

export enum ElectronicPriceTagConfigStatusEnum {
  /** 正常 */
  Normal = 'normal',
  /** 同步中 */
  Sync = 'sync'
}

export enum EntityCardStatusEnum {
  /** 未使用 */
  Init = 'init',
  /** 使用中 */
  Using = 'using',
  /** 已作废 */
  Aborted = 'aborted',
  /** 挂失中 */
  Lost = 'lost',
  /** 已冻结 */
  Freezed = 'freezed'
}

export enum EwsCodeConfigEnum {
  /** 取秤码价格 */
  EwsCodePrice = 'ewsCodePrice',
  /** 取门店商品价格 */
  StoreGoodsPrice = 'storeGoodsPrice'
}

export enum EwsPriceTypeEnum {
  /** 零售价 */
  RetailPrice = 'retailPrice',
  /** 会员价 */
  MemberPrice = 'memberPrice'
}

export enum EwsTemplateCodeTypeEnum {
  /** 金额数量码 */
  AmountWeight = 'amountWeight',
  /** 数量金额码 */
  WeightAmount = 'weightAmount',
  /** 金额码 */
  Amount = 'amount',
  /** 数量码 */
  Weight = 'weight'
}

export enum EwsTemplateStoreScopeEnum {
  /** 全部门店 */
  All = 'all',
  /** 部分门店 */
  Assign = 'assign'
}

export enum EwsTransferChannelEnum {
  /** 安卓POS */
  Android = 'android',
  /** PC传秤助手 */
  Windows = 'windows'
}

export enum EwsTransferStatusTypeEnum {
  /** 成功 */
  Success = 'success',
  /** 失败 */
  Fail = 'fail',
  /** 传秤中 */
  Doing = 'doing'
}

export enum EwsTypeEnum {
  /** 大华 */
  Dahua = 'dahua',
  /** 托利多 */
  Toledo = 'toledo',
  /** 顶尖 */
  Aclas = 'aclas',
  /** 容大 */
  Rongta = 'rongta',
  /** 胖柚AI秤 */
  Pangyou = 'pangyou',
  /** 寺冈 */
  Digi = 'digi'
}

/** 费用单-关联单据类型 */
export enum FeeBillClassEnum {
  /** 采购进货单 */
  PurchaseIn = 'purchaseIn',
  /** 采购退货单 */
  PurchaseBack = 'purchaseBack',
  /** 门店进货单 */
  StoreIn = 'storeIn',
  /** 门店退货单 */
  StoreBack = 'storeBack'
}

/** 费用精度进位类型 */
export enum FeeCarryTypeEnum {
  /** 四舍五入 */
  HalfUp = 'halfUp',
  /** 进位 */
  Up = 'up',
  /** 舍去 */
  Down = 'down'
}

export enum FeeCreateStatusEnum {
  /** 待审核 */
  Applyed = 'applyed',
  /** 已审核 */
  Approved = 'approved'
}

export enum FeeItemPayDirectionEnum {
  /** 收 */
  In = 'in',
  /** 付 */
  Out = 'out'
}

export enum FeeItemPayTypeEnum {
  /** 冲抵货款 */
  Offset = 'offset',
  /** 立即交款 */
  Prompt = 'prompt'
}

export enum FeeItemSourceEnum {
  /** 供应商 */
  Supplier = 'supplier',
  /** 门店 */
  Store = 'store',
  /** 客户 */
  Customer = 'customer'
}

export enum FeeItemTypeEnum {
  /** 固定类 */
  Fixed = 'fixed',
  /** 提成类 */
  Percent = 'percent',
  /** 手工类 */
  Manual = 'manual'
}

/** 费用精度 */
export enum FeePrecisionEnum {
  /** 元 */
  Yuan = 'yuan',
  /** 角 */
  Jiao = 'jiao',
  /** 分 */
  Fen = 'fen'
}

export enum FeeSubjectTypeEnum {
  /** 总部 */
  Head = 'head',
  /** 门店 */
  Store = 'store'
}

export enum FinanceInvGoodsOrderEnum {
  /** 默认排序 */
  CreatedDesc = 'createdDesc',
  /** 库存数量升序 */
  QtyAsc = 'qtyAsc',
  /** 库存数量降序 */
  QtyDesc = 'qtyDesc',
  /** 库存金额升序 */
  AmtAsc = 'amtAsc',
  /** 库存金额降序 */
  AmtDesc = 'amtDesc'
}

/** 财务销售渠道 */
export enum FinanceSaleChannelEnumEnum {
  /** POS */
  Pos = 'pos',
  /** 美团 */
  Meituan = 'meituan',
  /** 饿了么 */
  Eleme = 'eleme',
  /** 京东到家 */
  Jddj = 'jddj',
  /** 小程序 */
  Weimob = 'weimob'
}

export enum FontAlignEnum {
  /** 无 */
  None = 'none',
  /** 居左 */
  Left = 'left',
  /** 居中 */
  Center = 'center',
  /** 居右 */
  Right = 'right'
}

/** 运费类型枚举 */
export enum FreightTypeEnum {
  /** 固定运费 */
  Fixed = 'fixed',
  /** 运费模版 */
  Template = 'template'
}

export enum FxFormulaEnumEnum {
  /** 库存成本价 */
  InvPrice = 'invPrice',
  /** 采购协议价 */
  InPrice = 'inPrice',
  /** 最新进价 */
  LastInPrice = 'lastInPrice'
}

/** 取整类型 */
export enum GiveChangeTypeEnum {
  /** 不取整 */
  None = 'none',
  /** 去分 */
  Cent = 'cent',
  /** 去角和分 */
  Dime = 'dime'
}

export enum GoodsAdjustButtonEnumEnum {
  /** 保存 */
  Init = 'init',
  /** 提交 */
  Submit = 'submit',
  /** 审核 */
  Approved = 'approved'
}

export enum GoodsDistTypeEnum {
  /** 仓配 */
  Wrh = 'wrh',
  /** 直配 */
  Alloc = 'alloc',
  /** 直送 */
  Direct = 'direct',
  /** 无 */
  None = 'none'
}

export enum GoodsLockInvTypeEnumEnum {
  /** 盘亏 */
  CheckInLoss = 'checkInLoss',
  /** 报损 */
  Loss = 'loss',
  /** 配货差异 */
  DistDiff = 'distDiff'
}

export enum GoodsMeasurementModeEnumEnum {
  /** 称重 */
  Weigh = 'weigh',
  /** 计件 */
  Piece = 'piece'
}

export enum GoodsOrderTypeEnum {
  /** 创建时间降序 */
  CreatedDesc = 'createdDesc',
  /** 创建时间升序 */
  CreatedAsc = 'createdAsc',
  /** 更新时间降序 */
  LastModifiedDesc = 'lastModifiedDesc',
  /** 更新时间升序 */
  LastModifiedAsc = 'lastModifiedAsc',
  /** 编码降序 */
  IdDesc = 'idDesc',
  /** 编码升序 */
  IdAsc = 'idAsc'
}

export enum GoodsPriceAddModeEnum {
  /** 固定价格加价方式 */
  FixedPriceAddMode = 'FixedPriceAddMode',
  /** 固定价格方式 */
  FixedPriceMode = 'FixedPriceMode',
  /** 固定比例加价方式 */
  FixedRatioAddMode = 'FixedRatioAddMode'
}

export enum GoodsPriceAdjustStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 待生效 */
  Launching = 'launching',
  /** 已生效 */
  Launched = 'launched',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum GoodsPriceGoodsPositioningEnum {
  /** 机会商品 */
  Chance = 'chance',
  /** 基础商品 */
  Basics = 'basics',
  /** 引流商品 */
  Attract = 'attract'
}

/** 价格试算-调价日期类型 */
export enum GoodsPriceTrialAdjustPriceTypeEnum {
  /** 次日生效 */
  Tomorrow = 'tomorrow',
  /** 提交后即生效 */
  Immediately = 'immediately'
}

/** 价格试算-试算记录状态 */
export enum GoodsPriceTrialRecordStatusEnum {
  /** 计算中 */
  Processing = 'processing',
  /** 试算中 */
  Doing = 'doing',
  /** 试算失败 */
  Failed = 'failed',
  /** 试算完成 */
  Finished = 'finished',
  /** 中断 */
  Interrupt = 'interrupt',
  /** 已结束 */
  Close = 'close'
}

export enum GoodsPriceTypeEnum {
  /** 零售价 */
  RetailPrice = 'retailPrice',
  /** 会员价 */
  MemberPrice = 'memberPrice'
}

export enum GoodsPriceTypeForAddEnum {
  /** 门店库存成本价 */
  CostPrice = 'costPrice',
  /** 门店最新进价 */
  LastInPrice = 'lastInPrice'
}

export enum GoodsRemoveProcessEnum {
  /** 检查商品档案 */
  CheckGoods = 'checkGoods',
  /** 检查门店/线上/批发/拣货商品库 */
  CheckWrh = 'checkWrh',
  /** 检查协议/方案 */
  CheckAgreement = 'checkAgreement',
  /** 检查当前库存 */
  CheckStock = 'checkStock',
  /** 检查进销存单据-进货 */
  CheckBillIn = 'checkBillIn',
  /** 检查进销存单据-退货 */
  CheckBillOut = 'checkBillOut',
  /** 检查进销存单据-店务 */
  CheckBillStore = 'checkBillStore',
  /** 确认删除商品 */
  RemoveGoods = 'removeGoods'
}

export enum GoodsStatusEnum {
  /** 新品 */
  New = 'new',
  /** 正常 */
  Normal = 'normal',
  /** 预淘汰 */
  PreOut = 'preOut',
  /** 淘汰 */
  Out = 'out'
}

export enum GoodsSyncEnum {
  /** 成功 */
  Succed = 'succed',
  /** 失败 */
  Fail = 'fail',
  /** 同步中 */
  Doing = 'doing'
}

export enum GoodsTypeEnum {
  /** 散称商品 */
  Bulk = 'bulk',
  /** 单规格标品 */
  Single = 'single',
  /** 多规格标品 */
  Multi = 'multi',
  /** 组合商品 */
  Combine = 'combine',
  /** 分级商品 */
  Level = 'level'
}

export enum HolderAuthCodeStatusTypeEnum {
  /** 未使用 */
  Unactivated = 'unactivated',
  /** 已使用 */
  Activated = 'activated',
  /** 已失效 */
  Expired = 'expired'
}

export enum HolderOnlineStatusTypeEnum {
  /** 在线 */
  Online = 'online',
  /** 断开 */
  Offline = 'offline'
}

export enum HolderStatusTypeEnum {
  /** 未激活 */
  Unactivated = 'unactivated',
  /** 已激活 */
  Activated = 'activated'
}

export enum HttpLogResultTypeEnum {
  /** 默认 */
  Default = 'default',
  /** 成功 */
  Success = 'success',
  /** 失败 */
  Failure = 'failure'
}

export enum InoutBillClassEnum {
  /** 采购进货单 */
  PurchaseIn = 'purchaseIn',
  /** 采购退货单 */
  PurchaseBack = 'purchaseBack',
  /** 门店进货单(直送) */
  DirectIn = 'directIn',
  /** 门店进货单(直配) */
  AllocIn = 'allocIn',
  /** 门店退货单(直送) */
  DirectBack = 'directBack',
  /** 门店退货单(直配) */
  AllocBack = 'allocBack'
}

export enum InvFlowBillClassStoreEnum {
  /** 自采入库 */
  StoreIn = 'storeIn',
  /** 自采退货 */
  StoreInBack = 'storeInBack',
  /** 配货收货 */
  WrhAllocOutStore = 'wrhAllocOutStore',
  /** 配货差异 */
  WrhAllocDiffStore = 'wrhAllocDiffStore',
  /** 门店退仓 */
  WrhAllocBackStore = 'wrhAllocBackStore',
  /** 门店退仓通知单 */
  WrhAllocNoticeBackStore = 'wrhAllocNoticeBackStore',
  /** 库存转入 */
  InventoryTransferIn = 'inventoryTransferIn',
  /** 门店进货 */
  DirectAllocOut = 'directAllocOut',
  /** 门店退货 */
  DirectAllocBack = 'directAllocBack',
  /** 门店报损 */
  StoreLoss = 'storeLoss',
  /** 门店报溢 */
  StoreGain = 'storeGain',
  /** 门店调拨调入 */
  StoreTransferIn = 'storeTransferIn',
  /** 门店调拨调出 */
  StoreTransferOut = 'storeTransferOut',
  /** 加工单原料出库 */
  StoreProcessOut = 'storeProcessOut',
  /** 加工单成品入库 */
  StoreProcessIn = 'storeProcessIn',
  /** 门店领用 */
  StoreUseOut = 'storeUseOut',
  /** 门店领用退 */
  StoreUseBack = 'storeUseBack',
  /** 盘点盘亏 */
  CheckInLoss = 'checkInLoss',
  /** 盘点盘盈 */
  CheckInGain = 'checkInGain',
  /** 批发 */
  SaleOut = 'saleOut',
  /** 批发退货 */
  SaleBack = 'saleBack',
  /** 成本调整 */
  AdjustCost = 'adjustCost',
  /** 负库存差异 */
  DiffCost = 'diffCost',
  /** 批次合并 */
  BatchMerge = 'batchMerge',
  /** 零售 */
  RetailOut = 'retailOut',
  /** 零售退货 */
  RetailBack = 'retailBack',
  /** 门店期初库存导入 */
  StoreInvInit = 'storeInvInit',
  /** 联营进货 */
  SellunionIn = 'sellunionIn',
  /** 联营退货 */
  SellunionBack = 'sellunionBack'
}

export enum InvFlowBillClassWrhEnum {
  /** 采购进货 */
  PurchaseIn = 'purchaseIn',
  /** 采购退货 */
  PurchaseBack = 'purchaseBack',
  /** 配货出货 */
  WrhAllocOutWrh = 'wrhAllocOutWrh',
  /** 配货差异 */
  WrhAllocDiffWrh = 'wrhAllocDiffWrh',
  /** 门店退仓 */
  WrhAllocBackWrh = 'wrhAllocBackWrh',
  /** 库存转出 */
  InventoryTransferOut = 'inventoryTransferOut',
  /** 直配配进 */
  DirectAllocOutWrhIn = 'directAllocOutWrhIn',
  /** 直配配出 */
  DirectAllocOutWrhOut = 'directAllocOutWrhOut',
  /** 仓库报损 */
  WrhLoss = 'wrhLoss',
  /** 仓库报溢 */
  WrhGain = 'wrhGain',
  /** 仓库调拨调入 */
  WrhTransferIn = 'wrhTransferIn',
  /** 仓库调拨调出 */
  WrhTransferOut = 'wrhTransferOut',
  /** 仓库加工单原料出库 */
  WrhProcessOut = 'wrhProcessOut',
  /** 仓库加工单成品入库 */
  WrhProcessIn = 'wrhProcessIn',
  /** 仓库领用 */
  WrhUseOut = 'wrhUseOut',
  /** 仓库领用退 */
  WrhUseBack = 'wrhUseBack',
  /** 盘点盘亏 */
  CheckInLoss = 'checkInLoss',
  /** 盘点盘盈 */
  CheckInGain = 'checkInGain',
  /** 仓库批发出货 */
  WrhWholeSaleOut = 'wrhWholeSaleOut',
  /** 仓库批发退货 */
  WrhWholeSaleBack = 'wrhWholeSaleBack',
  /** 成本调整 */
  AdjustCost = 'adjustCost',
  /** 负库存差异 */
  DiffCost = 'diffCost',
  /** 批次合并 */
  BatchMerge = 'batchMerge',
  /** 仓库期初库存导入 */
  WrhInvInit = 'wrhInvInit'
}

export enum InvPriceAdjustReasonEnum {
  /** 进价错误 */
  UnKnowWord45458b73 = '进价错误',
  /** 成本错误 */
  UnKnowWord904b7df0 = '成本错误',
  /** 库存价调整名称恶霸霸霸霸 */
  UnKnowWord77394b0e = '库存价调整名称恶霸霸霸霸',
  /** 测试 */
  UnKnowWorddb06c78d = '测试',
  /** 微调 */
  UnKnowWord58fd75c1 = '微调',
  /** 调价测试 */
  UnKnowWorde6cef106 = '调价测试'
}

export enum InvPriceAdjustStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已完成 */
  Finished = 'finished'
}

export enum IoBatchBusinessTypeEnum {
  /** 导入品牌 */
  Brand = 'brand',
  /** 导入分类 */
  Sort = 'sort',
  /** 导入规格 */
  Spec = 'spec',
  /** 导入散称商品 */
  GoodsBulk = 'goodsBulk',
  /** 导入单规格标品 */
  GoodsSingle = 'goodsSingle',
  /** 导入分级商品 */
  GoodsLevel = 'goodsLevel',
  /** 导入多规格标品 */
  GoodsMulti = 'goodsMulti',
  /** 导入组合商品 */
  GoodsCombine = 'goodsCombine',
  /** 导入包装商品 */
  GoodsMunit = 'goodsMunit',
  /** 导入商品图片 */
  GoodsImg = 'goodsImg',
  /** 导入商品修改 */
  GoodsModify = 'goodsModify',
  /** 导入修改包装商品 */
  GoodsMunitModify = 'goodsMunitModify',
  /** 导入门店商品 */
  StoreGoods = 'storeGoods',
  /** 导入修改门店商品属性 */
  StoreGoodsSaleChannel = 'storeGoodsSaleChannel',
  /** 导入方案 */
  DistSchemaDetail = 'distSchemaDetail',
  /** 导入门店 */
  Store = 'store',
  /** 导入供应商 */
  Supplier = 'supplier',
  /** 导入供应商修改 */
  SupplierEdit = 'supplierEdit',
  /** 导入协议 */
  SupplierAgreement = 'supplierAgreement',
  /** 导入协议明细导入 */
  SupplierAgreementDetail = 'supplierAgreementDetail',
  /** 导入采购协议商品 */
  SupplierAgreementGoods = 'supplierAgreementGoods',
  /** 导入采购协议商品 */
  SupplierAgreementBatch = 'supplierAgreementBatch',
  /** 导入补差协议 */
  SupplierSubdiffAgreement = 'supplierSubdiffAgreement',
  /** 导入补差协议商品 */
  SupplierSubdiffAgreementGoods = 'supplierSubdiffAgreementGoods',
  /** 导入补差单 */
  SupplierSubdiff = 'supplierSubdiff',
  /** 导入补差单商品 */
  SupplierSubdiffGoods = 'supplierSubdiffGoods',
  /** 导入商品 */
  SellunionAgreementGoods = 'sellunionAgreementGoods',
  /** 导入客户 */
  Customer = 'customer',
  /** 导入总部采购单 */
  PurchaseOrderBill = 'purchaseOrderBill',
  /** 导入门店采购单 */
  StorePurchaseOrderBill = 'storePurchaseOrderBill',
  /** 导入商品 */
  PurchaseOrder = 'purchaseOrder',
  /** 导入商品 */
  DirectAllocOut = 'directAllocOut',
  /** 导入商品 */
  DirectAllocBack = 'directAllocBack',
  /** 导入调价单 */
  PriceAdjust = 'priceAdjust',
  /** 导入调价单商品 */
  PriceAdjustGoods = 'priceAdjustGoods',
  /** 价签打印按商品导入商品 */
  PriceTagSkuByGoods = 'priceTagSkuByGoods',
  /** 价签打印按门店导入商品 */
  PriceTagSkuByStore = 'priceTagSkuByStore',
  /** 导入盘点流水 */
  CheckFlow = 'checkFlow',
  /** 盘点盈亏单导入 */
  CheckIn = 'checkIn',
  /** 盘点任务商品 */
  CheckTaskGoods = 'checkTaskGoods',
  /** 导入批发商品 */
  WholeSaleOut = 'wholeSaleOut',
  /** 导入批发退货商品 */
  WholeSaleIn = 'wholeSaleIn',
  /** 导入礼品卡 */
  PrePayCard = 'prePayCard',
  /** 导入订货 */
  RequireOrder = 'requireOrder',
  /** 导入商品 */
  SpecialBarcodeGoods = 'specialBarcodeGoods',
  /** 导入仓库库存 */
  WrhInvInit = 'wrhInvInit',
  /** 导入门店库存 */
  StoreInvInit = 'storeInvInit',
  /** 仓库调拨单商品明细导入 */
  WrhTransferDetail = 'wrhTransferDetail',
  /** 导入明细 */
  StoreTransferDetail = 'storeTransferDetail',
  /** 导入明细 */
  StoreLossDetail = 'storeLossDetail',
  /** 导入明细 */
  StoreDirectIn = 'storeDirectIn',
  /** 导入明细 */
  StoreDirectBack = 'storeDirectBack',
  /** 导入商品 */
  StoreGoodsAdjustPrice = 'storeGoodsAdjustPrice',
  /** 导入明细 */
  WrhGainDetail = 'wrhGainDetail',
  /** 导入明细 */
  WrhLossDetail = 'wrhLossDetail',
  /** 导入明细 */
  WrhAllocOutDetail = 'wrhAllocOutDetail',
  /** 导入单据及明细 */
  WrhAllocOut = 'wrhAllocOut',
  /** 导入明细 */
  StoreGainDetail = 'storeGainDetail',
  /** 导入明细 */
  StoreUseDetail = 'storeUseDetail',
  /** 导入明细 */
  WrhUseDetail = 'wrhUseDetail',
  /** 导入明细 */
  WrhAllocBackDetail = 'wrhAllocBackDetail',
  /** 导入明细 */
  StoreInDetail = 'storeInDetail',
  /** 导入明细 */
  StoreInBackDetail = 'storeInBackDetail',
  /** 导入明细 */
  StoreReturnDetail = 'storeReturnDetail',
  /** 导入联营进货单商品 */
  SellunionInDetail = 'sellunionInDetail',
  /** 导入联营退货单商品 */
  SellunionBackDetail = 'sellunionBackDetail',
  /** 导入会员卡 */
  MemberCardEntity = 'memberCardEntity',
  /** 修改库存 */
  OnlineGoodsStock = 'onlineGoodsStock',
  /** 修改售价 */
  OnlineGoodsPrice = 'onlineGoodsPrice',
  /** 修改上下架 */
  OnlineGoodsStatus = 'onlineGoodsStatus',
  /** 修改分组及类目 */
  OnlineGoodsGroup = 'onlineGoodsGroup',
  /** 导入商品图片 */
  OnlineGoodsImg = 'onlineGoodsImg',
  /** 线上商品迁移 */
  OnlineGoodsTrans = 'onlineGoodsTrans',
  /** 导入分组 */
  OnlineGroup = 'onlineGroup',
  /** 导入商品分组图片 */
  OnlineGroupImg = 'onlineGroupImg',
  /** 导入柜组商品 */
  StoreGoodsCabinet = 'storeGoodsCabinet',
  /** 导入商品 */
  ReplenishSchemaGoods = 'replenishSchemaGoods',
  /** 导入员工 */
  User = 'user',
  /** 仓库调整库存成本 */
  IowrhInvAdjust = 'iowrhInvAdjust',
  /** 门店调整库存成本 */
  IostoreInvAdjust = 'iostoreInvAdjust',
  /** 导入门店货架位 */
  StoreShelves = 'storeShelves',
  /** 补货商品导入 */
  ReplenishGoods = 'replenishGoods',
  /** 导入商品 */
  PurchaseLadingGoods = 'purchaseLadingGoods',
  /** 导入批发单商品 */
  WrhWholeSaleOutDetail = 'wrhWholeSaleOutDetail',
  /** 导入批发退货单商品 */
  WrhWholeSaleBackDetail = 'wrhWholeSaleBackDetail',
  /** 导入市调价格 */
  GoodsMarketPrice = 'goodsMarketPrice',
  /** 仓库配货订单商品明细导入 */
  WrhAllocOrderDetail = 'wrhAllocOrderDetail',
  /** 配货通知单 */
  WrhAllocOrder = 'wrhAllocOrder',
  /** 客户商品约定价导入 */
  CustomerGoodsPrice = 'customerGoodsPrice',
  /** 客户等级价格导入 */
  CustomerLevelPrice = 'customerLevelPrice',
  /** 价签商品导入 */
  PriceTagGoods = 'priceTagGoods',
  /** 门店改价单据导入 */
  StoreGoodsAdjustPriceBill = 'storeGoodsAdjustPriceBill',
  /** 门店属性修改导入 */
  StoreInfoModify = 'storeInfoModify',
  /** 仓库分货池 */
  WrhAllotPool = 'wrhAllotPool',
  /** 订货明细导入 */
  DistPool = 'distPool',
  /** 门店配货池导入 */
  StoreDistPool = 'storeDistPool',
  /** 采购进货单导入 */
  PurchaseIn = 'purchaseIn',
  /** 分拣商品库 */
  PickingGoods = 'pickingGoods',
  /** 提货卡 */
  PickupCard = 'pickupCard',
  /** 批发管理 */
  WholesaleOrder = 'wholesaleOrder',
  /** 限时折扣导入促销商品模板 */
  TimelimitPromoteSku = 'timelimitPromoteSku',
  /** 采购进货商品导入 */
  PurchaseInGoods = 'purchaseInGoods',
  /** 采购退货商品导入 */
  PurchaseBackGoods = 'purchaseBackGoods',
  /** 门店退仓通知单商品明细导入 */
  WrhAllocNoticeBackGoods = 'wrhAllocNoticeBackGoods',
  /** 费用单导入 */
  SupplierFeeBill = 'supplierFeeBill',
  /** 门店费用单导入 */
  StoreSupplierFee = 'storeSupplierFee',
  /** 缴款单导入 */
  JoinPayment = 'joinPayment',
  /** 门店结算协议 */
  StoreSettleAgreement = 'storeSettleAgreement',
  /** 门店结算协议-扣率修改 */
  StoreSettleAgreementRate = 'storeSettleAgreementRate'
}

export enum IoInPlateEnum {
  /** 商品-品牌管理 */
  Brand = 'brand',
  /** 商品-商品分类 */
  Sort = 'sort',
  /** 商品-规格管理 */
  Spec = 'spec',
  /** 商品-商品档案 */
  GoodsBulk = 'goodsBulk',
  /** 商品-门店商品库 */
  StoreGoods = 'storeGoods',
  /** 商品-配送方案 */
  DistSchemaDetail = 'distSchemaDetail',
  /** 门店-门店档案 */
  Store = 'store',
  /** 采购-供应商档案 */
  Supplier = 'supplier',
  /** 采购-采购协议 */
  SupplierAgreement = 'supplierAgreement',
  /** 采购-采购协议-详情 */
  SupplierAgreementGoods = 'supplierAgreementGoods',
  /** 采购-采购协议-列表 */
  SupplierAgreementBatch = 'supplierAgreementBatch',
  /** 供应商-补差协议 */
  SupplierSubdiffAgreement = 'supplierSubdiffAgreement',
  /** 财务-补差单 */
  SupplierSubdiff = 'supplierSubdiff',
  /** 批发-客户档案 */
  Customer = 'customer',
  /** 零售-商品调价 */
  PriceAdjust = 'priceAdjust',
  /** 店务-价签打印 */
  PriceTagSkuByGoods = 'priceTagSkuByGoods',
  /** 仓库-盘点任务 */
  CheckFlow = 'checkFlow',
  /** 仓库-盘点盈亏单 */
  CheckIn = 'checkIn',
  /** 会员-礼品卡管理 */
  PrePayCard = 'prePayCard',
  /** 店务-订货 */
  RequireOrder = 'requireOrder',
  /** 销售-特殊条码打印-新建打印任务 */
  SpecialBarcodeGoods = 'specialBarcodeGoods',
  /** 设置-库存导入 */
  WrhInvInit = 'wrhInvInit',
  /** 会员-会员卡管理 */
  MemberCardEntity = 'memberCardEntity',
  /** 商品-线上商品库 */
  OnlineGoodsStock = 'onlineGoodsStock',
  /** 商品-线上商品分组 */
  OnlineGroup = 'onlineGroup',
  /** 门店-门店补货方案 */
  ReplenishSchemaGoods = 'replenishSchemaGoods',
  /** 设置-员工 */
  User = 'user',
  /** 商品-货架位管理 */
  StoreShelves = 'storeShelves',
  /** 店务管理-门店补货方案 */
  ReplenishGoods = 'replenishGoods',
  /** 采购-采购提单 */
  PurchaseLadingGoods = 'purchaseLadingGoods',
  /** 商品-价格管理 */
  GoodsMarketPrice = 'goodsMarketPrice',
  /** 订货-客户约定价 */
  CustomerGoodsPrice = 'customerGoodsPrice',
  /** 批发管理-客户等级 */
  CustomerLevelPrice = 'customerLevelPrice',
  /** 商品-价签打印 */
  PriceTagGoods = 'priceTagGoods',
  /** 店务-门店改价 */
  StoreGoodsAdjustPriceBill = 'storeGoodsAdjustPriceBill',
  /** 店务-门店档案 */
  StoreInfoModify = 'storeInfoModify',
  /** 仓库-分货池 */
  WrhAllotPool = 'wrhAllotPool',
  /** 仓库-提单 */
  DistPool = 'distPool',
  /** 订货管理-已订清单 */
  StoreDistPool = 'storeDistPool',
  /** 采购-采购进货 */
  PurchaseIn = 'purchaseIn',
  /** 仓库-分拣商品库 */
  PickingGoods = 'pickingGoods',
  /** 实体卡管理-提货卡 */
  PickupCard = 'pickupCard',
  /** 批发订单-批发订单 */
  WholesaleOrder = 'wholesaleOrder',
  /** 门店促销-导入商品 */
  TimelimitPromoteSku = 'timelimitPromoteSku',
  /** 采购-采购退货 */
  PurchaseBackGoods = 'purchaseBackGoods',
  /** 仓库-门店退仓通知单 */
  WrhAllocNoticeBackGoods = 'wrhAllocNoticeBackGoods',
  /** 结算管理-费用管理 */
  SupplierFeeBill = 'supplierFeeBill',
  /** 门店结算管理-费用管理 */
  StoreSupplierFee = 'storeSupplierFee',
  /** 收付管理-资金账户 */
  JoinPayment = 'joinPayment',
  /** 结算管理-分账管理-门店结算协议 */
  StoreSettleAgreement = 'storeSettleAgreement'
}

export enum IoInTemplateEnum {
  /** https://c-dev.weimobwmc.com/qa-static-resource/c83ca56e2de64b24a1048c0bf8a397ca.xlsx */
  Brand = 'brand',
  /** https://c.weimobwmc.com/ol-6LF7L/0a8a95a5794a4933986a89cf4566c7e9.xlsx */
  GoodsBulk = 'goodsBulk',
  /** https://c.weimobwmc.com/ol-6LF7L/aeee931136e34f838ba39b4dcf6cddb1.xlsx */
  GoodsSingle = 'goodsSingle',
  /** https://c.weimobwmc.com/ol-6LF7L/212936ed8e3d47969b4717673f162c8a.xlsx */
  GoodsMulti = 'goodsMulti',
  /** https://c.weimobwmc.com/ol-6LF7L/d2f39569ac0c40e99f00b12add595dfd.xlsx */
  GoodsLevel = 'goodsLevel',
  /** https://c.weimobwmc.com/ol-6LF7L/4ae6aa479f4e496aa40fff84a24e49f0.xlsx */
  GoodsCombine = 'goodsCombine',
  /** https://c.weimobwmc.com/ol-6LF7L/74c19df66b5f4328b6d51f76685406b3.xlsx */
  GoodsModify = 'goodsModify',
  /** https://c-dev.weimobwmc.com/qa-static-resource/d01d3cbc62494dfc96261db709d71ee5.xlsx */
  Sort = 'sort',
  /** https://c-dev.weimobwmc.com/qa-static-resource/657c78950f1549a9a49ef93812d2a245.xlsx */
  Spec = 'spec',
  /** https://c-dev.weimobwmc.com/qa-Ongm/a42dbe556bfa471c9fd9c61138a2a96e.xlsx */
  StoreGoods = 'storeGoods',
  /** https://c-dev.weimobwmc.com/qa-Ongm/90ed86b8112a4a178eecaaca2eecdfd9.xlsx */
  StoreGoodsSaleChannel = 'storeGoodsSaleChannel',
  /** https://c.weimobwmc.com/ol-6LF7L/6e4c0a8d9f5e4cea8c4fffceeefd4933.xlsx */
  Supplier = 'supplier',
  /** https://c.weimobwmc.com/ol-6LF7L/9b68d7cc5e7047c79151d04fdf8c2cb1.xlsx */
  SupplierEdit = 'supplierEdit',
  /** https://c-dev.weimobwmc.com/qa-static-resource/f4245900ed464bd3b10327033119d0fc.xlsx */
  SupplierAgreement = 'supplierAgreement',
  /** https://c-dev.weimobwmc.com/qa-static-resource/9d3ac9100ce045819fbd01d46b8365de.xlsx */
  SupplierAgreementGoods = 'supplierAgreementGoods',
  /** https://c-dev.weimobwmc.com/qa-static-resource/d8081e57e5694750876cc4b49d7d702b.xlsx */
  SupplierAgreementDetail = 'supplierAgreementDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/fb009ec8a55c444ba2df94fb5d774a3d.xlsx */
  SupplierAgreementBatch = 'supplierAgreementBatch',
  /** https://c-dev.weimobwmc.com/qa-Ongm/d39f4c9de92a439c8765a9baa8e79356.xlsx */
  DistSchemaDetail = 'distSchemaDetail',
  /** https://c.weimobwmc.com/ol-6LF7L/6dea7c8396d44de0a40d3b0aac3c39fc.xlsx */
  Customer = 'customer',
  /** https://c-dev.weimobwmc.com/qa-static-resource/d0d6913f2b264b49ab31d096bbf51efd.xlsx */
  GoodsImg = 'goodsImg',
  /** https://c-dev.weimobwmc.com/qa-static-resource/42e92d5b18384ccf89117a2a67f8d5d0.xlsx */
  OnlineGoodsImg = 'onlineGoodsImg',
  /** https://c-dev.weimobwmc.com/qa-static-resource/133e1c0af15b45caba03c90027f6476f.xlsx */
  OnlineGroupImg = 'onlineGroupImg',
  /** https://c-dev.weimobwmc.com/qa-static-resource/25350ad77df340998128750a6c74f818.xlsx */
  PurchaseOrder = 'purchaseOrder',
  /** https://c-dev.weimobwmc.com/qa-Ongm/14bee339b8824e70a4b4e95da88a7456.xlsx */
  DirectAllocOut = 'directAllocOut',
  /** https://c-dev.weimobwmc.com/qa-static-resource/f50a19e11e8a42cf96a3db6e86002aba.xlsx */
  DirectAllocBack = 'directAllocBack',
  /** https://c-dev.weimobwmc.com/qa-Ongm/09da2220da7b41ceaa82648e18ef830e.xlsx */
  PriceAdjust = 'priceAdjust',
  /** https://c-dev.weimobwmc.com/qa-Ongm/e7cd9800f0dd48acab7e9b70b4da1a00.xlsx */
  PriceAdjustGoods = 'priceAdjustGoods',
  /** https://c-dev.weimobwmc.com/qa-Ongm/43f583b7218f4a23a11136f5ebb93307.xlsx */
  PriceTagSkuByGoods = 'priceTagSkuByGoods',
  /** https://c-dev.weimobwmc.com/qa-Ongm/39a3874aff4143caa17a3d2255560d3a.xlsx */
  PriceTagSkuByStore = 'priceTagSkuByStore',
  /** https://c-dev.weimobwmc.com/qa-Ongm/01892343b6d74f3db1d0bf772d3140fc.xlsx */
  CheckFlow = 'checkFlow',
  /** https://c-dev.weimobwmc.com/qa-Ongm/643ffc3aafb24d278e22b73d8c88b200.xlsx */
  CheckIn = 'checkIn',
  /** https://c-dev.weimobwmc.com/qa-static-resource/20236d1d656a426ca0679311796dd37b.xlsx */
  SellunionAgreementGoods = 'sellunionAgreementGoods',
  /** https://c.weimobwmc.com/ol-6LF7L/c03b8dd36e9e4082bb9fa3f4bbeca60e.xlsx */
  WholeSaleOut = 'wholeSaleOut',
  /** https://c-dev.weimobwmc.com/qa-static-resource/67a57fddedd84a83b32a520616e72e53.xlsx */
  WholeSaleIn = 'wholeSaleIn',
  /** https://c-dev.weimobwmc.com/qa-Ongm/13a11c823aaf4ba0865b44f9e189dfa4.xlsx */
  PrePayCard = 'prePayCard',
  /** https://c-dev.weimobwmc.com/qa-static-resource/62138509d21346e8a83caf0cf8263ebd.xlsx */
  RequireOrder = 'requireOrder',
  /** https://c-dev.weimobwmc.com/qa-static-resource/22fd2d6474384ecbac8477cba65c4707.xlsx */
  SpecialBarcodeGoods = 'specialBarcodeGoods',
  /** https://c-dev.weimobwmc.com/qa-static-resource/e07c1d9f62dd420b8543a769a776eead.xlsx */
  WrhInvInit = 'wrhInvInit',
  /** https://c-dev.weimobwmc.com/qa-Ongm/cac3572fcf054ebdb78fbec1f9cccb00.xlsx */
  StoreInvInit = 'storeInvInit',
  /** https://c-dev.weimobwmc.com/qa-static-resource/e830131b4f1c4e73b82a62dddd1760ff.xlsx */
  WrhTransferDetail = 'wrhTransferDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/f242c789aa654808aad08241bedbbc54.xlsx */
  StoreTransferDetail = 'storeTransferDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/c32b9103efc74154b92e7b9a0de0bb8b.xlsx */
  StoreLossDetail = 'storeLossDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/60707ce1c9e9410f8f4ddad4692881d8.xlsx */
  StoreGoodsAdjustPrice = 'storeGoodsAdjustPrice',
  /** https://c-dev.weimobwmc.com/qa-static-resource/5dafdac4a83e432f8e125553466ac6a3.xlsx */
  StoreGainDetail = 'storeGainDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/0f644173c6b8448886fd814192607e87.xlsx */
  StoreUseDetail = 'storeUseDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/f7763b506ed541549090663b7d8e3721.xlsx */
  StoreInDetail = 'storeInDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/0ba44d9a78a44fd186cc93fc5f4cbc30.xlsx */
  StoreInBackDetail = 'storeInBackDetail',
  /** https://c-dev.weimobwmc.com/qa-Ongm/0025fb4b88114750aa721a9a072973dc.xlsx */
  StoreDirectIn = 'storeDirectIn',
  /** https://c-dev.weimobwmc.com/qa-static-resource/184e3be0b9b54720b3f1ab1ff897b96b.xlsx */
  StoreDirectBack = 'storeDirectBack',
  /** https://c-dev.weimobwmc.com/qa-static-resource/1815ec2b100f4d5eadc4c2378909e621.xlsx */
  StoreReturnDetail = 'storeReturnDetail',
  /** https://c.weimobwmc.com/ol-6LF7L/080c5761b382407ea0aaae09cd2effb1.xlsx */
  WrhAllocOutDetail = 'wrhAllocOutDetail',
  /** https://c.weimobwmc.com/ol-6LF7L/aad2fe014cfd4ba28c8bca25e2a3f3e0.xlsx */
  WrhAllocOut = 'wrhAllocOut',
  /** https://c.weimobwmc.com/ol-6LF7L/8fa72f5021df437d9fe17782e1add9d3.xlsx */
  WrhAllocOrder = 'wrhAllocOrder',
  /** https://c-dev.weimobwmc.com/qa-static-resource/194bbb8d524340ab9f3914f7d76cbb4f.xlsx */
  WrhAllocBackDetail = 'wrhAllocBackDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/52487661a04540768cb732e800da3aa5.xlsx */
  Store = 'store',
  /** https://c-dev.weimobwmc.com/qa-static-resource/820bc58ea8d7454c84a1a54841779cff.xlsx */
  WrhGainDetail = 'wrhGainDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/7672ff887d5c44ebb077a36b67ded032.xlsx */
  WrhLossDetail = 'wrhLossDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/ec115f69dae94ce8a2b92284b8b39c26.xlsx */
  SellunionInDetail = 'sellunionInDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/ec115f69dae94ce8a2b92284b8b39c26.xlsx */
  SellunionBackDetail = 'sellunionBackDetail',
  /** https://c-dev.weimobwmc.com/qa-Ongm/48d63aa4e6254e3590b0e8f03ced410e.xlsx */
  MemberCardEntity = 'memberCardEntity',
  /** https://c-dev.weimobwmc.com/qa-Ongm/a66414d7d0444d3aacffed2dc3c6c206.xlsx */
  OnlineGoodsStock = 'onlineGoodsStock',
  /** https://c-dev.weimobwmc.com/qa-Ongm/fd638e0b8ea343f9aaef4f395360a038.xlsx */
  OnlineGoodsPrice = 'onlineGoodsPrice',
  /** https://c-dev.weimobwmc.com/qa-Ongm/66755a02359c40e2ab058c6745f03aba.xlsx */
  OnlineGoodsStatus = 'onlineGoodsStatus',
  /** https://c-dev.weimobwmc.com/qa-Ongm/884fb730155245988a10e0979c415d6b.xlsx */
  OnlineGoodsGroup = 'onlineGoodsGroup',
  /** https://c-dev.weimobwmc.com/qa-static-resource/1eac925579404c43bb6f7cac86c8a1ef.xlsx */
  OnlineGroup = 'onlineGroup',
  /** https://c-dev.weimobwmc.com/qa-static-resource/29ac593560bd43489aa3ec35cf726857.xlsx */
  StoreGoodsCabinet = 'storeGoodsCabinet',
  /** https://c-dev.weimobwmc.com/qa-Ongm/158bd2091a1a4a26a0efc03ff00d557f.xlsx */
  ReplenishSchemaGoods = 'replenishSchemaGoods',
  /** https://c-dev.weimobwmc.com/qa-static-resource/1354a29376fe47caa2b4d9dcb7b9bc1b.xlsx */
  User = 'user',
  /** https://c-dev.weimobwmc.com/qa-Ongm/82efb0db3c434e3f9479a6372cce2af4.xlsx */
  IowrhInvAdjust = 'iowrhInvAdjust',
  /** https://c-dev.weimobwmc.com/qa-Ongm/82efb0db3c434e3f9479a6372cce2af4.xlsx */
  IostoreInvAdjust = 'iostoreInvAdjust',
  /** https://c-dev.weimobwmc.com/qa-static-resource/dd3b6d2fc9404f45b388a23cd8111eea.xlsx */
  StoreShelves = 'storeShelves',
  /** https://c-dev.weimobwmc.com/qa-static-resource/ea5aaeb8d55f4664a2cd069450b49f69.xlsx */
  PurchaseLadingGoods = 'purchaseLadingGoods',
  /** https://c.weimobwmc.com/ol-6LF7L/3bef1581ca844490a5e6939b6473ef4e.xlsx */
  WrhWholeSaleOutDetail = 'wrhWholeSaleOutDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/4e9360da32174f3f8a5ab697b715c572.xlsx */
  WrhWholeSaleBackDetail = 'wrhWholeSaleBackDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/0c0d56a0d0fe4829b47c5770017f5e8a.xlsx */
  WrhAllocOrderDetail = 'wrhAllocOrderDetail',
  /** https://c-dev.weimobwmc.com/qa-static-resource/6af00cea7c57433ea2aab53395568fc4.xlsx */
  GoodsMarketPrice = 'goodsMarketPrice',
  /** https://c-dev.weimobwmc.com/qa-static-resource/f03df9427d6f4dd98126be0eb81d436d.xlsx */
  CustomerGoodsPrice = 'customerGoodsPrice',
  /** https://c-dev.weimobwmc.com/qa-static-resource/47a97b4bb9724af9b6db9a4fe058b0b9.xlsx */
  CustomerLevelPrice = 'customerLevelPrice',
  /** https://c-dev.weimobwmc.com/qa-Ongm/208a2ef2c95a47e198f03dfdf592295b.xlsx */
  PriceTagGoods = 'priceTagGoods',
  /** https://c-dev.weimobwmc.com/qa-static-resource/84c594b3a4ef44bebceddfd23396e414.xlsx */
  StoreGoodsAdjustPriceBill = 'storeGoodsAdjustPriceBill',
  /** https://c-dev.weimobwmc.com/qa-static-resource/43fb66798a474cac9acecd2e4b83b553.xlsx */
  SupplierSubdiffAgreement = 'supplierSubdiffAgreement',
  /** https://c-dev.weimobwmc.com/qa-static-resource/843118637ef74a83aeb52f2dc15c1a03.xlsx */
  SupplierSubdiffAgreementGoods = 'supplierSubdiffAgreementGoods',
  /** https://c-dev.weimobwmc.com/qa-static-resource/83a090146fc3493c99be0d9e1eef1ce7.xlsx */
  SupplierSubdiff = 'supplierSubdiff',
  /** https://c-dev.weimobwmc.com/qa-static-resource/e190472cf2ab493983c3b9ee709b4746.xlsx */
  SupplierSubdiffGoods = 'supplierSubdiffGoods',
  /** https://c-dev.weimobwmc.com/qa-Ongm/3e114fd009064a5a9ac557ae15e4e5da.xlsx */
  WrhAllotPool = 'wrhAllotPool',
  /** https://c-dev.weimobwmc.com/qa-Ongm/7fd354fa22b84363a98c34121977c07c.xlsx */
  StoreInfoModify = 'storeInfoModify',
  /** https://c.weimobwmc.com/ol-6LF7L/e2664cad3c3c454187b58e8106cdd441.xlsx */
  DistPool = 'distPool',
  /** https://c.weimobwmc.com/ol-6LF7L/4729c3a91a5a4e6689bfa73ba2f12f1d.xlsx */
  StoreDistPool = 'storeDistPool',
  /** https://c-dev.weimobwmc.com/qa-Ongm/c93a5bddf4cd454c9fe442a4056302a5.xlsx */
  PurchaseIn = 'purchaseIn',
  /** https://c-dev.weimobwmc.com/qa-Ongm/bab0a606e87549c2b5f04648f4fd64c0.xlsx */
  CheckTaskGoods = 'checkTaskGoods',
  /** https://c-dev.weimobwmc.com/qa-Ongm/34b59018da584b1cbf0c79cf08182d5b.xlsx */
  PickingGoods = 'pickingGoods',
  /** https://c-dev.weimobwmc.com/qa-OnVi/5d0cda8b647e432d957b7e3d194233aa.xlsx */
  WrhUseDetail = 'wrhUseDetail',
  /** https://c-dev.weimobwmc.com/qa-Ongm/032aa310fd6944eb986c0a9a56491d50.xlsx */
  PickupCard = 'pickupCard',
  /** https://c.weimobwmc.com/ol-6LF7L/331549650b4d42a88c6704de2578376c.xlsx */
  WholesaleOrder = 'wholesaleOrder',
  /** https://c-dev.weimobwmc.com/qa-Ongm/86b83a742529437ab5b6c88157422ffa.xlsx */
  TimelimitPromoteSku = 'timelimitPromoteSku',
  /** https://c-dev.weimobwmc.com/qa-static-resource/194bbb8d524340ab9f3914f7d76cbb4f.xlsx */
  WrhAllocNoticeBackGoods = 'wrhAllocNoticeBackGoods',
  /** https://c-dev.weimobwmc.com/qa-Ongm/4d5cf1c18b3c44b0bcd7437b407f9845.xlsx */
  SupplierFeeBill = 'supplierFeeBill',
  /** https://c.weimobwmc.com/ol-6LF7L/8bf6f6a8d64e49afa382143c8d918781.xlsx */
  StoreSupplierFee = 'storeSupplierFee',
  /** https://c-dev.weimobwmc.com/qa-Ongm/2a63eff3d8834246bfc3f1677593aa66.xlsx */
  PurchaseInGoods = 'purchaseInGoods',
  /** https://c.weimobwmc.com/ol-6LF7L/001ac12880c14519bd8d96701ff55bb7.xlsx */
  PurchaseBackGoods = 'purchaseBackGoods',
  /** https://c.weimobwmc.com/ol-6LF7L/df70b1b5dfd8425d9ef31dbe74007ad7.xlsx */
  ReplenishGoods = 'replenishGoods',
  /** https://c.weimobwmc.com/ol-6LF7L/1839b381d3464710aa01265ace5216ff.xlsx */
  PurchaseOrderBill = 'purchaseOrderBill',
  /** https://c.weimobwmc.com/ol-6LF7L/0093c8eaf03343c884704c55e370bd3b.xlsx */
  StorePurchaseOrderBill = 'storePurchaseOrderBill',
  /** https://c.weimobwmc.com/ol-6LF7L/d98b91809fa549c791e93b40bef19299.xlsx */
  JoinPayment = 'joinPayment',
  /** https://c.weimobwmc.com/ol-6LF7L/e563938c0c9c411abf4c2b1f67c2a403.xlsx */
  StoreSettleAgreement = 'storeSettleAgreement',
  /** https://c.weimobwmc.com/ol-6LF7L/878de525512c4dec8caf9f5ce0cc463d.xlsx */
  StoreSettleAgreementRate = 'storeSettleAgreementRate'
}

export enum IoOutBusinessTypeEnum {
  /** 商品 */
  Goods = 'goods',
  /** 导出盘点商品 */
  CheckGoodsDetail = 'checkGoodsDetail',
  /** 导出查询结果 */
  StoreGoods = 'storeGoods',
  /** 导出查询结果 */
  Supplier = 'supplier',
  /** 导出查询结果 */
  SupplierAgreement = 'supplierAgreement',
  /** 导出查询结果 */
  SupplierSellunionAgreement = 'supplierSellunionAgreement',
  /** 导出方案 */
  DistSchema = 'distSchema',
  /** 总部-供应链-仓库库存查询 */
  InvQueryWrh = 'invQueryWrh',
  /** 总部-供应链-门店库存查询 */
  InvQueryStore = 'invQueryStore',
  /** 总部-供应链-批次库存查询 */
  InvQueryBatch = 'invQueryBatch',
  /** 总部-供应链-效期库存查询 */
  InvQueryValidDate = 'invQueryValidDate',
  /** 门店-店务-效期库存查询 */
  StoreInvQueryValidDate = 'storeInvQueryValidDate',
  /** 总部-仓库业务库存导出 */
  BusinessWrhInv = 'businessWrhInv',
  /** 总部-门店业务库存导出 */
  BusinessStoreInv = 'businessStoreInv',
  /** 门店业务库存导出 */
  StoreBusinessStoreInv = 'storeBusinessStoreInv',
  /** 导出查询结果 */
  FinanceWrhInv = 'financeWrhInv',
  /** 财务库存 */
  FinanceStoreInv = 'financeStoreInv',
  /** 导出查询结果 */
  HqInvFlow = 'hqInvFlow',
  /** 总部-门店库存流水导出 */
  StoreInvFlow = 'storeInvFlow',
  /** 门店-门店库存流水导出 */
  StoreInvFlowNew = 'storeInvFlowNew',
  /** 导出品牌 */
  Brand = 'brand',
  /** 导出查询结果 */
  BuyRecord = 'buyRecord',
  /** 导出查询结果 */
  BuyPayType = 'buyPayType',
  /** 导出查询结果 */
  BuyRecordGoods = 'buyRecordGoods',
  /** 导出授权码 */
  HolderAuthCode = 'holderAuthCode',
  /** 导出查询结果 */
  SellUnionAccountGoods = 'sellUnionAccountGoods',
  /** 导出查询结果 */
  SellUnionAccountDetail = 'sellUnionAccountDetail',
  /** 导出查询结果 */
  StoreOrderReference = 'storeOrderReference',
  /** 按单据-导出查询结果 */
  SellBuyAccountBill = 'sellBuyAccountBill',
  /** 按明细-导出查询结果 */
  SellBuyAccountDetail = 'sellBuyAccountDetail',
  /** 按销售-导出查询结果 */
  SellBuyAccountSale = 'sellBuyAccountSale',
  /** 按单据-导出查询结果 */
  CustomerAccountBill = 'customerAccountBill',
  /** 按明细-导出查询结果 */
  CustomerAccountDetail = 'customerAccountDetail',
  /** 按单据-导出查询结果 */
  StoreCustomerAccountBill = 'storeCustomerAccountBill',
  /** 按明细-导出查询结果 */
  StoreCustomerAccountDetail = 'storeCustomerAccountDetail',
  /** 供应商订单 */
  SupplierOrder = 'supplierOrder',
  /** 门店库存（供应商协议下） */
  SupplierStoreGoodsInv = 'supplierStoreGoodsInv',
  /** 仓库-盘点结果-按明细 */
  WrhCheckResultDetail = 'wrhCheckResultDetail',
  /** 门店-盘点结果-按明细 */
  StoreCheckResultDetail = 'storeCheckResultDetail',
  /** 门店销售（供应商协议下） */
  GoodsSaleForSupplier = 'goodsSaleForSupplier',
  /** 线上订单_按订单导出 */
  OnlineOrder = 'onlineOrder',
  /** 线上订单_按商品导出 */
  OnlineOrderByGoods = 'onlineOrderByGoods',
  /** 线上退款单 */
  OnlineOrderRefund = 'onlineOrderRefund',
  /** 线上商品库 */
  OnlineGoods = 'onlineGoods',
  /** 线上商品分组 */
  OnlineGroup = 'onlineGroup',
  /** 线上订单评价 */
  OnlineOrderComment = 'onlineOrderComment',
  /** 线上商品信息拉取 */
  OnlineDataPull = 'onlineDataPull',
  /** 线上商品信息整合 */
  OnlineGoodsOutModel = 'onlineGoodsOutModel',
  /** 采购订单明细 */
  PurchaseOrderDetail = 'purchaseOrderDetail',
  /** 盘点流水明细 */
  CheckFlowDetailWrh = 'checkFlowDetailWrh',
  /** 盘点任务明细 */
  CheckTaskDetailWrh = 'checkTaskDetailWrh',
  /** 盘点任务明细 */
  CheckInDetailWrh = 'checkInDetailWrh',
  /** 盘点流水明细 */
  CheckFlowDetailStore = 'checkFlowDetailStore',
  /** 盘点任务明细 */
  CheckTaskDetailStore = 'checkTaskDetailStore',
  /** 盘点任务明细 */
  CheckInDetailStore = 'checkInDetailStore',
  /** 批发单明细 */
  WholeSaleOutDetail = 'wholeSaleOutDetail',
  /** 批发退货单明细 */
  WholeSaleBackDetail = 'wholeSaleBackDetail',
  /** 批发订单明细 */
  WholesaleOrder = 'wholesaleOrder',
  /** 批发订单批量导出 */
  WholesaleOrderBatch = 'wholesaleOrderBatch',
  /** 批发订单批量导出 */
  WholesaleOrderBatchSum = 'wholesaleOrderBatchSum',
  /** 批发订单交叉表导出 */
  WholesaleOrderCross = 'wholesaleOrderCross',
  /** 门店改价单明细 */
  RetailPriceAdjustDetail = 'retailPriceAdjustDetail',
  /** 总部调价单明细 */
  GoodsPriceAdjustDetail = 'goodsPriceAdjustDetail',
  /** 设备授权码信息 */
  EquipmentAuthCodeInfo = 'equipmentAuthCodeInfo',
  /** 会员卡导出 */
  MemberCardEntity = 'memberCardEntity',
  /** 门店商品(门店视角) */
  StoreGoodsForStore = 'storeGoodsForStore',
  /** 导购员业绩汇总 */
  GuiderReport = 'guiderReport',
  /** 导购员业绩汇总 */
  GuiderReportHq = 'guiderReportHq',
  /** 导购员业绩汇总 */
  GuiderReportDetail = 'guiderReportDetail',
  /** 导购员业绩汇总 */
  GuiderReportDetailHq = 'guiderReportDetailHq',
  /** 营业款对账（按门店） */
  BusinessFundsStore = 'businessFundsStore',
  /** 门店-会员储值交易流水 */
  StoreMemberStoredTrans = 'storeMemberStoredTrans',
  /** 总部-会员储值交易流水 */
  HeadMemberStoredTrans = 'headMemberStoredTrans',
  /** 门店-门店储值汇总 */
  StoreMemberStoredSummary = 'storeMemberStoredSummary',
  /** 总部-门店储值汇总 */
  HeadMemberStoredSummary = 'headMemberStoredSummary',
  /** 储值汇总 */
  MemberStoredSummary = 'memberStoredSummary',
  /** 门店-会员消费汇总 */
  StoreMemberConsumeSummary = 'storeMemberConsumeSummary',
  /** 总部-会员消费汇总 */
  HeadMemberConsumeSummary = 'headMemberConsumeSummary',
  /** 门店-会员热销品 */
  StoreMemberConsumeGoods = 'storeMemberConsumeGoods',
  /** 总部-会员热销品 */
  HeadMemberConsumeGoods = 'headMemberConsumeGoods',
  /** 门店-会员实体卡绑定记录 */
  StoreMemberCardBind = 'storeMemberCardBind',
  /** 总部-会员实体卡绑定记录 */
  HeadMemberCardBind = 'headMemberCardBind',
  /** 门店-会员注册汇总 */
  StoreMemberRegister = 'storeMemberRegister',
  /** 总部-会员注册汇总 */
  HeadMemberRegister = 'headMemberRegister',
  /** 收银缴款 */
  BusinessFundsCashier = 'businessFundsCashier',
  /** 门店-营业款对账-收银缴款 */
  StoreBusinessFundsCashier = 'storeBusinessFundsCashier',
  /** 总部-门店配货查询 */
  StoreDistDetail = 'storeDistDetail',
  /** 门店-门店配货查询 */
  StoreDistDetailNew = 'storeDistDetailNew',
  /** 门店配货查询 */
  StoreAllocOut = 'storeAllocOut',
  /** 门店退货查询 */
  StoreAllocBack = 'storeAllocBack',
  /** 门店进销存日报（按门店） */
  DrptJxcReport = 'drptJxcReport',
  /** 门店进销存日报（按门店） */
  DrptJxcReportStore = 'drptJxcReportStore',
  /** 门店进销存日报(按大类) */
  DrptJxcReportGeneral = 'drptJxcReportGeneral',
  /** 门店进销存日报(按中类) */
  DrptJxcReportMedium = 'drptJxcReportMedium',
  /** 门店进销存日报(按小类) */
  DrptJxcReportSubsort = 'drptJxcReportSubsort',
  /** 门店进销存日报(按商品) */
  DrptJxcReportGoods = 'drptJxcReportGoods',
  /** 门店进销存月报(按门店) */
  MrptJxcReportStore = 'mrptJxcReportStore',
  /** 门店进销存月报(按大类) */
  MrptJxcReportGeneral = 'mrptJxcReportGeneral',
  /** 门店进销存月报(按种类) */
  MrptJxcReportMedium = 'mrptJxcReportMedium',
  /** 门店进销存月报(按小类) */
  MrptJxcReportSubsort = 'mrptJxcReportSubsort',
  /** 门店进销存月报(按商品) */
  MrptJxcReportGoods = 'mrptJxcReportGoods',
  /** 门店进销存汇总(按门店) */
  SummaryReportStore = 'summaryReportStore',
  /** 门店进销存汇总(按大类) */
  SummaryReportGeneral = 'summaryReportGeneral',
  /** 门店进销存汇总(按中类) */
  SummaryReportMedium = 'summaryReportMedium',
  /** 门店进销存汇总(按小类) */
  SummaryReportSubsort = 'summaryReportSubsort',
  /** 门店进销存汇总(按商品) */
  SummaryReportGoods = 'summaryReportGoods',
  /** 总部进销存日报（按门店） */
  DrptJxcReportStoreForWrh = 'drptJxcReportStoreForWrh',
  /** 总部门店进销存日报(按大类) */
  DrptJxcReportGeneralForWrh = 'drptJxcReportGeneralForWrh',
  /** 总部门店进销存日报(按中类) */
  DrptJxcReportMediumForWrh = 'drptJxcReportMediumForWrh',
  /** 总部门店进销存日报(按小类) */
  DrptJxcReportSubsortForWrh = 'drptJxcReportSubsortForWrh',
  /** 总部门店进销存日报(按商品) */
  DrptJxcReportGoodsForWrh = 'drptJxcReportGoodsForWrh',
  /** 总部门店进销存月报(按门店) */
  MrptJxcReportStoreForWrh = 'mrptJxcReportStoreForWrh',
  /** 总部门店进销存月报(按大类) */
  MrptJxcReportGeneralForWrh = 'mrptJxcReportGeneralForWrh',
  /** 总部门店进销存月报(按种类) */
  MrptJxcReportMediumForWrh = 'mrptJxcReportMediumForWrh',
  /** 总部门店进销存月报(按小类) */
  MrptJxcReportSubsortForWrh = 'mrptJxcReportSubsortForWrh',
  /** 总部门店进销存月报(按商品) */
  MrptJxcReportGoodsForWrh = 'mrptJxcReportGoodsForWrh',
  /** 总部门店进销存汇总(按商品) */
  SummaryReportStoreForWrh = 'summaryReportStoreForWrh',
  /** 总部门店进销存汇总(按大类) */
  SummaryReportGeneralForWrh = 'summaryReportGeneralForWrh',
  /** 总部门店进销存汇总(按中类) */
  SummaryReportMediumForWrh = 'summaryReportMediumForWrh',
  /** 总部门店进销存汇总(按小类) */
  SummaryReportSubsortForWrh = 'summaryReportSubsortForWrh',
  /** 总部门店进销存汇总(按商品) */
  SummaryReportGoodsForWrh = 'summaryReportGoodsForWrh',
  /** 采购订单监控-到货率 */
  PurchaseOrderMonitorReceiveRate = 'purchaseOrderMonitorReceiveRate',
  /** 采购订单监控-过期订单 */
  PurchaseOrderMonitorOverDue = 'purchaseOrderMonitorOverDue',
  /** 采购订单监控-无效订单 */
  PurchaseOrderMonitorInvalid = 'purchaseOrderMonitorInvalid',
  /** 采购订单监控-在途订单 */
  PurchaseOrderMonitorRouting = 'purchaseOrderMonitorRouting',
  /** 采购订单-订货参考 */
  PurchaseOrderReference = 'purchaseOrderReference',
  /** 总部-商品基本信息 */
  GoodsInfoHeadBasic = 'goodsInfoHeadBasic',
  /** 总部-商品供应商信息 */
  GoodsInfoHeadSupplier = 'goodsInfoHeadSupplier',
  /** 总部-商品条码信息 */
  GoodsInfoHeadBarcode = 'goodsInfoHeadBarcode',
  /** 总部-商品包装信息 */
  GoodsInfoHeadQpcStr = 'goodsInfoHeadQpcStr',
  /** 总部-商品状态变更信息 */
  GoodsInfoHeadStateChange = 'goodsInfoHeadStateChange',
  /** 总部-换货商品信息 */
  GoodsInfoHeadExchange = 'goodsInfoHeadExchange',
  /** 总部-商品-异常商品-负库存 */
  GoodsInfoHeadNegativeInv = 'goodsInfoHeadNegativeInv',
  /** 总部-商品-异常商品-负毛利 */
  GoodsInfoHeadNegativeProfit = 'goodsInfoHeadNegativeProfit',
  /** 总部-商品-异常商品-高库龄 */
  GoodsInfoHeadOlderInv = 'goodsInfoHeadOlderInv',
  /** 总部-商品-异常商品-异常锁定 */
  GoodsInfoLockInv = 'goodsInfoLockInv',
  /** 门店-商品基本信息 */
  GoodsInfoStoreBasic = 'goodsInfoStoreBasic',
  /** 门店-商品条码信息 */
  GoodsInfoStoreBarcode = 'goodsInfoStoreBarcode',
  /** 门店-组合商品信息 */
  GoodsInfoStoreCombine = 'goodsInfoStoreCombine',
  /** 门店-淘汰商品信息 */
  GoodsInfoStoreOutState = 'goodsInfoStoreOutState',
  /** 门店-商品新品信息 */
  GoodsInfoStoreNewState = 'goodsInfoStoreNewState',
  /** 门店-电子秤商品信息 */
  GoodsInfoStorePlu = 'goodsInfoStorePlu',
  /** 门店-商品包装信息 */
  GoodsInfoStoreQpcStr = 'goodsInfoStoreQpcStr',
  /** 门店-换货商品信息 */
  GoodsInfoStoreExchange = 'goodsInfoStoreExchange',
  /** 门店-低库存-商品信息 */
  GoodsInfoStoreLessInv = 'goodsInfoStoreLessInv',
  /** 门店-负库存-商品信息 */
  GoodsInfoStoreNegativeInv = 'goodsInfoStoreNegativeInv',
  /** 门店-负毛利-商品信息 */
  GoodsInfoStoreNegativeProfit = 'goodsInfoStoreNegativeProfit',
  /** 门店-滞销-商品信息 */
  GoodsInfoStoreUnsold = 'goodsInfoStoreUnsold',
  /** 门店-异常锁定-商品信息 */
  GoodsInfoStoreLockInv = 'goodsInfoStoreLockInv',
  /** 总部-兑换商品汇总 */
  HeadPointGoodsConvertSummery = 'headPointGoodsConvertSummery',
  /** 门店-兑换商品汇总 */
  StorePointGoodsConvertSummery = 'storePointGoodsConvertSummery',
  /** 会员积分兑换流水 */
  PointConvertSummery = 'pointConvertSummery',
  /** 商品调价报表 */
  PriceAdjustLaunchGoods = 'priceAdjustLaunchGoods',
  /** 门店补货方案商品参数导出 */
  ReplenishSchemaGoods = 'replenishSchemaGoods',
  /** 补货商品导出 */
  ReplenishGoods = 'replenishGoods',
  /** 门店日结查看当日的营业收入汇总数据 */
  BusinessSaleCurrencyDay = 'businessSaleCurrencyDay',
  /** 营业对账查看当日的营业收入汇总数据 */
  NormalBusinessSaleCurrencyDay = 'normalBusinessSaleCurrencyDay',
  /** 查询当日门店商品销售情况，按大类统计 */
  BusinessSaleSortDay = 'businessSaleSortDay',
  /** 查询当日门店商品销售情况，按中类统计 */
  BusinessSaleMediumSortDay = 'businessSaleMediumSortDay',
  /** 查询当日门店商品销售情况，按小类统计 */
  BusinessSaleSmallSortDay = 'businessSaleSmallSortDay',
  /** 直配收货单 */
  AllocDirectOut = 'allocDirectOut',
  /** 门店收货 */
  DirectAllocOut = 'directAllocOut',
  /** 门店退货 */
  DirectAllocBack = 'directAllocBack',
  /** 员工资料 */
  User = 'user',
  /** 营业款对账查询(按充值流水) */
  RechargeFlow = 'rechargeFlow',
  /** 门店-营业款对账查询(按充值流水) */
  StoreRechargeFlow = 'storeRechargeFlow',
  /** 营业款对账查询(按售卡流水) */
  SaleCardFlow = 'saleCardFlow',
  /** 门店端-营业款对账查询(按售卡流水) */
  StoreSaleCardFlow = 'storeSaleCardFlow',
  /** 营业款对账查询(按销售流水) */
  BusinessFundsFlow = 'businessFundsFlow',
  /** 门店端-营业款对账查询(按销售流水) */
  StoreBusinessFundsFlow = 'storeBusinessFundsFlow',
  /** 预付款订货汇总报表 */
  PrepayReport = 'prepayReport',
  /** 预付款订货明细报表 */
  PrepayDetailReport = 'prepayDetailReport',
  /** 进货报表-按门店 */
  PurchaseReportByStore = 'purchaseReportByStore',
  /** 进货报表-按供应商 */
  PurchaseReportBySupplier = 'purchaseReportBySupplier',
  /** 门店销售汇总报表 */
  SaleAccount = 'saleAccount',
  /** 损耗报表 */
  LossAccount = 'lossAccount',
  /** 盘点盈亏报表-按门店 */
  CheckInReportByStore = 'checkInReportByStore',
  /** 盘点盈亏报表-按供应商 */
  CheckInReportBySupplier = 'checkInReportBySupplier',
  /** 采购订单按订单导出 */
  PurchaseOrderForOrder = 'purchaseOrderForOrder',
  /** 采购订单按供应商导出 */
  PurchaseOrderForSupplier = 'purchaseOrderForSupplier',
  /** 商品分类 */
  Sort = 'sort',
  /** 采购进货查询-按商品汇总 */
  PurchaseGoodsByGoods = 'purchaseGoodsByGoods',
  /** 采购进货查询-按供应商汇总 */
  PurchaseGoodsBySupplier = 'purchaseGoodsBySupplier',
  /** 采购进货查询-按分类汇总 */
  PurchaseGoodsBySort = 'purchaseGoodsBySort',
  /** 采购进货查询-进货明细 */
  PurchaseGoodsInDetail = 'purchaseGoodsInDetail',
  /** 采购进货查询-退货明细 */
  PurchaseGoodsBackDetail = 'purchaseGoodsBackDetail',
  /** 仓库报损报表-按仓库汇总 */
  WrhLossReportByWrh = 'wrhLossReportByWrh',
  /** 仓库报损报表-按分类汇总 */
  WrhLossReportBySort = 'wrhLossReportBySort',
  /** 仓库报损报表-按供应商汇总 */
  WrhLossReportBySupplier = 'wrhLossReportBySupplier',
  /** 仓库报损报表-按商品汇总 */
  WrhLossReportByGoods = 'wrhLossReportByGoods',
  /** 仓库报损报表-明细查询 */
  WrhLossReportDetail = 'wrhLossReportDetail',
  /** 门店报损报表-按门店汇总 */
  StoreLossReportByStore = 'storeLossReportByStore',
  /** 门店报损报表-按分类汇总 */
  StoreLossReportBySort = 'storeLossReportBySort',
  /** 门店报损报表-按供应商汇总 */
  StoreLossReportBySupplier = 'storeLossReportBySupplier',
  /** 门店报损报表-按商品汇总 */
  StoreLossReportByGoods = 'storeLossReportByGoods',
  /** 门店报损报表-明细查询 */
  StoreLossReportDetail = 'storeLossReportDetail',
  /** 货架位商品导出 */
  StoreShelves = 'storeShelves',
  /** 到期催销商品查询 */
  StoreUrgeSale = 'storeUrgeSale',
  /** 过期商品查询 */
  StoreOutTimeInv = 'storeOutTimeInv',
  /** 导出查询结果 */
  PurchaseLadingGoods = 'purchaseLadingGoods',
  /** 财务经销结算导出 */
  SupplierSellbuySettle = 'supplierSellbuySettle',
  /** 财务联营结算导出 */
  SupplierSellUnionSettle = 'supplierSellUnionSettle',
  /** 财务批发结算导出 */
  WholesaleSettle = 'wholesaleSettle',
  /** 财务预付款导出 */
  SupplierPrepay = 'supplierPrepay',
  /** 财务付款单导出 */
  Pay = 'pay',
  /** 财务收款单导出 */
  Receipt = 'receipt',
  /** 拣货异常商品 */
  GoodsOnlineTaskAbnormal = 'goodsOnlineTaskAbnormal',
  /** 门店订货查询---按门店 */
  RequireOrderQueryByStore = 'requireOrderQueryByStore',
  /** 门店订货查询---按大类 */
  RequireOrderQueryBySort = 'requireOrderQueryBySort',
  /** 门店订货查询---按商品 */
  RequireOrderQueryByGoods = 'requireOrderQueryByGoods',
  /** 门店订货查询---订货明细 */
  RequireOrderQueryDetail = 'requireOrderQueryDetail',
  /** 批发销售报表---按商品 */
  WholeSaleReportGoods = 'wholeSaleReportGoods',
  /** 批发销售报表---按客户 */
  WholeSaleReportCustomer = 'wholeSaleReportCustomer',
  /** 批发销售报表---按分类 */
  WholeSaleReportSort = 'wholeSaleReportSort',
  /** 批发销售报表---按门店 */
  WholeSaleReportStore = 'wholeSaleReportStore',
  /** 批发销售报表---按仓库 */
  WholeSaleReportWrh = 'wholeSaleReportWrh',
  /** 批发销售报表---明细 */
  WholeSaleReportDetail = 'wholeSaleReportDetail',
  /** POS操作日志 */
  PosLogQuery = 'posLogQuery',
  /** 总部-收银异常监控[门店] */
  PosLogStoreQuery = 'posLogStoreQuery',
  /** 总部-收银异常监控[员工] */
  PosLogCashierQuery = 'posLogCashierQuery',
  /** 导出仓库批发单 */
  WrhWholeSaleOut = 'wrhWholeSaleOut',
  /** 导出仓库批发退货单 */
  WrhWholeSaleBack = 'wrhWholeSaleBack',
  /** 会员付费开卡流水 */
  MemberCardFlow = 'memberCardFlow',
  /** 门店端会员付费开卡流水 */
  StoreMemberCardFlow = 'storeMemberCardFlow',
  /** 导出供应商结款汇总 */
  StandingBookCashier = 'standingBookCashier',
  /** 导出供应商往来明细 */
  StandingBookDetail = 'standingBookDetail',
  /** 导出后台操作日志 */
  BackstageLog = 'backstageLog',
  /** 采购计划单明细导出 */
  PurchasePlan = 'purchasePlan',
  /** 越库作业单明细导出 */
  CrossDocking = 'crossDocking',
  /** 门店储值流向导出 */
  StoredFlowDirection = 'storedFlowDirection',
  /** 总部储值变动导出 */
  DepositChange = 'depositChange',
  /** 仓库配货订单导出 */
  WrhAllocOrder = 'wrhAllocOrder',
  /** 仓库配货订单明细导出 */
  WrhAllocOrderDetail = 'wrhAllocOrderDetail',
  /** 配货通知单汇总导出 */
  WrhAllocOrderSummary = 'wrhAllocOrderSummary',
  /** 收款流水导出 */
  StoreRevenueFlow = 'storeRevenueFlow',
  /** 收款流水导出 */
  RevenueFlow = 'revenueFlow',
  /** 价格策略报表 */
  PriceCalcPlanForms = 'priceCalcPlanForms',
  /** 员工拣货统计查询报表 */
  PickingEmployeeStat = 'pickingEmployeeStat',
  /** 门店-员工拣货统计查询报表 */
  PickingEmployeeStatStore = 'pickingEmployeeStatStore',
  /** 配货毛利查询-按统计 */
  DistributionMarginSummary = 'distributionMarginSummary',
  /** 配货毛利查询-按仓库 */
  DistributionMarginWrh = 'distributionMarginWrh',
  /** 配货毛利查询-按门店 */
  DistributionMarginStore = 'distributionMarginStore',
  /** 配货毛利查询-按明细 */
  DistributionMarginDetail = 'distributionMarginDetail',
  /** 门店调拨查询-按单据 */
  StoreTransferReportBill = 'storeTransferReportBill',
  /** 门店调拨查询-按明细 */
  StoreTransferReportDetail = 'storeTransferReportDetail',
  /** 门店调拨明细 */
  StoreTransferDetail = 'storeTransferDetail',
  /** 门店调拨明细 */
  StoreTransferInDetail = 'storeTransferInDetail',
  /** 门店调拨明细 */
  StoreTransferOutDetail = 'storeTransferOutDetail',
  /** 批量导出配货单 */
  WrhAllocOutBatch = 'wrhAllocOutBatch',
  /** 价格变动记录 */
  AdjustPriceGoodsRecord = 'adjustPriceGoodsRecord',
  /** 价格变动记录 */
  StoreAdjustPriceGoodsRecord = 'storeAdjustPriceGoodsRecord',
  /** POS订单 */
  PosOrder = 'posOrder',
  /** 进销毛利报表 */
  JxMargin = 'jxMargin',
  /** 进销毛利报表 */
  StoreJxMargin = 'storeJxMargin',
  /** 商品销售报表（按商品） */
  DrptSale = 'drptSale',
  /** 门店商品销售报表（按商品） */
  StoreDrptSale = 'storeDrptSale',
  /** 商品销售报表（按类别） */
  DrptSaleSort = 'drptSaleSort',
  /** 门店商品销售报表（按类别） */
  StoreDrptSaleSort = 'storeDrptSaleSort',
  /** 门店销售统计-按门店 */
  DrptSaleStore = 'drptSaleStore',
  /** 销售日报报表（按门店） */
  DrptStore = 'drptStore',
  /** 门店销售日报报表（按门店） */
  StoreDrptStore = 'storeDrptStore',
  /** 销售日报报表（按类别） */
  DrptSort = 'drptSort',
  /** 销售日报报表（按类别） */
  StoreDrptSort = 'storeDrptSort',
  /** 销售日报报表（按商品） */
  DrptSku = 'drptSku',
  /** 销售日报报表（按商品） */
  StoreDrptSku = 'storeDrptSku',
  /** 补差协议 */
  SupplierSubdiffAgreement = 'supplierSubdiffAgreement',
  /** 补差单 */
  SupplierSubdiff = 'supplierSubdiff',
  /** 门店进销存日报（按门店） */
  JxcDailyStore = 'jxcDailyStore',
  /** 门店进销存日报（按类别） */
  JxcDailyStoreSort = 'jxcDailyStoreSort',
  /** 门店进销存日报（按单品） */
  JxcDailyStoreGoods = 'jxcDailyStoreGoods',
  /** 门店进销存月报（按门店） */
  JxcMonthStore = 'jxcMonthStore',
  /** 门店进销存月报（按类别） */
  JxcMonthStoreSort = 'jxcMonthStoreSort',
  /** 门店进销存月报（按单品） */
  JxcMonthStoreGoods = 'jxcMonthStoreGoods',
  /** 门店进销存汇总（按门店） */
  JxcSumStore = 'jxcSumStore',
  /** 门店进销存汇总（按类别） */
  JxcSumStoreSort = 'jxcSumStoreSort',
  /** 门店进销存汇总（按单品） */
  JxcSumStoreGoods = 'jxcSumStoreGoods',
  /** 总部进销存日报（按仓库） */
  JxcDailyWrh = 'jxcDailyWrh',
  /** 总部进销存日报（按类别） */
  JxcDailyWrhSort = 'jxcDailyWrhSort',
  /** 总部进销存日报（按单品） */
  JxcDailyWrhGoods = 'jxcDailyWrhGoods',
  /** 总部进销存月报（按仓库） */
  JxcMonthWrh = 'jxcMonthWrh',
  /** 总部进销存月报（按类别） */
  JxcMonthWrhSort = 'jxcMonthWrhSort',
  /** 总部进销存月报（按单品） */
  JxcMonthWrhGoods = 'jxcMonthWrhGoods',
  /** 总部进销存汇总（按仓库） */
  JxcSumWrh = 'jxcSumWrh',
  /** 总部进销存汇总（按类别） */
  JxcSumWrhSort = 'jxcSumWrhSort',
  /** 总部进销存汇总（按单品） */
  JxcSumWrhGoods = 'jxcSumWrhGoods',
  /** 分货池导出 */
  WrhAllotPool = 'wrhAllotPool',
  /** 商品销售财务报表 */
  DrptFinanceSort = 'drptFinanceSort',
  /** 商品销售财务报表 */
  DrptFinanceSku = 'drptFinanceSku',
  /** 商品销售财务报表 */
  DrptFinanceStore = 'drptFinanceStore',
  /** 礼品卡管理 */
  PrePayCard = 'prePayCard',
  /** 礼品卡消费 */
  PrePayCardFlow = 'prePayCardFlow',
  /** 礼品卡发售 */
  PrePayCardSale = 'prePayCardSale',
  /** 批发订单 */
  WholeSaleOut = 'wholeSaleOut',
  /** 缺货商品导出 */
  DistLackGoods = 'distLackGoods',
  /** 提单异常商品导出 */
  DistLackFailGoods = 'distLackFailGoods',
  /** 订货明细导出 */
  DistPoolGoods = 'distPoolGoods',
  /** 订货汇总导出 */
  DistPoolSum = 'distPoolSum',
  /** 门店订货导出 */
  RequireOrder = 'requireOrder',
  /** 门店订货V2导出 */
  RequireOrderV2 = 'requireOrderV2',
  /** 门店订货已订V2导出 */
  StoreDistPool = 'storeDistPool',
  /** 不动销商品 */
  AbnormalNoSaleGoods = 'abnormalNoSaleGoods',
  /** 门店不动销商品 */
  StoreAbnormalNoSaleGoods = 'storeAbnormalNoSaleGoods',
  /** 促销明细 */
  PromtionSku = 'promtionSku',
  /** 促销明细（门店） */
  StorePromtionSku = 'storePromtionSku',
  /** 促销商品 */
  DrptPromtionSku = 'drptPromtionSku',
  /** 促销商品（门店） */
  StoreDrptPromtionSku = 'storeDrptPromtionSku',
  /** 采购到货率-按供应商导出 */
  PurchaseArrivalSupplier = 'purchaseArrivalSupplier',
  /** 采购到货率-按单品导出 */
  PurchaseArrivalGoods = 'purchaseArrivalGoods',
  /** 客户台账导出 */
  CustomerStandingBook = 'customerStandingBook',
  /** 客户台账明细导出 */
  CustomerStandingBookDetail = 'customerStandingBookDetail',
  /** 礼品卡转储流水导出 */
  CardDumpFlow = 'cardDumpFlow',
  /** 礼品卡汇总导出 */
  CardDrpt = 'cardDrpt',
  /** 礼品卡流水汇总导出 */
  CardDrptFlow = 'cardDrptFlow',
  /** 门店礼品卡流水汇总导出 */
  StoreCardDrptFlow = 'storeCardDrptFlow',
  /** 按售卡业务员导出 */
  CardBySaler = 'cardBySaler',
  /** 按售卡门店导出 */
  CardByStore = 'cardByStore',
  /** 客户购卡按交易导出 */
  CardTradeByBuyer = 'cardTradeByBuyer',
  /** 客户购卡按明细导出 */
  CardDetailByBuyer = 'cardDetailByBuyer',
  /** 卡余额变动导出 */
  CardBalance = 'cardBalance',
  /** 盘点盈亏报表-汇总 */
  CheckInReportByWrh = 'checkInReportByWrh',
  /** 盘点盈亏报表-明细 */
  CheckInReportByDetail = 'checkInReportByDetail',
  /** 调拨报表-汇总 */
  TransferReportByWrh = 'transferReportByWrh',
  /** 调拨报表-明细 */
  TransferReportByDetail = 'transferReportByDetail',
  /** 供应商补差明细 */
  SupplierSubdiffReportDetail = 'supplierSubdiffReportDetail',
  /** 供应商补差汇总（按供应商） */
  SupplierSubdiffReportBySupplier = 'supplierSubdiffReportBySupplier',
  /** 供应商补差汇总（按门店） */
  SupplierSubdiffReportByStore = 'supplierSubdiffReportByStore',
  /** 损溢汇总 */
  LossGainReport = 'lossGainReport',
  /** 损溢明细 */
  LossGainReportDetail = 'lossGainReportDetail',
  /** 供应商台账汇总 */
  SupplierSbSum = 'supplierSbSum',
  /** 供应商台账应付明细 */
  SupplierSbShouldPayDetail = 'supplierSbShouldPayDetail',
  /** 供应商台账预付明细 */
  SupplierSbPrepayDetail = 'supplierSbPrepayDetail',
  /** 供应商台账费用明细 */
  SupplierSbFeeDetail = 'supplierSbFeeDetail',
  /** 分拣商品库 */
  PickingGoods = 'pickingGoods',
  /** 拣货查询 */
  PickingReport = 'pickingReport',
  /** 分拣管理 */
  PickingOutboundLimitError = 'pickingOutboundLimitError',
  /** 分拣管理 */
  PickingOutboundOutStock = 'pickingOutboundOutStock',
  /** 查询报表 */
  PromoteSkuReport = 'promoteSkuReport',
  /** 查询报表 */
  PromoteStoreReport = 'promoteStoreReport',
  /** 提货卡导出 */
  PickupCard = 'pickupCard',
  /** 进货报表-按供应商 */
  PurchaseSupplier = 'purchaseSupplier',
  /** 进货报表-按收货组织 */
  PurchaseReceiver = 'purchaseReceiver',
  /** 进货报表-按门店 */
  PurchaseStore = 'purchaseStore',
  /** 进货报表-按商品 */
  PurchaseSku = 'purchaseSku',
  /** 进货报表-进货明细 */
  PurchaseDetail = 'purchaseDetail',
  /** 提货卡用卡商品明细导出 */
  PickupFlowGoods = 'pickupFlowGoods',
  /** 提货卡发售流水导出 */
  PickupSaleFlow = 'pickupSaleFlow',
  /** 提货卡消费流水导出 */
  PickupVerificationFlow = 'pickupVerificationFlow',
  /** 整单折扣流水导出 */
  DiscountFlow = 'discountFlow',
  /** WMS库存差异报表导出 */
  WmsInvCheck = 'wmsInvCheck',
  /** 领用报表-领用汇总 */
  UseReportSum = 'useReportSum',
  /** 领用报表-领用明细 */
  UseReportDetail = 'useReportDetail',
  /** 退仓通知单明细导出 */
  WrhAllocNoticeBackDetail = 'wrhAllocNoticeBackDetail',
  /** 财务配货报表-按门店汇总 */
  DistInoutFlowStore = 'distInoutFlowStore',
  /** 财务配货报表-按商品汇总 */
  DistInoutFlowGoods = 'distInoutFlowGoods',
  /** 财务配货报表-配货明细 */
  DistInoutFlowAlloc = 'distInoutFlowAlloc',
  /** 财务配货报表-退货明细 */
  DistInoutFlowBack = 'distInoutFlowBack',
  /** 礼品卡售卡交易导出 */
  CardServiceOrder = 'cardServiceOrder',
  /** 礼品卡售卡交易-卡明细导出 */
  CardServiceOrderDetail = 'cardServiceOrderDetail',
  /** 提货卡售卡交易导出 */
  CardServiceOrder2 = 'cardServiceOrder2',
  /** 提货卡售卡交易-卡明细导出 */
  CardServiceOrderDetail2 = 'cardServiceOrderDetail2',
  /** 采购进货单导出 */
  PurchaseInOrder = 'purchaseInOrder',
  /** 采购退货单导出 */
  PurchaseBackOrder = 'purchaseBackOrder',
  /** 仓库分货记录导出 */
  AllotGenAllocGoods = 'allotGenAllocGoods',
  /** 门店商品库销售商品导出 */
  StoreGoodsSaleInfo = 'storeGoodsSaleInfo',
  /** 门店商品库库存商品导出 */
  StoreGoodsInvInfo = 'storeGoodsInvInfo',
  /** 门店商品库销售商品导出 */
  StoreGoodsSaleOutInfo = 'storeGoodsSaleOutInfo',
  /** 门店商品库库存商品导出 */
  StoreGoodsInvOutInfo = 'storeGoodsInvOutInfo',
  /** 分账单导出 */
  DivisionBill = 'divisionBill',
  /** 门店结算协议导出 */
  StoreSettleAgreement = 'storeSettleAgreement',
  /** 门店储值日报 */
  MemberBalanceStoreDaySummary = 'memberBalanceStoreDaySummary',
  /** 储值业务汇总 */
  MemberBalanceStoreSummary = 'memberBalanceStoreSummary',
  /** 储值流水 */
  MemberBalanceFlowWork = 'memberBalanceFlowWork'
}

export enum IoOutPlateEnum {
  /** 商品-商品档案 */
  Goods = 'goods',
  /** 仓库-盘点任务 */
  CheckGoodsDetail = 'checkGoodsDetail',
  /** 商品-门店商品库 */
  StoreGoods = 'storeGoods',
  /** 采购-供应商档案 */
  Supplier = 'supplier',
  /** 采购-采购协议 */
  SupplierAgreement = 'supplierAgreement',
  /** 采购-联营协议 */
  SupplierSellunionAgreement = 'supplierSellunionAgreement',
  /** 商品-配送方案 */
  DistSchema = 'distSchema',
  /** 供应链-总部仓库库存查询 */
  InvQueryWrh = 'invQueryWrh',
  /** 供应链-总部门店库存查询 */
  InvQueryStore = 'invQueryStore',
  /** 供应链-总部批次库存查询 */
  InvQueryBatch = 'invQueryBatch',
  /** 供应链-总部效期库存查询 */
  InvQueryValidDate = 'invQueryValidDate',
  /** 店务-效期库存查询 */
  StoreInvQueryValidDate = 'storeInvQueryValidDate',
  /** 商品-总部库存查询-业务库存 */
  BusinessWrhInv = 'businessWrhInv',
  /** 总部-商品-库存查询 */
  BusinessStoreInv = 'businessStoreInv',
  /** 门店-商品-库存查询 */
  StoreBusinessStoreInv = 'storeBusinessStoreInv',
  /** 商品-总部库存查询-财务库存 */
  FinanceWrhInv = 'financeWrhInv',
  /** 商品-库存查询 */
  FinanceStoreInv = 'financeStoreInv',
  /** 商品-库存流水 */
  HqInvFlow = 'hqInvFlow',
  /** 商品-库存流水查询 */
  StoreInvFlow = 'storeInvFlow',
  /** 商品-品牌管理 */
  Brand = 'brand',
  /** 零售-交易流水查询-按流水查询 */
  BuyRecord = 'buyRecord',
  /** 零售-交易流水查询-按支付方式查询 */
  BuyPayType = 'buyPayType',
  /** 零售-交易流水查询-按商品查询 */
  BuyRecordGoods = 'buyRecordGoods',
  /** 门店-手持设备 */
  HolderAuthCode = 'holderAuthCode',
  /** 财务-联营对账查询-按商品 */
  SellUnionAccountGoods = 'sellUnionAccountGoods',
  /** 财务-联营对账查询-按明细 */
  SellUnionAccountDetail = 'sellUnionAccountDetail',
  /** 采购-门店订货参考 */
  StoreOrderReference = 'storeOrderReference',
  /** 财务-经销对账查询 */
  SellBuyAccountBill = 'sellBuyAccountBill',
  /** 财务-客户对账查询 */
  CustomerAccountBill = 'customerAccountBill',
  /** - */
  SupplierOrder = 'supplierOrder',
  /** 线上订单-线上订单查询 */
  OnlineOrder = 'onlineOrder',
  /** 退款管理-线上订单退款单查询 */
  OnlineOrderRefund = 'onlineOrderRefund',
  /** 线上商品-线上商品查询 */
  OnlineGoods = 'onlineGoods',
  /** 线上分组-线上分组查询 */
  OnlineGroup = 'onlineGroup',
  /** 线上订单-线上订单评价 */
  OnlineOrderComment = 'onlineOrderComment',
  /** 线上商品迁移-线上商品信息拉取 */
  OnlineDataPull = 'onlineDataPull',
  /** 线上商品迁移-线上商品信息整合 */
  OnlineGoodsOutModel = 'onlineGoodsOutModel',
  /** 采购-采购订单 */
  PurchaseOrderDetail = 'purchaseOrderDetail',
  /** 批发-批发订单 */
  WholesaleOrder = 'wholesaleOrder',
  /** 供应链-业务查询-门店配货查询 */
  StoreAllocOut = 'storeAllocOut',
  /** 供应链-业务查询-门店退货查询 */
  StoreAllocBack = 'storeAllocBack',
  /** 财务-门店进销存日报 */
  DrptJxcReportStore = 'drptJxcReportStore',
  /** 财务-门店进销存月报 */
  MrptJxcReportStore = 'mrptJxcReportStore',
  /** 财务-门店进销存汇总 */
  SummaryReportStore = 'summaryReportStore',
  /** 财务-总部进销存日报 */
  DrptJxcReportStoreForWrh = 'drptJxcReportStoreForWrh',
  /** 财务-总部门店进销存月报 */
  MrptJxcReportStoreForWrh = 'mrptJxcReportStoreForWrh',
  /** 财务-总部门店进销存汇总 */
  SummaryReportStoreForWrh = 'summaryReportStoreForWrh',
  /** 采购-采购订单监控 */
  PurchaseOrderMonitorReceiveRate = 'purchaseOrderMonitorReceiveRate',
  /** 采购-采购订货参考 */
  PurchaseOrderReference = 'purchaseOrderReference',
  /** 商品-商品信息查询 */
  GoodsInfoHeadBasic = 'goodsInfoHeadBasic',
  /** 商品-异常商品查询 */
  GoodsInfoHeadNegativeInv = 'goodsInfoHeadNegativeInv',
  /** 会员-会员兑换查询 */
  HeadPointGoodsConvertSummery = 'headPointGoodsConvertSummery',
  /** 会员-会员积分兑换查询 */
  PointConvertSummery = 'pointConvertSummery',
  /** 店务管理-门店补货管理 */
  ReplenishGoods = 'replenishGoods',
  /** 采购管理-门店进货 */
  DirectAllocOut = 'directAllocOut',
  /** 采购管理-门店退货 */
  DirectAllocBack = 'directAllocBack',
  /** 设置-员工 */
  User = 'user',
  /** 财务-进货报表 */
  PurchaseReportByStore = 'purchaseReportByStore',
  /** 财务-损耗报表 */
  LossAccount = 'lossAccount',
  /** 财务-盘点盈亏报表 */
  CheckInReportByStore = 'checkInReportByStore',
  /** 商品-商品分类 */
  Sort = 'sort',
  /** 供应链-业务查询 */
  PurchaseGoodsByGoods = 'purchaseGoodsByGoods',
  /** 仓库-仓库查询 */
  WrhLossReportByWrh = 'wrhLossReportByWrh',
  /** 商品-商品查询 */
  StoreLossReportByStore = 'storeLossReportByStore',
  /** 商品-货架位管理 */
  StoreShelves = 'storeShelves',
  /** 采购-采购提单 */
  PurchaseLadingGoods = 'purchaseLadingGoods',
  /** 财务-经销结算 */
  SupplierSellbuySettle = 'supplierSellbuySettle',
  /** 财务-联营结算 */
  SupplierSellUnionSettle = 'supplierSellUnionSettle',
  /** 财务-批发结算 */
  WholesaleSettle = 'wholesaleSettle',
  /** 财务-预付款 */
  SupplierPrepay = 'supplierPrepay',
  /** 财务-付款单 */
  Pay = 'pay',
  /** 财务-收款单 */
  Receipt = 'receipt',
  /** 批发-仓库批发单 */
  WrhWholeSaleOut = 'wrhWholeSaleOut',
  /** 批发-仓库批发退货单 */
  WrhWholeSaleBack = 'wrhWholeSaleBack',
  /** 财务-门店日结 */
  MemberCardFlow = 'memberCardFlow',
  /** 财务-供应商结款汇总 */
  StandingBookCashier = 'standingBookCashier',
  /** 财务-供应商往来明细 */
  StandingBookDetail = 'standingBookDetail',
  /** 采购计划-明细导出 */
  PurchasePlan = 'purchasePlan',
  /** 越库作业-明细导出 */
  CrossDocking = 'crossDocking',
  /** 会员查询- */
  StoredFlowDirection = 'storedFlowDirection',
  /** 会员-会员查询 */
  DepositChange = 'depositChange',
  /** 仓库-配货订单 */
  WrhAllocOrder = 'wrhAllocOrder',
  /** 分拣管理-配货通知单 */
  WrhAllocOrderSummary = 'wrhAllocOrderSummary',
  /** 营业对账查询-收款流水 */
  StoreRevenueFlow = 'storeRevenueFlow',
  /** 商品-价格管理 */
  PriceCalcPlanForms = 'priceCalcPlanForms',
  /** 零售-零售查询 */
  PickingEmployeeStat = 'pickingEmployeeStat',
  /** 门店-店务-调拨 */
  StoreTransferDetail = 'storeTransferDetail',
  /** 门店-店务-调拨入库 */
  StoreTransferInDetail = 'storeTransferInDetail',
  /** 门店-店务-调拨出库 */
  StoreTransferOutDetail = 'storeTransferOutDetail',
  /** 仓库管理-配货单 */
  WrhAllocOutBatch = 'wrhAllocOutBatch',
  /** 总部-店务-查询报表 */
  AdjustPriceGoodsRecord = 'adjustPriceGoodsRecord',
  /** 门店-店务-查询报表 */
  StoreAdjustPriceGoodsRecord = 'storeAdjustPriceGoodsRecord',
  /** 店务-订单管理 */
  PosOrder = 'posOrder',
  /** 总部-供应链-财务报表 */
  JxMargin = 'jxMargin',
  /** 店务-查询报表 */
  DrptSale = 'drptSale',
  /** 供应商-补差协议 */
  SupplierSubdiffAgreement = 'supplierSubdiffAgreement',
  /** 财务-补差单 */
  SupplierSubdiff = 'supplierSubdiff',
  /** 供应链-进销存报表 */
  JxcDailyStore = 'jxcDailyStore',
  /** 仓库-分货池 */
  WrhAllotPool = 'wrhAllotPool',
  /** 仓库-提单 */
  DistLackGoods = 'distLackGoods',
  /** 店务-订货 */
  RequireOrder = 'requireOrder',
  /** 订货管理-订货（新） */
  RequireOrderV2 = 'requireOrderV2',
  /** 订货管理-订货（新）- 已订 */
  StoreDistPool = 'storeDistPool',
  /** 异常商品查询-不动销商品 */
  AbnormalNoSaleGoods = 'abnormalNoSaleGoods',
  /** 异常商品查询-门店不动销商品 */
  StoreAbnormalNoSaleGoods = 'storeAbnormalNoSaleGoods',
  /** 促销明细-促销明细 */
  PromtionSku = 'promtionSku',
  /** 促销商品-促销商品 */
  DrptPromtionSku = 'drptPromtionSku',
  /** 采购-报表 */
  PurchaseArrivalSupplier = 'purchaseArrivalSupplier',
  /** 客户台账-报表 */
  CustomerStandingBook = 'customerStandingBook',
  /** 礼品卡-报表 */
  CardDumpFlow = 'cardDumpFlow',
  /** 礼品卡对账-报表 */
  CardDrpt = 'cardDrpt',
  /** 财务-调拨查询报表 */
  TransferReportByWrh = 'transferReportByWrh',
  /** 结算管理-补差管理 */
  SupplierSubdiffReportDetail = 'supplierSubdiffReportDetail',
  /** 财务报表-损溢汇总 */
  LossGainReport = 'lossGainReport',
  /** 财务报表-损溢明细 */
  LossGainReportDetail = 'lossGainReportDetail',
  /** 结算管理-供应商台账 */
  SupplierSbSum = 'supplierSbSum',
  /** 仓库管理-分拣商品 */
  PickingGoods = 'pickingGoods',
  /** 分拣管理-拣货查询 */
  PickingReport = 'pickingReport',
  /** 拣货任务看板-一键出库 */
  PickingOutboundLimitError = 'pickingOutboundLimitError',
  /** 促销价查询-按商品 */
  PromoteSkuReport = 'promoteSkuReport',
  /** 促销价查询-按门店 */
  PromoteStoreReport = 'promoteStoreReport',
  /** 实体卡管理-提货卡 */
  PickupCard = 'pickupCard',
  /** 总部-财务报表 */
  PurchaseSupplier = 'purchaseSupplier',
  /** 实体卡管理-提货卡用卡商品明细 */
  PickupFlowGoods = 'pickupFlowGoods',
  /** 实体卡管理-提货卡发售流水 */
  PickupSaleFlow = 'pickupSaleFlow',
  /** 实体卡管理-提货卡消费流水 */
  PickupVerificationFlow = 'pickupVerificationFlow',
  /** 授权管理-折扣授权管理 */
  DiscountFlow = 'discountFlow',
  /** 维护工具-WMS库存差异报表 */
  WmsInvCheck = 'wmsInvCheck',
  /** 总部-门店退仓 */
  WrhAllocNoticeBackDetail = 'wrhAllocNoticeBackDetail',
  /** 总部-财务 */
  DistInoutFlowStore = 'distInoutFlowStore',
  /** 实体卡管理-礼品卡 */
  CardServiceOrder = 'cardServiceOrder',
  /** 供应链-采购管理 */
  PurchaseInOrder = 'purchaseInOrder',
  /** 仓库管理-仓库分货 */
  AllotGenAllocGoods = 'allotGenAllocGoods',
  /** 店务-门店商品库 */
  StoreGoodsSaleInfo = 'storeGoodsSaleInfo',
  /** 分账管理-分账单 */
  DivisionBill = 'divisionBill',
  /** 分账管理-门店结算协议 */
  StoreSettleAgreement = 'storeSettleAgreement'
}

export enum IoOutStatusEnum {
  /** 待开始 */
  Init = 'init',
  /** 进行中 */
  Running = 'running',
  /** 导出成功 */
  Finished = 'finished',
  /** 导出失败 */
  Error = 'error'
}

export enum IoTaskInStatusEnum {
  /** 待开始 */
  Init = 'init',
  /** 数据读取中 */
  Reading = 'reading',
  /** 待导入 */
  Waiting = 'waiting',
  /** 待确认 */
  DataError = 'dataError',
  /** 开启导入任务 */
  ToImport = 'toImport',
  /** 进行中 */
  Importing = 'importing',
  /** 导入完成 */
  Finished = 'finished',
  /** 导入失败 */
  Failed = 'failed',
  /** 已取消 */
  Canceled = 'canceled'
}

/** iwms物流模式 */
export enum IwmsLogisticModeEnum {
  /** 统配 */
  Unify = 'UNIFY',
  /** 二步越库 */
  Twostepcross = 'TWOSTEPCROSS',
  /** 类越库 */
  Likecross = 'LIKECROSS'
}

export enum JoinAccountStatusEnum {
  /** 已启用 */
  Enabled = 'enabled',
  /** 已停用 */
  Disabled = 'disabled'
}

/** 加盟协议状态 */
export enum JoinAgreementStatusEnum {
  /** 未生效 */
  Init = 'init',
  /** 已生效 */
  Effected = 'effected',
  /** 已失效 */
  Expired = 'expired',
  /** 已终止 */
  Terminated = 'terminated'
}

export enum JoinDeductReasonEnum {
  /** 货款 */
  UnKnowWordc2a29afe = '货款',
  /** 费用 */
  UnKnowWordecd76cb3 = '费用',
  /** 账户扣款原因名称恶霸霸霸 */
  UnKnowWordd2ebe338 = '账户扣款原因名称恶霸霸霸'
}

export enum JoinDeductionStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum JoinFlowOccurTypeEnum {
  /** 增加 */
  Add = 'add',
  /** 扣减 */
  Reduce = 'reduce'
}

export enum JoinFlowTradeTypeEnum {
  /** 配货 */
  Dist = 'dist',
  /** 配货-作废 */
  UnDist = 'unDist',
  /** 配货差异 */
  DistDiff = 'distDiff',
  /** 退货 */
  Back = 'back',
  /** 退货-作废 */
  UnBack = 'unBack',
  /** 缴款 */
  Pay = 'pay',
  /** 缴款-作废 */
  UnPay = 'unPay',
  /** 扣款 */
  Deduct = 'deduct',
  /** 扣款-作废 */
  UnDeduct = 'unDeduct',
  /** 订单扣款 */
  OrderPay = 'orderPay',
  /** 订单退款 */
  OrderBack = 'orderBack',
  /** 批发扣款 */
  WholesalePay = 'wholesalePay',
  /** 批发退款 */
  WholesaleBack = 'wholesaleBack'
}

export enum JoinPayReasonEnum {
  /** 货款 */
  UnKnowWordc2a29afe = '货款',
  /** 费用 */
  UnKnowWordecd76cb3 = '费用',
  /** 充值 */
  UnKnowWorde50663b2 = '充值',
  /** 账户缴款原因名称恶霸霸霸 */
  UnKnowWordf60d86c4 = '账户缴款原因名称恶霸霸霸'
}

export enum JoinPayTypeEnum {
  /** 现金 */
  Cash = 'cash',
  /** 银行转账 */
  Remit = 'remit',
  /** 微信 */
  WeiXin = 'weiXin',
  /** 支付宝 */
  AliPay = 'aliPay',
  /** 支票 */
  Cheque = 'cheque',
  /** 电汇 */
  Wire = 'wire',
  /** 银行汇票 */
  Bank = 'bank',
  /** 内部 */
  Inside = 'inside',
  /** 其他 */
  Other = 'other'
}

export enum JoinPaymentStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum KeyboardCashFunctionEnum {
  /** 录入/修改会员 */
  SetMember = 'setMember',
  /** 导购员 */
  Saler = 'saler',
  /** 单价 */
  Price = 'price',
  /** 数量 */
  Num = 'num',
  /** 折扣 */
  Discount = 'discount',
  /** 金额 */
  Amount = 'amount',
  /** 删除 */
  Delete = 'delete',
  /** 挂单/取单 */
  HangingOrder = 'hangingOrder',
  /** 取消交易 */
  CancelDeal = 'cancelDeal',
  /** 更多... */
  More = 'more',
  /** 结算 */
  Settle = 'settle',
  /** 销售退货 */
  SaleRefund = 'saleRefund',
  /** 销售冲账 */
  SaleReverse = 'saleReverse',
  /** 赠品录入 */
  SetGive = 'setGive',
  /** 自提核销 */
  SelfCanceling = 'selfCanceling',
  /** 重打小票 */
  ReprintTicket = 'reprintTicket',
  /** 打开钱箱 */
  OpenMoneyBox = 'openMoneyBox',
  /** 锁屏 */
  LockScreen = 'lockScreen',
  /** 会员注册 */
  MemberRegister = 'memberRegister',
  /** 储值充值 */
  BalanceCharge = 'balanceCharge',
  /** 礼品卡发售 */
  GiftCardSale = 'giftCardSale',
  /** 交易流水 */
  SaleFlow = 'saleFlow',
  /** 商品信息查询 */
  GoodsInfoQuery = 'goodsInfoQuery',
  /** 会员查询 */
  MemberQuery = 'memberQuery',
  /** 礼品卡查询 */
  GiftCardQuery = 'giftCardQuery',
  /** 原单结账 */
  OriginalSettle = 'originalSettle',
  /** 旧系统换卡 */
  OldCardWrite = 'oldCardWrite'
}

export enum KeyboardPayFunctionEnum {
  /** 整单折扣 */
  OrderFavour = 'orderFavour',
  /** 现金支付 */
  CashPay = 'cashPay',
  /** 鼎付通支付 */
  DftPay = 'dftPay',
  /** 积分支付 */
  PointPay = 'pointPay',
  /** 礼品卡支付 */
  GiftCardPay = 'giftCardPay',
  /** 提货卡支付 */
  PickupPay = 'pickupPay',
  /** 储值支付 */
  BalancePay = 'balancePay',
  /** 券支付 */
  CouponPay = 'couponPay',
  /** 完成结账 */
  FinishSettle = 'finishSettle',
  /** 券查询 */
  CouponQuery = 'couponQuery',
  /** 桂林银行刷卡 */
  GlCardPay = 'glCardPay',
  /** 桂林银行积分 */
  GlScorePay = 'glScorePay',
  /** 电子礼品卡 */
  ElecGiftCardPay = 'elecGiftCardPay'
}

export enum KeyboardSettingsTypeEnum {
  /** 销售界面 */
  Cash = 'cash',
  /** 支付界面 */
  Pay = 'pay'
}

/** 提单来源枚举 */
export enum LadingSourceEnum {
  /** 采购提单 */
  Purchase = 'purchase',
  /** 仓库提单 */
  Wrh = 'wrh'
}

export enum LadingTimeTypeEnum {
  /** 每天21点 */
  TwentyOne = 'twentyOne',
  /** 每天21点30 */
  TwentyOneHalf = 'twentyOneHalf',
  /** 每天22点 */
  TwentyTwo = 'twentyTwo',
  /** 每天22点30 */
  TwentyTwoHalf = 'twentyTwoHalf',
  /** 每天23点 */
  TwentyThree = 'twentyThree',
  /** 每天23点30 */
  TwentyThreeHalf = 'twentyThreeHalf'
}

export enum LadingTypeEnum {
  /** 全部提单 */
  All = 'all',
  /** 仓配提单 */
  Wrh = 'wrh',
  /** 直配提单 */
  Alloc = 'alloc'
}

export enum LastSettleNoEnum {
  /** 202304 */
  LastSettleNo = 'lastSettleNo'
}

export enum LevelModeEnum {
  /** 普通分级 */
  Normal = 'normal',
  /** 子母码 */
  Child = 'child'
}

/** 批发价格枚举 */
export enum LevelPriceTypeEnum {
  /** 公式价 */
  Formula = 'formula',
  /** 固定价 */
  Fixed = 'fixed',
  /** 无价格 */
  None = 'none'
}

export enum LineEnum {
  /** 线下 */
  Offline = 'offline',
  /** 线上 */
  Online = 'online'
}

/** 损溢报表组织类型 */
export enum LossGainReportOrgTypeEnum {
  /** 门店 */
  Store = 'store',
  /** 总部 */
  Hq = 'hq'
}

/** 损溢报表类型 */
export enum LossGainReportTypeEnum {
  /** 报溢 */
  Gain = 'gain',
  /** 报损 */
  Loss = 'loss'
}

export enum LossReportDimensionEnum {
  /** 仓库 */
  Wrh = 'wrh',
  /** 门店 */
  Store = 'store',
  /** 商品 */
  Goods = 'goods',
  /** 供应商 */
  Supplier = 'supplier',
  /** 分类 */
  Sort = 'sort',
  /** 明细 */
  Detail = 'detail'
}

export enum ManualSyncTypeEnum {
  /** 按照时间更新 */
  ByTime = 'byTime',
  /** 按照业务id更新 */
  ByBizKey = 'byBizKey'
}

export enum MdataTypeEnum {
  /** 部门 */
  Dept = 'dept',
  /** 区域 */
  Area = 'area',
  /** 员工 */
  User = 'user',
  /** 角色 */
  Role = 'role',
  /** 门店 */
  Store = 'store',
  /** 仓库 */
  Warehouse = 'warehouse',
  /** 商品SKU */
  Goods = 'goods',
  /** 商品档案 */
  Spu = 'spu',
  /** 品牌 */
  Brand = 'brand',
  /** 供应商 */
  Supplier = 'supplier',
  /** 商品规格 */
  Spec = 'spec',
  /** 服务项目 */
  ServiceItem = 'serviceItem',
  /** 服务套餐 */
  ServicePackage = 'servicePackage',
  /** 客户档案 */
  Customer = 'customer',
  /** 加工模板 */
  Recipe = 'recipe',
  /** 自助收银销售模板 */
  SelfPosGroup = 'selfPosGroup',
  /** 自助收银销售类目 */
  SelfPosGroupSort = 'selfPosGroupSort',
  /** 商品部门 */
  GoodsDept = 'goodsDept',
  /** 核算部门 */
  AccountDept = 'accountDept',
  /** 重点商品 */
  HotGoodsCategory = 'hotGoodsCategory',
  /** 门店线路 */
  DistLine = 'distLine',
  /** 分拣设备 */
  PickingDevice = 'pickingDevice',
  /** 皮重模版 */
  TareWeightTemplate = 'tareWeightTemplate'
}

export enum MemberCardStoredFlowActionTypeEnum {
  /** 消费 */
  Consume = 'consume',
  /** 退款 */
  Refund = 'refund',
  /** 充值 */
  Deposit = 'deposit',
  /** 转储 */
  Dump = 'dump',
  /** 退储值 */
  DepositRefund = 'depositRefund',
  /** 充值赠送 */
  RechargeDeposit = 'rechargeDeposit',
  /** 营销赠送 */
  MarketDeposit = 'marketDeposit',
  /** POS收银 */
  Wm906 = 'wm906',
  /** 领卡有礼 */
  Wm908 = 'wm908',
  /** 首次充值 */
  Wm909 = 'wm909',
  /** 首次消费 */
  Wm910 = 'wm910',
  /** 充值有礼 */
  Wm911 = 'wm911',
  /** 消费有礼 */
  Wm912 = 'wm912',
  /** 生日关怀 */
  Wm913 = 'wm913',
  /** 节日关怀 */
  Wm914 = 'wm914',
  /** 直接发券 */
  Wm915 = 'wm915',
  /** 开放平台扫码 */
  Wm888 = 'wm888',
  /** 线下活动 */
  Wm918 = 'wm918',
  /** 消费使用 */
  Wm924 = 'wm924',
  /** POS退款 */
  Wm928 = 'wm928',
  /** 线上充值 */
  Wm929 = 'wm929',
  /** 线下充值 */
  Wm930 = 'wm930',
  /** 兑换使用 */
  Wm932 = 'wm932',
  /** 手动增加 */
  Wm933 = 'wm933',
  /** 手动减少 */
  Wm934 = 'wm934',
  /** 签到有礼 */
  Wm935 = 'wm935',
  /** 线下导入会员 */
  Wm938 = 'wm938',
  /** 线上调整等级 */
  Wm944 = 'wm944',
  /** 线下调整等级 */
  Wm945 = 'wm945',
  /** 线下扣减余额 */
  Wm946 = 'wm946',
  /** 线下增加余额 */
  Wm947 = 'wm947',
  /** 线下发券 */
  Wm948 = 'wm948',
  /** 会员开放平台 */
  Wm953 = 'wm953',
  /** 未知 */
  Wm0 = 'wm0',
  /** 其它 */
  Wm1 = 'wm1'
}

export enum MemberCardStoredFlowChannelTypeEnum {
  /** 线上 */
  Wxapp = 'wxapp',
  /** 零售云后台 */
  Hq = 'hq',
  /** pos */
  Pos = 'pos'
}

export enum MemberCardTypeEnum {
  /** 会员卡 */
  Entity = 'entity',
  /** 礼品卡 */
  PrePay = 'prePay'
}

export enum MemberCardUnionStatusEnum {
  /** 使用中 */
  Using = 'using',
  /** 已挂失 */
  Lost = 'lost',
  /** 已冻结 */
  Freezed = 'freezed',
  /** 已过期 */
  Expired = 'expired'
}

export enum MemberConsumeChannelTypeEnum {
  /** 小程序 */
  Weimob = 'weimob',
  /** pos */
  Pos = 'pos'
}

/** 会员价规则 */
export enum MemberPriceRuleEnum {
  /** 门店会员价 */
  StoreMemberPrice = 'storeMemberPrice',
  /** 秤会员价 */
  WeighMemberPrice = 'weighMemberPrice',
  /** 门店会员价（与秤价取最低） */
  BestMemberPrice = 'bestMemberPrice',
  /** 不参与会员价 */
  NoMemberPrice = 'noMemberPrice'
}

export enum MemberTradeTypeEnum {
  /** 消费 */
  Consume = 'consume',
  /** 退款 */
  Refund = 'refund'
}

export enum MerchantPackTypeEnum {
  /** 商超版 */
  Super = 'super',
  /** 生鲜版 */
  Fresh = 'fresh'
}

export enum MessageBusinessTypeEnum {
  /** 采购订单即将失效 */
  PurchaseOrderToExpire = 'purchaseOrderToExpire',
  /** 采购订单已失效 */
  PurchaseOrderExpired = 'purchaseOrderExpired',
  /** 门店商品负库存提醒 */
  StoreGoodsNegativeInventory = 'storeGoodsNegativeInventory',
  /** 门店商品负毛利提醒 */
  StoreGoodsNegativeGross = 'storeGoodsNegativeGross',
  /** 采购协议到期提醒 */
  PurchaseAgreementExpired = 'purchaseAgreementExpired',
  /** 总部商品调价生效提醒 */
  StorePriceAdjustLaunch = 'storePriceAdjustLaunch',
  /** 安全库存补货提醒 */
  StoreGoodsSafeStock = 'storeGoodsSafeStock',
  /** 供应商合约费用自动生成提醒 */
  SupplierFeeAutoGen = 'supplierFeeAutoGen'
}

/** 美团必填信息缺失 */
export enum MtRequiredLackEnum {
  /** 无图 */
  NoImage = 'noImage',
  /** 无分组 */
  NoGroup = 'noGroup',
  /** 无类目 */
  NoCategory = 'noCategory',
  /** 未关联规格 */
  NoAttribute = 'noAttribute',
  /** 无类目属性 */
  NoCatalogAttribute = 'noCatalogAttribute'
}

/** 负毛利商品查询对应渠道枚举 */
export enum NegativeProfitPlatformEnum {
  /** 批发 */
  Wholesale = 'wholesale',
  /** POS */
  Pos = 'pos',
  /** 小程序 */
  Weimob = 'weimob',
  /** 美团 */
  Meituan = 'meituan',
  /** 饿了么 */
  Eleme = 'eleme',
  /** 京东到家 */
  Jddj = 'jddj',
  /** 快团团 */
  Ktt = 'ktt'
}

/** 订货商城客户申请状态 */
export enum OmallCustomerApplyStatusEnum {
  /** name */
  Id = 'id',
  /** 同意 */
  Approved = 'approved',
  /** 拒绝 */
  Refused = 'refused'
}

/** 订货商城首页装修跳转类型 */
export enum OmallHomeJumpTypeEnum {
  /** 分类 */
  Sort = 'sort',
  /** 商品 */
  Goods = 'goods'
}

export enum OnlineGoodsSearchTypeEnum {
  /** 已上架商品 */
  Up = 'up',
  /** 已下架商品 */
  Down = 'down',
  /** 已售罄商品 */
  SellOut = 'sellOut',
  /** 异常商品 */
  Abnormal = 'abnormal',
  /** 未上线商品 */
  NotOnline = 'notOnline'
}

export enum OnlineGoodsStatusEnum {
  /** 已上架 */
  Up = 'up',
  /** 已下架 */
  Down = 'down'
}

/** 线上同步操作 */
export enum OnlineGoodsSyncOperateEnum {
  /** 商品信息 */
  Goods = 'goods',
  /** 上下架状态 */
  UpDown = 'upDown',
  /** 库存 */
  Inv = 'inv',
  /** 价格 */
  Price = 'price'
}

export enum OnlineInvRuleTypeEnum {
  /** 等于门店库存 */
  Eq = 'eq',
  /** 百分比 */
  Percent = 'percent',
  /** 手动配置 */
  Manual = 'manual'
}

export enum OnlineOrderCancelReasonEnum {
  /** 店铺太忙 */
  UnKnowWord21cb4bea = '店铺太忙',
  /** 商品已售完 */
  UnKnowWord8bb948a6 = '商品已售完',
  /** 地址无法配送 */
  UnKnowWorde31ef095 = '地址无法配送',
  /** 店铺已打烊 */
  UnKnowWord352160e8 = '店铺已打烊',
  /** 联系不上顾客 */
  UnKnowWord66990608 = '联系不上顾客',
  /** 假订单 */
  UnKnowWord7fc5c9bd = '假订单',
  /** 配送员取货慢 */
  UnKnowWord64fee481 = '配送员取货慢',
  /** 线上订单取消原因名称恶霸 */
  UnKnowWordc47b41af = '线上订单取消原因名称恶霸',
  /** zjl测试取消订单 */
  UnKnowWord039e2858 = 'zjl测试取消订单'
}

export enum OnlineOrderCommentScopeEnum {
  /** 一星 */
  One = 'one',
  /** 二星 */
  Two = 'two',
  /** 三星 */
  Three = 'three',
  /** 四星 */
  Four = 'four',
  /** 五星 */
  Five = 'five'
}

export enum OnlineOrderCommentTypeEnum {
  /** 全部评论 */
  All = 'all',
  /** 带图评论 */
  HasImage = 'hasImage',
  /** 默认评价 */
  Default = 'default',
  /** 好评 */
  Goods = 'goods',
  /** 差评 */
  Negative = 'negative'
}

export enum OnlineOrderDeliverModesEnum {
  /** 平台配送 */
  PlatformDeliver = 'PlatformDeliver',
  /** 到店自提 */
  PickUpInStoreDeliver = 'PickUpInStoreDeliver',
  /** 商家自配 */
  StoreDeliver = 'StoreDeliver',
  /** 三方配送 */
  ThirdPlatformDeliver = 'ThirdPlatformDeliver',
  /** 快递配送 */
  ExpressDeliver = 'ExpressDeliver'
}

export enum OnlineOrderRDeliverModesEnum {
  /** 快递配送 */
  ExpressDeliver = 'ExpressDeliver',
  /** 到店退货 */
  ReturnInStoreDeliver = 'ReturnInStoreDeliver'
}

export enum OnlineOrderRRefuseReasonEnum {
  /** 已和用户电话沟通 */
  UnKnowWord99227986 = '已和用户电话沟通',
  /** 商品正在配送中 */
  UnKnowWorde2cdbebf = '商品正在配送中',
  /** 商品无质量问题 */
  UnKnowWordb1a4934e = '商品无质量问题',
  /** 商品没有缺货少货问题 */
  UnKnowWord6980a642 = '商品没有缺货少货问题',
  /** 申请时间已经超过售后服务时限 */
  UnKnowWord26efc310 = '申请时间已经超过售后服务时限',
  /** 其他 */
  UnKnowWord0d98c747 = '其他'
}

export enum OnlineOrderRRefuseReceiveReasonEnum {
  /** 收到货物有破损 */
  UnKnowWord2a65ef27 = '收到货物有破损',
  /** 未收到货物 */
  UnKnowWord551cd885 = '未收到货物',
  /** 其他 */
  UnKnowWord0d98c747 = '其他'
}

export enum OnlineOrderRStatusEnum {
  /** 待审核 */
  Submitted = 'SUBMITTED',
  /** 已同意 */
  Agreed = 'AGREED',
  /** 待收货 */
  Sent = 'SENT',
  /** 已收货 */
  Received = 'RECEIVED',
  /** 已完成 */
  Finished = 'FINISHED',
  /** 已取消 */
  Canceled = 'CANCELED',
  /** 已拒绝 */
  Refused = 'REFUSED'
}

export enum OnlineOrderRTypeEnum {
  /** 仅退款 */
  RefundOnly = 'REFUND_ONLY',
  /** 退货退款 */
  Returns = 'RETURNS'
}

export enum OnlineOrderRefuseOrderReasonEnum {
  /** 店铺太忙 */
  UnKnowWord21cb4bea = '店铺太忙',
  /** 商品已售完 */
  UnKnowWord8bb948a6 = '商品已售完',
  /** 地址无法配送 */
  UnKnowWorde31ef095 = '地址无法配送',
  /** 店铺已打烊 */
  UnKnowWord352160e8 = '店铺已打烊',
  /** 线上订单拒绝接单名称恶霸 */
  UnKnowWorde03e50b8 = '线上订单拒绝接单名称恶霸',
  /** 立志的拒绝接单 */
  UnKnowWord3a604364 = '立志的拒绝接单',
  /** zjl测试拒绝接单 */
  UnKnowWordeb2f0b92 = 'zjl测试拒绝接单',
  /** 线上订单拒绝接单2 */
  UnKnowWord5fe00169 = '线上订单拒绝接单2',
  /** 通天塔 */
  UnKnowWord8436dd8d = '通天塔'
}

export enum OnlineOrderRefuseReasonEnum {
  /** 商品已售完 */
  UnKnowWord8bb948a6 = '商品已售完',
  /** 店铺太忙了 */
  UnKnowWord78419a9f = '店铺太忙了',
  /** 店铺已打烊 */
  UnKnowWord352160e8 = '店铺已打烊',
  /** 其他 */
  UnKnowWord0d98c747 = '其他'
}

export enum OnlineOrderRefuseRefundReasonEnum {
  /** 已和顾客电话沟通 */
  UnKnowWord254b61d1 = '已和顾客电话沟通',
  /** 商品无质量问题 */
  UnKnowWordb1a4934e = '商品无质量问题',
  /** 商品没有缺货少货问题 */
  UnKnowWord6980a642 = '商品没有缺货少货问题',
  /** 商品影响二次销售 */
  UnKnowWordf50e03b6 = '商品影响二次销售',
  /** 商品不支持无理由退货 */
  UnKnowWordc9b5a020 = '商品不支持无理由退货',
  /** 未收到商品 */
  UnKnowWordd90db565 = '未收到商品',
  /** 线上退款拒绝原因名称恶霸 */
  UnKnowWord516db54c = '线上退款拒绝原因名称恶霸'
}

export enum OnlineOrderStatusEnum {
  /** 待接单 */
  UnTake = 'UN_TAKE',
  /** 待拣货 */
  Took = 'TOOK',
  /** 待配送 */
  UnDeliver = 'UN_DELIVER',
  /** 配送中 */
  Delivering = 'DELIVERING',
  /** 配送完成 */
  Delivered = 'DELIVERED',
  /** 待自提 */
  UnPickUp = 'UN_PICK_UP',
  /** 已自提 */
  PickUp = 'PICK_UP',
  /** 待发货 */
  UnSend = 'UN_SEND',
  /** 待收货 */
  Sent = 'SENT',
  /** 已完成 */
  Finished = 'FINISHED',
  /** 已取消 */
  Canceled = 'CANCELED'
}

export enum OnlineOrderTypeEnum {
  /** 外卖订单 */
  Takeout = 'TAKEOUT',
  /** 预售订单 */
  Advance = 'ADVANCE',
  /** 积分兑换订单 */
  Integral = 'INTEGRAL',
  /** 拼团 */
  Group = 'GROUP',
  /** 社区团购 */
  CommunityGroup = 'COMMUNITY_GROUP'
}

export enum OnlinePlatformEnum {
  /** 美团 */
  Meituan = 'meituan',
  /** 饿了么 */
  Eleme = 'eleme',
  /** 京东到家 */
  Jddj = 'jddj',
  /** 小程序 */
  Weimob = 'weimob'
}

export enum OnlinePriceRuleTypeEnum {
  /** 等于门店售价 */
  Eq = 'eq',
  /** 百分比 */
  Percent = 'percent',
  /** 手动配置 */
  Manual = 'manual'
}

export enum OnlineRuleTypeEnum {
  /** 等于门店库存 */
  Eq = 'eq',
  /** 百分比 */
  Percent = 'percent',
  /** 手动配置 */
  Manual = 'manual'
}

export enum OnlineStoreStatusEnum {
  /** 营业中 */
  Open = 'open',
  /** 休息 */
  Closed = 'closed',
  /** 停业 */
  Stop = 'stop',
  /** 暂停营业 */
  Pause = 'pause',
  /** 可预定 */
  Reserve = 'reserve'
}

export enum OnlineTaskGoodsAbnormalReasonEnum {
  /** 校验成功 */
  Success = 'success',
  /** 拣货商品码不存在 */
  BarcodeNotExist = 'barcodeNotExist',
  /** 订单商品不存在 */
  GoodsNotExist = 'goodsNotExist',
  /** 拣货商品码不匹配 */
  BarcodeError = 'barcodeError'
}

export enum OrderBillClassEnum {
  /** 销售 */
  Sale = 'sale',
  /** 销售退货 */
  SaleReturn = 'saleReturn',
  /** 冲账退货 */
  OffsetReturn = 'offsetReturn'
}

export enum OrgTypeEnum {
  /** 总部 */
  Hq = 'hq',
  /** 区域 */
  Area = 'area',
  /** 部门 */
  Dept = 'dept',
  /** 门店 */
  Store = 'store',
  /** 供应商 */
  Supplier = 'supplier'
}

export enum OrgValidateFieldEnum {
  /** 区域编号 */
  AreaId = 'areaId',
  /** 区域名称 */
  AreaName = 'areaName',
  /** 部门编号 */
  DeptId = 'deptId',
  /** 部门名称 */
  DeptName = 'deptName'
}

export enum OutStatusViewEnumEnum {
  /** 已收货 */
  Received = 'received',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Abolish = 'abolish'
}

export enum PayBillClassEnum {
  /** 结算单 */
  Settle = 'settle',
  /** 进退货单 */
  PurchaseInout = 'purchaseInout',
  /** 费用单 */
  Fee = 'fee'
}

export enum PayBusinessTypeEnum {
  /** 供应商 */
  Supplier = 'supplier',
  /** 门店 */
  Store = 'store',
  /** 客户 */
  Customer = 'customer'
}

export enum PaySettleTypeEnumEnum {
  /** 经销 */
  Distributor = 'distributor',
  /** 联营 */
  Jointor = 'jointor'
}

export enum PayStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 待付款 */
  Approved = 'approved',
  /** 已付款 */
  Payed = 'payed',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum PayTypeEnum {
  /** 现金 */
  Cash = 'cash',
  /** 银行转账 */
  Remit = 'remit',
  /** 微信 */
  WeiXin = 'weiXin',
  /** 支付宝 */
  AliPay = 'aliPay',
  /** 支票 */
  Cheque = 'cheque',
  /** 电汇 */
  Wire = 'wire',
  /** 银行汇票 */
  Bank = 'bank',
  /** 内部 */
  Inside = 'inside',
  /** 其他 */
  Other = 'other'
}

export enum PayedStatusEnum {
  /** 待付款 */
  UnPay = 'unPay',
  /** 已付款 */
  Payed = 'payed',
  /** 部分付款 */
  PartPay = 'partPay'
}

export enum PaymentSchemaEnum {
  /** 支付宝 */
  AliPay = 'aliPay',
  /** 微信 */
  WeiXin = 'weiXin',
  /** 银联支付 */
  UnionAcPay = 'unionAcPay'
}

export enum PermTableNodeTypeEnum {
  /** 菜单 */
  Menu = 'menu',
  /** 权限 */
  Perm = 'perm'
}

export enum PermTypeEnum {
  /** 查询 */
  View = 'view',
  /** 编辑 */
  Edit = 'edit',
  /** 删除 */
  Delete = 'delete',
  /** 其它 */
  Others = 'others'
}

/** 拣货单拣货商品明细行来源 */
export enum PickingDetailSourceEnum {
  /** 分拣秤添加 */
  Scale = 'scale',
  /** 拣货波次 */
  Wave = 'wave'
}

/** 拣货模式 */
export enum PickingModelEnum {
  /** 按需求拣货 */
  Required = 'required',
  /** 按提单时可用库存分拣 */
  UsableQty = 'usableQty'
}

/** 一键出库批次状态 */
export enum PickingOutboundStatusEnum {
  /** 初始化 */
  Init = 'init',
  /** 校验中 */
  CheckDoing = 'checkDoing',
  /** 校验不通过 */
  CheckFailed = 'checkFailed',
  /** 出库中 */
  OutDocing = 'outDocing',
  /** 出库完成 */
  End = 'end',
  /** 完成 */
  Finished = 'finished'
}

/** 一键出库类型 */
export enum PickingOutboundTypeEnum {
  /** 全部出库 */
  All = 'all',
  /** 按线路出库 */
  DistLine = 'distLine',
  /** 按门店出库 */
  Store = 'store'
}

/** 拣货单来源 */
export enum PickingSourceEnum {
  /** 手动创建 */
  Manual = 'manual',
  /** 拣货波次 */
  PickingWave = 'pickingWave'
}

/** 拣货单状态 */
export enum PickingStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待拣货 */
  Submitted = 'submitted',
  /** 已拣货 */
  Picked = 'picked',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Aborted = 'aborted'
}

/** 波次单来源 */
export enum PickingWaveSourceEnum {
  /** 手工新建 */
  Manual = 'manual',
  /** 提单生成 */
  Lading = 'lading'
}

/** 波次单状态 */
export enum PickingWaveStatusEnum {
  /** 待启动 */
  Init = 'init',
  /** 启动中 */
  Starting = 'starting',
  /** 启动完成 */
  StartFinish = 'startFinish',
  /** 启动失败 */
  StartFail = 'startFail',
  /** 波次确认 */
  ConfirmStartFinish = 'confirmStartFinish',
  /** 完成中 */
  FinishDoing = 'finishDoing',
  /** 已完成 */
  Finished = 'finished'
}

/** 提货券实例状态 */
export enum PickupCardEntityStatusTypeEnum {
  /** 全部 */
  All = 'all',
  /** 未出售 */
  Unsale = 'unsale',
  /** 使用中 */
  Using = 'using',
  /** 已使用 */
  Used = 'used',
  /** 已过期 */
  Expired = 'expired',
  /** 已作废 */
  Aborted = 'aborted'
}

/** 提货券实例发售渠道 */
export enum PickupCardSaleChannelTypeEnum {
  /** POS */
  Pos = 'pos',
  /** 总部后台 */
  Hq = 'hq'
}

/** 提货券类型门店类型 */
export enum PickupCardStoreTypeEnum {
  /** 全部门店 */
  All = 'all',
  /** 指定门店 */
  Assign = 'assign'
}

/** 提货券类型启用状态 */
export enum PickupCardTypeStatusEnum {
  /** 可用 */
  Enabled = 'enabled',
  /** 停用 */
  Disabled = 'disabled'
}

/** 提货卡流水类型 */
export enum PickupFlowTypeEnum {
  /** 消费 */
  Consume = 'consume'
}

export enum PointGoodsOrderByEnumEnum {
  /** 默认排序 */
  DefaultAsc = 'defaultAsc',
  /** 日期升序 */
  OrderTimeAsc = 'orderTimeAsc',
  /** 日期降序 */
  OrderTimeDesc = 'orderTimeDesc',
  /** 兑换数量升序 */
  QtyAsc = 'qtyAsc',
  /** 兑换数量降序 */
  QtyDesc = 'qtyDesc',
  /** 兑换积分升序 */
  UseMemberPointAsc = 'useMemberPointAsc',
  /** 兑换积分降序 */
  UseMemberPointDesc = 'useMemberPointDesc',
  /** 兑换金额升序 */
  TotalAsc = 'totalAsc',
  /** 兑换金额降序 */
  TotalDesc = 'totalDesc'
}

export enum PosAuthCodeStatusEnum {
  /** 未使用 */
  Unactivated = 'unactivated',
  /** 已使用 */
  Activated = 'activated',
  /** 已失效 */
  Expired = 'expired'
}

export enum PosDataDistributeRangeTypeEnum {
  /** 业务配置 */
  BusinessConfig = 'businessConfig',
  /** 商品资料 */
  Goods = 'goods',
  /** 促销资料 */
  Promotion = 'promotion',
  /** 销售面板 */
  SaleGroup = 'saleGroup',
  /** 小票模板 */
  TicketTemplate = 'ticketTemplate'
}

/** 收银机设备枚举 */
export enum PosDeviceFieldEnumEnum {
  /** 设备厂商 */
  DeviceFactory = 'device_factory',
  /** 设备型号 */
  DeviceModel = 'device_model',
  /** 系统版本 */
  SystemVersion = 'system_version',
  /** app版本 */
  AppVersion = 'app_version',
  /** 主屏幕 */
  MajorScreen = 'major_screen',
  /** 副屏幕 */
  MinorScreen = 'minor_screen',
  /** TTS是否启用 */
  TtsEnable = 'tts_enable',
  /** AI是否激活 */
  AiEnable = 'ai_enable'
}

export enum PosDeviceTypeEnum {
  /** 收银机 */
  Deskpos = 'deskpos',
  /** 自助收银机 */
  Selfpos = 'selfpos',
  /** PC收银 */
  Pcpos = 'pcpos',
  /** PC收银键盘版 */
  Kpos = 'kpos'
}

export enum PosDistributeTaskStatusEnum {
  /** 正在下发 */
  Running = 'running',
  /** 下发完成 */
  Finished = 'finished',
  /** 部分失败 */
  PartFailed = 'partFailed',
  /** 全部失败 */
  Failed = 'failed'
}

export enum PosLockStatusEnum {
  /** 已冻结 */
  Locked = 'locked',
  /** 未冻结 */
  Unlock = 'unlock'
}

export enum PosLogModuleEnum {
  /** 收银 */
  Cashier = 'cashier',
  /** 会员 */
  Member = 'member'
}

export enum PosLogOperationEnum {
  /** 打开钱箱 */
  OpenBox = 'openBox',
  /** 手动改价 */
  ChangePrice = 'changePrice',
  /** 手动商品折扣 */
  Discount = 'discount',
  /** 整单折扣 */
  FullDiscount = 'fullDiscount',
  /** 挂单 */
  HandUp = 'handUp',
  /** 整单取消 */
  Cancel = 'cancel',
  /** 删除商品 */
  DeleteGoods = 'deleteGoods',
  /** 销售冲账 */
  OffsetReturn = 'offsetReturn',
  /** 销售退货 */
  SaleReturn = 'saleReturn',
  /** 手工赠品 */
  Gift = 'gift',
  /** 储值退 */
  BalanceReturn = 'balanceReturn',
  /** 修改支付密码 */
  UpdatePayPassword = 'updatePayPassword',
  /** 重置支付密码 */
  ResetPayPassword = 'resetPayPassword',
  /** 冻结会员 */
  FreezeMember = 'freezeMember',
  /** 解冻会员 */
  UnfreezeMember = 'unfreezeMember',
  /** 会员实体卡挂失 */
  EntityCardReportLoss = 'entityCardReportLoss',
  /** 会员实体卡补卡 */
  EntityCardReplace = 'entityCardReplace'
}

export enum PosMonitorStatusEnum {
  /** 在线 */
  Online = 'online',
  /** 断开 */
  Offline = 'offline',
  /** 数据同步正常 */
  Normal = 'normal',
  /** 数据同步异常 */
  Error = 'error',
  /** 流水加工已完成 */
  Done = 'done',
  /** 流水加工未完成 */
  Doing = 'doing'
}

export enum PosOnlineStatusEnum {
  /** 在线 */
  Online = 'online',
  /** 断开 */
  Offline = 'offline'
}

export enum PosSchemaPriceTagEnum {
  /** 合计金额 */
  TotalAmount = 'totalAmount',
  /** 会员优惠金额 */
  MemberDiscountAmount = 'memberDiscountAmount',
  /** 优惠金额 */
  DiscountAmount = 'discountAmount',
  /** 应付金额 */
  ShouldAmount = 'shouldAmount'
}

export enum PosSchemaPrinterModelEnum {
  /** MS：美松打印机 */
  Ms = 'MS',
  /** PT：中科打印机 */
  Pt = 'PT',
  /** SMK：商米K系列打印机 */
  Smk = 'SMK',
  /** SMK2MN：商米K2mini内置打印 */
  SmkMini = 'SMK_MINI',
  /** SMT：商米T系列打印机 */
  Smt = 'SMT',
  /** HX：海信打印机 */
  Hx = 'HX',
  /** ZD：正大打印机 */
  Zd = 'ZD',
  /** USB：USB打印机 */
  Usb = 'USB'
}

export enum PosSchemaRoundModeEnum {
  /** 不抹零 */
  None = 'none',
  /** 整单抹分 */
  FloorToFen = 'floorToFen',
  /** 整单抹角 */
  FloorToJiao = 'floorToJiao',
  /** 定制抹零 */
  RoundToJiao = 'roundToJiao',
  /** 部分抹分 */
  PartFloorToFen = 'partFloorToFen',
  /** 部分抹角 */
  PartFloorToJiao = 'partFloorToJiao'
}

export enum PosSchemaScreenModeEnum {
  /** 广告模式 */
  AdMode = 'adMode',
  /** 收银模式 */
  CashMode = 'cashMode',
  /** 支付宝模式 */
  AlipayMode = 'alipayMode'
}

export enum PosStatusEnum {
  /** 未激活 */
  Unactivated = 'unactivated',
  /** 已激活 */
  Activated = 'activated'
}

/** 预盘单状态 */
export enum PreCheckStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已提交 */
  Submited = 'submited',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum PrePayCardActionTypeEnum {
  /** 制卡 */
  Make = 'make',
  /** 售卡 */
  Sale = 'sale',
  /** 过期 */
  Expire = 'expire',
  /** 绑卡 */
  Bind = 'bind'
}

export enum PrePayCardCashActionTypeEnum {
  /** 消费 */
  Consume = 'consume',
  /** 退款 */
  Refund = 'refund',
  /** 作废 */
  Abort = 'abort'
}

export enum PrePayCardDumpEnum {
  /** 不允许 */
  NoAllow = 'noAllow',
  /** 允许一次性卡 */
  OneAllow = 'oneAllow',
  /** 允许所有卡 */
  AllAllow = 'allAllow'
}

export enum PrePayCardOrderStatusEnum {
  /** 批次 */
  BatchNo = 'batchNo',
  /** 有效期 */
  ValidateDate = 'validateDate',
  /** 制卡时间 */
  MakeTime = 'makeTime',
  /** 流水交易时间 */
  Created = 'created',
  /** 售卡时间 */
  SaleTime = 'saleTime',
  /** 变动金额 */
  Amount = 'amount'
}

export enum PrePayCardSaleChannelTypeEnum {
  /** POS */
  Pos = 'pos',
  /** 总部后台 */
  Hq = 'hq'
}

/** 礼品卡状态值 */
export enum PrePayCardStatusActionTypeEnum {
  /** 全部 */
  All = 'all',
  /** 未出售 */
  Unsale = 'unsale',
  /** 使用中 */
  Using = 'using',
  /** 已使用 */
  Used = 'used',
  /** 已过期 */
  Expired = 'expired',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum PrePayCardStoreTypeEnum {
  /** 全部门店 */
  All = 'all',
  /** 指定门店 */
  Assign = 'assign'
}

export enum PrePayCardTypeStatusEnum {
  /** 可用 */
  Enabled = 'enabled',
  /** 停用 */
  Disabled = 'disabled'
}

export enum PriceTagBarCodeEnum {
  /** PLU码 */
  PluId = 'pluId',
  /** 商品编码 */
  Id = 'id',
  /** 商品条码 */
  Barcode = 'barcode',
  /** 速记码 */
  ShortCode = 'shortCode',
  /** 促销码 */
  PromoteCode = 'promoteCode'
}

/** 价签店务单据 */
export enum PriceTagBusinessBillTypeEnum {
  /** 仓配 */
  WrhAllocOut = 'wrhAllocOut',
  /** 直配 */
  Alloc = 'alloc',
  /** 直送 */
  Direct = 'direct',
  /** 采购订单 */
  PurchaseOrder = 'purchaseOrder',
  /** 自采 */
  StoreIn = 'storeIn',
  /** 联营进货 */
  SellunionIn = 'sellunionIn'
}

export enum PriceTagFixEnum {
  /** 门店名称 */
  StoreName = 'storeName',
  /** 打印时间 */
  PrintTime = 'printTime',
  /** 销售单号 */
  BillId = 'billId',
  /** 描述文字 */
  Describe = 'describe',
  /** 横线 */
  HLine = 'hLine',
  /** 竖线 */
  VLine = 'vLine',
  /** 备注 */
  Remark = 'remark'
}

export enum PriceTagGoodsEnum {
  /** 品名 */
  Name = 'name',
  /** 包装 */
  QpcStr = 'qpcStr',
  /** 计量单位 */
  Munit = 'munit',
  /** 基本单位 */
  BasicMunit = 'basicMunit',
  /** 零售价 */
  RetailPrice = 'retailPrice',
  /** 会员价 */
  MemberPrice = 'memberPrice',
  /** 会员价1 */
  MemberPrice1 = 'memberPrice1',
  /** 会员价2 */
  MemberPrice2 = 'memberPrice2',
  /** 会员价3 */
  MemberPrice3 = 'memberPrice3',
  /** 会员价4 */
  MemberPrice4 = 'memberPrice4',
  /** 会员价5 */
  MemberPrice5 = 'memberPrice5',
  /** 市场价 */
  MarketPrice = 'marketPrice',
  /** 促销价 */
  PromotionPrice = 'promotionPrice',
  /** 产地 */
  Origin = 'origin',
  /** 规格 */
  Spec = 'spec',
  /** 等级 */
  Level = 'level',
  /** 品牌 */
  Brand = 'brand',
  /** 货架位 */
  ShelvesFullId = 'shelvesFullId',
  /** 供应商名称 */
  Supplier = 'supplier',
  /** 供应商编码 */
  SupplierId = 'supplierId',
  /** 促销活动开始时间 */
  PromoteStartTime = 'promoteStartTime',
  /** 促销活动结束时间 */
  PromoteEndTime = 'promoteEndTime',
  /** 促销活动重复时间 */
  PromoteRepeatTime = 'promoteRepeatTime',
  /** 编码 */
  GoodsId = 'goodsId',
  /** 折扣值 */
  DiscountValue = 'discountValue',
  /** 指定价格 */
  PromoteValue = 'promoteValue'
}

/** 价签模版-拣货商品信息 */
export enum PriceTagPickingGoodsEnum {
  /** 商品编码 */
  GoodsId = 'goodsId',
  /** 商品名称 */
  GoodsName = 'goodsName',
  /** 单位 */
  GoodsMunit = 'goodsMunit',
  /** PLU码 */
  GoodsPlu = 'goodsPlu'
}

/** 价签模版-拣货信息 */
export enum PriceTagPickingInfoEnum {
  /** 应拣 */
  Qty = 'qty',
  /** 实拣 */
  PickedQty = 'pickedQty'
}

export enum PriceTagPlugFileEnum {
  /** https://c-dev.weimobwmc.com/qa-static-resource/b7dedc50890746509b0e7b74d8332154.exe */
  Windows32 = 'windows32',
  /** https://c-dev.weimobwmc.com/qa-static-resource/1aed8b2a2cab4b1792c7406344ef46bf.exe */
  Windows64 = 'windows64'
}

export enum PriceTagQrcodeEnum {
  /** 促销 */
  Promotion = 'promotion',
  /** 详情 */
  Detail = 'detail'
}

export enum PriceTagStatusEnum {
  /** 可用 */
  Enabled = 'enabled',
  /** 禁用 */
  Disabled = 'disabled'
}

/** 价签模版-门店信息 */
export enum PriceTagStoreInfoEnum {
  /** 门店编码 */
  StoreId = 'storeId',
  /** 门店名称 */
  StoreName = 'storeName',
  /** 所属线路 */
  DistLine = 'distLine'
}

export enum PriceTagTypeEnum {
  /** 普通价签 */
  PriceTagTemplate = 'priceTagTemplate',
  /** 促销码 */
  PromoteCodeTemplate = 'promoteCodeTemplate',
  /** 折扣码 */
  DiscountCodeTemplate = 'discountCodeTemplate',
  /** 生鲜拣货 */
  PickingTemplate = 'pickingTemplate'
}

export enum PriceTypeEnum {
  /** 平进平出 */
  NoProfit = 'noProfit',
  /** 固定价格 */
  Fixed = 'fixed',
  /** 价格公式 */
  Fx = 'fx'
}

/** 打印状态 */
export enum PrintStatusEnum {
  /** 已打印 */
  Printed = 'printed',
  /** 未打印 */
  UnPrinted = 'unPrinted'
}

export enum PrintingBillClassEnum {
  /** 采购订单 */
  PurchaseOrder = 'purchaseOrder',
  /** 采购进货单 */
  PurchaseIn = 'purchaseIn',
  /** 采购退货单 */
  PurchaseBack = 'purchaseBack',
  /** 门店进货单 */
  StoreIn = 'storeIn',
  /** 门店退货单 */
  StoreReturn = 'storeReturn',
  /** 配货订单 */
  WrhAllocOrder = 'wrhAllocOrder',
  /** 配货单 */
  WrhAlloc = 'wrhAlloc',
  /** 拣货单 */
  WrhPick = 'wrhPick',
  /** 门店退仓单 */
  WrhAllocBack = 'wrhAllocBack',
  /** 预付款单 */
  Prepay = 'prepay',
  /** 费用单 */
  Fee = 'fee',
  /** 对账单 */
  Settle = 'settle',
  /** 付款单 */
  Pay = 'pay',
  /** 收款单 */
  Receipt = 'receipt',
  /** 门店批发单 */
  WholesaleOut = 'wholesaleOut',
  /** 门店批发退货单 */
  WholesaleBack = 'wholesaleBack',
  /** 门店领用单 */
  StoreUse = 'storeUse',
  /** 门店报损单 */
  StoreLoss = 'storeLoss',
  /** 门店报溢单 */
  StoreGain = 'storeGain',
  /** 门店收货单 */
  StoreReceived = 'storeReceived',
  /** 门店调拨单 */
  StoreTransfer = 'storeTransfer',
  /** 自采退货单 */
  StoreInBack = 'storeInBack',
  /** 直送退货单 */
  DirectBack = 'directBack',
  /** 仓库报损单 */
  WrhLoss = 'wrhLoss',
  /** 仓库报溢单 */
  WrhGain = 'wrhGain',
  /** 联营进货单 */
  SellunionIn = 'sellunionIn',
  /** 联营退货单 */
  SellunionBack = 'sellunionBack',
  /** 供应商采购订单 */
  SupplierPurchaseOrder = 'supplierPurchaseOrder',
  /** 收银界面键盘设置 */
  KeyboardSettingsCash = 'keyboardSettingsCash',
  /** 付款界面键盘设置 */
  KeyboardSettingsPay = 'keyboardSettingsPay',
  /** 商品调价报表 */
  PriceAdjustLaunchGoods = 'priceAdjustLaunchGoods',
  /** 联营结算单 */
  SellunionSettle = 'sellunionSettle',
  /** 导出仓库批发单 */
  WrhWholeSaleOut = 'wrhWholeSaleOut',
  /** 导出仓库批发退货单 */
  WrhWholeSaleBack = 'wrhWholeSaleBack',
  /** 门店改价单 */
  StoreAdjustPrice = 'storeAdjustPrice',
  /** 订货记录 */
  OrderGoodsRecord = 'orderGoodsRecord',
  /** 仓配收货单 */
  WrhAllocIn = 'wrhAllocIn',
  /** 收货差异单 */
  DistributionDiff = 'distributionDiff',
  /** 退货申请 */
  ApplyBack = 'applyBack',
  /** 自采入库单 */
  StoreMarketIn = 'storeMarketIn',
  /** 自采退货单 */
  StoreMarketBack = 'storeMarketBack',
  /** 门店加工单 */
  StoreRecipe = 'storeRecipe',
  /** 门店分割单 */
  StoreDivision = 'storeDivision',
  /** 采购计划单 */
  PurchasePlan = 'purchasePlan',
  /** 批发订单 */
  WholesaleOrder = 'wholesaleOrder',
  /** 盘点流水单 */
  CheckFlow = 'checkFlow',
  /** 仓库加工单 */
  WrhRecipe = 'wrhRecipe',
  /** 仓库分割单 */
  WrhDivision = 'wrhDivision',
  /** 预盘单 */
  PreCheck = 'preCheck',
  /** 直送收货单 */
  DirectOut = 'directOut',
  /** 直配收货单 */
  AllocOut = 'allocOut',
  /** 直配退货单 */
  AllocBack = 'allocBack',
  /** 线上订单 */
  OnlineOrder = 'onlineOrder',
  /** 拣货单 */
  Picking = 'picking',
  /** 仓库领用单 */
  WrhUse = 'wrhUse',
  /** 批发结算单 */
  WholesaleSettle = 'wholesaleSettle',
  /** 门店退仓通知单 */
  WrhAllocNoticeBackPos = 'wrhAllocNoticeBackPos',
  /** 门店批发结算单 */
  StoreWholesaleSettle = 'storeWholesaleSettle',
  /** 门店费用单 */
  StoreFee = 'storeFee',
  /** 门店经销结算单 */
  StoreSettle = 'storeSettle',
  /** 门店采购订单 */
  StorePurchaseOrder = 'storePurchaseOrder',
  /** 门店付款单 */
  StorePay = 'storePay',
  /** 门店预付款单 */
  StorePrepay = 'storePrepay',
  /** 门店联营结算单 */
  StoreSellUnionSettle = 'storeSellUnionSettle',
  /** 门店收款单 */
  StoreReceipt = 'storeReceipt'
}

export enum PrintingTempEnum {
  /**  */
  PurchaseOrder = 'purchaseOrder',
  /**  */
  PurchaseIn = 'purchaseIn',
  /**  */
  PurchaseBack = 'purchaseBack',
  /**  */
  StoreIn = 'storeIn',
  /**  */
  StoreReturn = 'storeReturn',
  /**  */
  WrhAllocOrder = 'wrhAllocOrder',
  /**  */
  WrhAlloc = 'wrhAlloc',
  /**  */
  WrhPick = 'wrhPick',
  /**  */
  WrhAllocBack = 'wrhAllocBack',
  /**  */
  Prepay = 'prepay',
  /**  */
  Fee = 'fee',
  /**  */
  Settle = 'settle',
  /**  */
  Pay = 'pay',
  /**  */
  Receipt = 'receipt',
  /**  */
  WholesaleOut = 'wholesaleOut',
  /**  */
  WholesaleBack = 'wholesaleBack',
  /**  */
  StoreUse = 'storeUse',
  /**  */
  StoreLoss = 'storeLoss',
  /**  */
  StoreGain = 'storeGain',
  /**  */
  StoreReceived = 'storeReceived',
  /**  */
  StoreTransfer = 'storeTransfer',
  /**  */
  StoreInBack = 'storeInBack',
  /**  */
  DirectBack = 'directBack',
  /**  */
  WrhLoss = 'wrhLoss',
  /**  */
  WrhGain = 'wrhGain',
  /**  */
  SellunionIn = 'sellunionIn',
  /**  */
  SellunionBack = 'sellunionBack',
  /**  */
  SupplierPurchaseOrder = 'supplierPurchaseOrder',
  /**  */
  KeyboardSettingsCash = 'keyboardSettingsCash',
  /**  */
  KeyboardSettingsPay = 'keyboardSettingsPay',
  /**  */
  PriceAdjustLaunchGoods = 'priceAdjustLaunchGoods',
  /**  */
  SellunionSettle = 'sellunionSettle',
  /**  */
  WrhWholeSaleOut = 'wrhWholeSaleOut',
  /**  */
  WrhWholeSaleBack = 'wrhWholeSaleBack',
  /**  */
  StoreAdjustPrice = 'storeAdjustPrice',
  /**  */
  OrderGoodsRecord = 'orderGoodsRecord',
  /**  */
  WrhAllocIn = 'wrhAllocIn',
  /**  */
  DistributionDiff = 'distributionDiff',
  /**  */
  ApplyBack = 'applyBack',
  /**  */
  StoreMarketIn = 'storeMarketIn',
  /**  */
  StoreMarketBack = 'storeMarketBack',
  /**  */
  StoreRecipe = 'storeRecipe',
  /**  */
  StoreDivision = 'storeDivision',
  /**  */
  PurchasePlan = 'purchasePlan',
  /**  */
  WholesaleOrder = 'wholesaleOrder',
  /**  */
  CheckFlow = 'checkFlow',
  /**  */
  WrhRecipe = 'wrhRecipe',
  /**  */
  WrhDivision = 'wrhDivision',
  /**  */
  PreCheck = 'preCheck',
  /**  */
  DirectOut = 'directOut',
  /**  */
  AllocOut = 'allocOut',
  /**  */
  AllocBack = 'allocBack',
  /**  */
  OnlineOrder = 'onlineOrder',
  /**  */
  Picking = 'picking',
  /**  */
  WrhUse = 'wrhUse',
  /**  */
  WholesaleSettle = 'wholesaleSettle',
  /**  */
  WrhAllocNoticeBackPos = 'wrhAllocNoticeBackPos',
  /**  */
  StoreWholesaleSettle = 'storeWholesaleSettle',
  /**  */
  StoreFee = 'storeFee',
  /**  */
  StoreSettle = 'storeSettle',
  /**  */
  StorePurchaseOrder = 'storePurchaseOrder',
  /**  */
  StorePay = 'storePay',
  /**  */
  StorePrepay = 'storePrepay',
  /**  */
  StoreSellUnionSettle = 'storeSellUnionSettle',
  /**  */
  StoreReceipt = 'storeReceipt'
}

/** 本地促销类型 */
export enum PromoteActivityClassEnum {
  /** 出清立折 */
  ClearDiscound = 'clearDiscound',
  /** 限时折扣 */
  TimelimitDiscound = 'timelimitDiscound',
  /** 阶梯特价 */
  StepSpecial = 'stepSpecial'
}

/** 本地促销状态 */
export enum PromoteActivityStatusEnum {
  /** 未开始 */
  Init = 'init',
  /** 进行中 */
  Doing = 'doing',
  /** 已结束 */
  Finished = 'finished'
}

export enum PromoteBillStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Submit = 'submit',
  /** 待生效 */
  Approved = 'approved',
  /** 已生效 */
  Enable = 'enable',
  /** 已作废 */
  Abort = 'abort',
  /** 已过期 */
  Expired = 'expired'
}

export enum PromoteBillTypeEnum {
  /** 价格码 */
  Promotion = 'promotion',
  /** 折扣码 */
  Discount = 'discount'
}

/** 促销渠道 */
export enum PromoteChannelEnum {
  /** POS */
  Pos = 'pos',
  /** 美团 */
  Meituan = 'meituan',
  /** 饿了么 */
  Eleme = 'eleme',
  /** 京东到家 */
  Jddj = 'jddj',
  /** 小程序 */
  Weimob = 'weimob'
}

/** 促销类型 */
export enum PromoteClassEnum {
  /** N元N件 */
  Nynj = 'nynj',
  /** 第X件X折 */
  Xjxz = 'xjxz',
  /** 限时折扣 */
  TimeLimitDiscount = 'timeLimitDiscount',
  /** 手工改价 */
  Manual = 'manual',
  /** 优惠套装 */
  Combination = 'combination',
  /** 满减满折 */
  PromFullDiscount = 'promFullDiscount',
  /** 加价购 */
  AddPrice = 'addPrice',
  /** 满赠 */
  GiftMarketing = 'giftMarketing',
  /** 会员价 */
  PromClsMember = 'promClsMember',
  /** 手工赠品 */
  PromClsManualGift = 'promClsManualGift',
  /** 整单折扣 */
  Discount = 'discount',
  /** 抹零 */
  GiveChange = 'giveChange',
  /** 促销码 */
  PromoteCode = 'promoteCode',
  /** 出清立折 */
  ClearDiscound = 'clearDiscound',
  /** 积分抵扣 */
  Score = 'score',
  /** 券抵扣 */
  Coupon = 'coupon',
  /** 阶梯特价 */
  StepSpecial = 'stepSpecial'
}

/** 本地促销条件类型 */
export enum PromoteConditionTypeEnum {
  /** 人群 */
  Person = 'person',
  /** 商品 */
  Sku = 'sku',
  /** 门店 */
  Store = 'store',
  /** 品牌 */
  Brand = 'brand',
  /** 分类 */
  Sort = 'sort'
}

/** 本地促销创建来源 */
export enum PromoteCreateSourceEnum {
  /** 总部 */
  Head = 'head',
  /** 门店 */
  Store = 'store'
}

/** 本地促销适用人群 */
export enum PromotePersonConditionEnum {
  /** 不限人群 */
  All = 'all',
  /** 指定等级会员 */
  LevelMember = 'levelMember',
  /** 指定付费会员 */
  PaidMember = 'paidMember'
}

/** 本地促销适用商品 */
export enum PromoteSkuConditionEnum {
  /** 全部门店 */
  All = 'all',
  /** 指定商品 */
  Exist = 'exist',
  /** 排除商品 */
  NotExist = 'notExist'
}

/** 本地促销商品条件类型 */
export enum PromoteSkuConditionClassEnum {
  /** 分类属于 */
  SortExist = 'sortExist',
  /** 分类不属于 */
  SortNotExist = 'sortNotExist',
  /** 品牌属于 */
  BrandExist = 'brandExist',
  /** 品牌不属于 */
  BrandNotExist = 'brandNotExist',
  /** 单品属于 */
  SkuExist = 'skuExist',
  /** 单品不属于 */
  SkuNotExist = 'skuNotExist'
}

/** 本地促销适用门店 */
export enum PromoteStoreConditionEnum {
  /** 全部门店 */
  All = 'all',
  /** 部分门店 */
  Part = 'part'
}

export enum PromotionTypeEnum {
  /** 促销码 */
  Promotion = 'promotion'
}

export enum PurchaseBackStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待退货 */
  Backing = 'backing',
  /** 已退货 */
  Backed = 'backed',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Abort = 'abort'
}

/** 采购进货单据类型 */
export enum PurchaseBillTypeEnum {
  /** 采购进货 */
  WrhPurchaseIn = 'wrhPurchaseIn',
  /** 采购退货 */
  WrhPurchaseBack = 'wrhPurchaseBack',
  /** 门店进货 */
  StorePurchaseIn = 'storePurchaseIn',
  /** 门店退货 */
  StorePurchaseBack = 'storePurchaseBack'
}

/** 采购进货业务类型 */
export enum PurchaseBusinessTypeEnum {
  /** 仓采 */
  WrhPurchase = 'wrhPurchase',
  /** 直配 */
  Alloc = 'alloc',
  /** 直送 */
  Direct = 'direct',
  /** 自采 */
  StorePurchase = 'storePurchase'
}

export enum PurchaseGoodsBackStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待退货 */
  Returning = 'returning',
  /** 已退货 */
  Sended = 'sended',
  /** 已退货 */
  Returned = 'returned',
  /** 已退货 */
  Backed = 'backed',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Invalid = 'invalid'
}

export enum PurchaseGoodsDimensionEnum {
  /** 商品 */
  Goods = 'goods',
  /** 供应商 */
  Supplier = 'supplier',
  /** 分类 */
  Sort = 'sort',
  /** 进货明细 */
  InDetail = 'inDetail',
  /** 退货明细 */
  BackDetail = 'backDetail'
}

export enum PurchaseInStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待收货 */
  Receiving = 'receiving',
  /** 已收货 */
  Received = 'received',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Abort = 'abort'
}

export enum PurchaseMonitRecStatusEnum {
  /** 已审核 */
  Approved = 'approved',
  /** 进行中 */
  Doing = 'doing',
  /** 已完成 */
  Finished = 'finished',
  /** 已关闭 */
  Closed = 'closed'
}

export enum PurchaseOrdBillClassEnum {
  /** 仓采 */
  Wrh = 'wrh',
  /** 直配 */
  Store = 'store'
}

export enum PurchaseOrdStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已审核 */
  Approved = 'approved',
  /** 进行中 */
  Doing = 'doing',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Abort = 'abort',
  /** 已关闭 */
  Closed = 'closed'
}

export enum PurchaseOrderBillClassEnum {
  /** 仓采 */
  Wrh = 'wrh',
  /** 直配 */
  Store = 'store'
}

/** 采购订货参考-按商品查询-排序条件 */
export enum PurchaseOrderByGoodsOrderByEnum {
  /** 默认排序 */
  DefaultAsc = 'defaultAsc',
  /** 销量升序 */
  SaleQtyAsc = 'saleQtyAsc',
  /** 销量降序 */
  SaleQtyDesc = 'saleQtyDesc',
  /** 销售额升序 */
  SaleTotalAsc = 'saleTotalAsc',
  /** 销售额降序 */
  SaleTotalDesc = 'saleTotalDesc',
  /** 毛利率升序 */
  ProfitRateAsc = 'profitRateAsc',
  /** 毛利率降序 */
  ProfitRateDesc = 'profitRateDesc'
}

/** 订单来源 */
export enum PurchaseOrderSourceEnum {
  /** 新增 */
  Web = 'web',
  /** 导入 */
  Import = 'import',
  /** 外部接口 */
  Api = 'api',
  /** 采购计划 */
  PurchasePlan = 'purchasePlan'
}

export enum PurchaseOrderStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已审核 */
  Approved = 'approved',
  /** 进行中 */
  Doing = 'doing',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Abort = 'abort',
  /** 已关闭 */
  Closed = 'closed'
}

/** 采购计划单查询类型 */
export enum PurchasePlanDetailQueryTypeEnum {
  /** 全部 */
  All = 'all',
  /** 未采 */
  Init = 'init',
  /** 少采 */
  Part = 'part',
  /** 多采 */
  MoreFinished = 'moreFinished',
  /** 待下单 */
  WaitSubmit = 'waitSubmit',
  /** 已下单 */
  Submitted = 'submitted'
}

export enum PurchasePlanDetailStatusEnum {
  /** 未采 */
  Init = 'init',
  /** 少采 */
  Part = 'part',
  /** 采购完成 */
  Finished = 'finished',
  /** 多采 */
  MoreFinished = 'moreFinished'
}

/** 采购计划商品异常原因 */
export enum PurchasePlanErrorReasonEnum {
  /** 采购价格为0 */
  InPriceZero = 'inPriceZero',
  /** 商品禁采 */
  GoodsNotPurchase = 'goodsNotPurchase',
  /** 供应商未合作 */
  SupplierNoCooperate = 'supplierNoCooperate',
  /** 供应商无采购协议 */
  SupplierNoAgreement = 'supplierNoAgreement',
  /** 供应商采购协议不包含此商品 */
  SupplierPurchaseAgreementNoGoods = 'supplierPurchaseAgreementNoGoods',
  /** 门店未启用 */
  StoreDisabled = 'storeDisabled',
  /** 商品不在此门店商品库内 */
  StoreNoGoods = 'storeNoGoods',
  /** 商品不在此门店所属的配送方案内 */
  DistSchemaNoGoods = 'distSchemaNoGoods'
}

export enum PurchasePlanStatusEnum {
  /** 待采购 */
  Init = 'init',
  /** 进行中 */
  OrderDoing = 'orderDoing',
  /** 已完成 */
  Finished = 'finished',
  /** 已过期 */
  Expired = 'expired'
}

/** 进货报表单据类型 */
export enum PurchaseReportBillTypeEnum {
  /** 采购进货 */
  PurchaseIn = 'purchaseIn',
  /** 采购退货 */
  PurchaseBack = 'purchaseBack',
  /** 直配收货 */
  AllocIn = 'allocIn',
  /** 直配退货 */
  AllocBack = 'allocBack',
  /** 直送收货 */
  DirectIn = 'directIn',
  /** 直送退货 */
  DirectBack = 'directBack',
  /** 自采收货 */
  StoreIn = 'storeIn',
  /** 自采退货 */
  StoreBack = 'storeBack'
}

/** 进货报表业务类型 */
export enum PurchaseReportBusinessTypeEnum {
  /** 进货 */
  In = 'in',
  /** 退货 */
  Back = 'back'
}

/** 采购进货采购类型 */
export enum PurchaseSubjectTypeEnum {
  /** 仓库采购 */
  WrhPurchase = 'wrhPurchase',
  /** 门店采购 */
  StorePurchase = 'storePurchase'
}

/** 数量范围 */
export enum QtyRangeModelEnum {
  /** 库存数量>0 */
  Positive = 'positive',
  /** 库存数量<0 */
  Negative = 'negative',
  /** 库存数量=0 */
  Zero = 'zero',
  /** 库存数量≠0 */
  Nonzero = 'nonzero',
  /** 自定义范围 */
  Install = 'install'
}

export enum ReasonTypeEnum {
  /** 退货 */
  Return = 'return',
  /** 调拨 */
  Transfer = 'transfer',
  /** 库存价调整 */
  InvPriceAdjust = 'invPriceAdjust',
  /** 促销 */
  Promote = 'promote',
  /** 报损 */
  Loss = 'loss',
  /** 报溢 */
  Gain = 'gain',
  /** 配货差异 */
  DistDiff = 'distDiff',
  /** 领用 */
  Use = 'use',
  /** 账户缴款原因 */
  JoinPay = 'joinPay',
  /** 账户扣款原因 */
  JoinDeduct = 'joinDeduct',
  /** 线上订单拒绝接单 */
  OnlineOrderRefuseOrder = 'onlineOrderRefuseOrder',
  /** 线上订单取消 */
  OnlineOrderCancel = 'onlineOrderCancel',
  /** 线上退款拒绝 */
  OnlineOrderRefuseRefund = 'onlineOrderRefuseRefund',
  /** 线上订单部分退款 */
  OnlineOrderPartRefund = 'onlineOrderPartRefund',
  /** 线上订单-全部 */
  OnlineOrder = 'onlineOrder'
}

export enum ReasonTypeRemarkEnum {
  /** 用于门店退货、退货申请以及仓库退货给供应商时的原因说明 */
  Return = 'return',
  /** 用于门店之间调拨、仓库之间调拨时的原因说明 */
  Transfer = 'transfer',
  /** 用于门店、仓库调整库存价时的原因说明 */
  InvPriceAdjust = 'invPriceAdjust',
  /** 用于商品特价促销时的原因说明 */
  Promote = 'promote',
  /** 用于门店、仓库报损时的原因说明 */
  Loss = 'loss',
  /** 用于门店、仓库报溢时的原因说明 */
  Gain = 'gain',
  /** 用于门店实收与仓库配货数产生差异时的原因说明 */
  DistDiff = 'distDiff',
  /** 用于门店、仓库领用时的原因说明 */
  Use = 'use',
  /** 用于加盟商缴款时的原因说明 */
  JoinPay = 'joinPay',
  /** 用于加盟商扣款时的原因说明 */
  JoinDeduct = 'joinDeduct'
}

export enum ReceiptBillTypeEnum {
  /** 批发单 */
  Wholesale = 'wholesale',
  /** 结算单 */
  WholesaleSettle = 'wholesaleSettle'
}

export enum ReceiptBusinessTypeEnum {
  /** 客户 */
  Customer = 'customer',
  /** 门店 */
  Store = 'store',
  /** 供应商 */
  Supplier = 'supplier'
}

export enum ReceiptStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 待收款 */
  Approved = 'approved',
  /** 已收款 */
  Receipted = 'receipted',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum ReceiptedStatusEnum {
  /** 待收款 */
  UnReceipt = 'unReceipt',
  /** 已收款 */
  Receipted = 'receipted',
  /** 部分收款 */
  PartReceipt = 'partReceipt'
}

export enum ReceptionLogStatusEnum {
  /** 全部 */
  All = 'all',
  /** POS */
  Pos = 'pos',
  /** 店务通 */
  WitPos = 'witPos'
}

export enum RechargeBillClassEnum {
  /** 充值 */
  Recharge = 'recharge',
  /** 退储值 */
  RechargeRefund = 'rechargeRefund'
}

export enum RecipeInvTypeEnum {
  /** 加工时变动库存 */
  Process = 'process',
  /** 销售时变动库存 */
  Sale = 'sale',
  /** 收货时变动库存 */
  Receive = 'receive'
}

/** 加工模版适用范围 */
export enum RecipeScopeEnum {
  /** 仓库 */
  Wrh = 'wrh',
  /** 门店 */
  Store = 'store'
}

export enum RecipeTypeEnum {
  /** 分割模板 */
  Cut = 'cut',
  /** 原料加工模板 */
  Raw = 'raw'
}

export enum RecordOrderBillClassEnum {
  /** 销售 */
  Sale = 'sale',
  /** 销售退货 */
  SaleReturn = 'saleReturn',
  /** 冲账退货 */
  OffsetReturn = 'offsetReturn'
}

export enum RegularTypeEnum {
  /** ^(13[0-9]|14[5|7]|15[0-9]|18[0-9])\d{8}$ */
  PhoneNumber = 'phoneNumber'
}

/** 补货库存 */
export enum ReplenishInvEnum {
  /** 安全库存 */
  Safe = 'safe',
  /** 最高库存 */
  Max = 'max',
  /** 3日累销 */
  ThreeSaleSum = 'threeSaleSum',
  /** 7日累销 */
  SevenSaleSum = 'sevenSaleSum',
  /** 30日累销 */
  ThirtySaleSum = 'thirtySaleSum',
  /** 配货周期销量 */
  AllocPeriodSaleSum = 'allocPeriodSaleSum'
}

/** 补货库存减数 */
export enum ReplenishInvSubtrahendEnum {
  /** 可用库存 */
  Available = 'available',
  /** 总库存 */
  Total = 'total',
  /** 可用库存+仓配在途 */
  AvailableDeliveryAlloc = 'availableDeliveryAlloc',
  /** 0 */
  None = 'none'
}

export enum ReplenishSchemaFormulaTypeEnum {
  /** （最高库存-当前库存）/ 配货规格 */
  FormulaOne = 'formulaOne',
  /** （安全库存-当前库存）/ 配货规格 */
  FormulaTwo = 'formulaTwo',
  /** （最低库存-当前库存）/ 配货规格 */
  FormulaThree = 'formulaThree'
}

export enum ReplenishSchemaGoodsEffectiveStatusEnum {
  /** 已生效 */
  Effectived = 'effectived',
  /** 未生效 */
  NoEffectived = 'noEffectived'
}

export enum ReplenishSchemaGoodsStatusEnum {
  /** 使用中 */
  Enabled = 'enabled',
  /** 已停用 */
  Disabled = 'disabled'
}

export enum ReplenishSchemaRuleTypeEnum {
  /** 低于安全库存 */
  LowerSafeStock = 'lowerSafeStock',
  /** 低于最低库存 */
  LowerLowestStock = 'lowerLowestStock',
  /** 低于排面量 */
  LowerFaceAccount = 'lowerFaceAccount'
}

export enum ReplenishSchemaSortRuleStatusEnum {
  /** 使用中 */
  Enabled = 'enabled',
  /** 已停用 */
  Disabled = 'disabled'
}

export enum ReplenishSchemaStatusEnum {
  /** 使用中 */
  Enabled = 'enabled',
  /** 已停用 */
  Disabled = 'disabled'
}

/** 补货触发配置 */
export enum ReplenishTriggerConfigEnum {
  /** 安全库存 */
  Safe = 'safe',
  /** 最高库存 */
  Max = 'max',
  /** 最低库存 */
  Min = 'min',
  /** 排面量 */
  MinDisplay = 'minDisplay',
  /** 3日累销 */
  ThreeSaleSum = 'threeSaleSum',
  /** 7日累销 */
  SevenSaleSum = 'sevenSaleSum'
}

/** 补货触发库存 */
export enum ReplenishTriggerInvEnum {
  /** 可用库存 */
  Available = 'available',
  /** 总库存 */
  Total = 'total',
  /** 可用库存+仓配在途 */
  AvailableDeliveryAlloc = 'availableDeliveryAlloc'
}

export enum ReportConfigTypeEnum {
  /** 总部-门店库存-业务库存 */
  WebStoreBusinessInv = 'webStoreBusinessInv',
  /** 总部-门店库存-财务库存 */
  WebStoreFinanceInv = 'webStoreFinanceInv',
  /** 总部-营业款查询-营业款对账（按门店） */
  BusinessFundsStore = 'businessFundsStore',
  /** 总部-营业款查询-收银缴款 */
  BusinessFundsCashier = 'businessFundsCashier',
  /** 总部-营业款查询-销售流水 */
  BusinessFundsFlow = 'businessFundsFlow',
  /** 总部-营业款查询-充值流水 */
  BusinessRechargeFlow = 'businessRechargeFlow',
  /** 总部-营业款查询-礼品卡发售流水 */
  BusinessSaleCardFlow = 'businessSaleCardFlow',
  /** 总部-营业款查询-会员付费开卡流水 */
  BusinessMemberCardFlow = 'businessMemberCardFlow',
  /** 总部-营业款查询-收款流水 */
  RevenueFlow = 'revenueFlow',
  /** 门店-营业款查询-收款流水 */
  StoreRevenueFlow = 'storeRevenueFlow',
  /** 总部-商品信息查询-基本信息查询 */
  GoodsBasicInfo = 'goodsBasicInfo',
  /** 总部-商品信息查询-商品供应商查询 */
  GoodsSupplierInfo = 'goodsSupplierInfo',
  /** 总部-商品信息查询-包装查询 */
  GoodsQpcStrInfo = 'goodsQpcStrInfo',
  /** 总部-商品信息查询-条码查询 */
  GoodsBarcodeInfo = 'goodsBarcodeInfo',
  /** 总部-商品信息查询-商品状态调整记录 */
  GoodsStateChangeInfo = 'goodsStateChangeInfo',
  /** 总部-异常商品查询-负库存商品 */
  GoodsNegativeInvInfo = 'goodsNegativeInvInfo',
  /** 总部-异常商品查询-负毛利商品 */
  GoodsNegativeProfitInfo = 'goodsNegativeProfitInfo',
  /** 总部-异常商品查询-高库龄商品 */
  GoodsOlderInvInfo = 'goodsOlderInvInfo',
  /** 总部-异常商品查询-异常锁定商品 */
  GoodsLockInv = 'goodsLockInv',
  /** 总部-异常商品查询-拣货异常商品 */
  GoodsOnlineTaskAbnormal = 'goodsOnlineTaskAbnormal',
  /** 总部-总部库存查询-业务库存 */
  WebBusinessInv = 'webBusinessInv',
  /** 总部-总部库存查询-业务库存 */
  WebFinanceInv = 'webFinanceInv',
  /** 总部-库存流水-总部库存流水 */
  WebInvFlow = 'webInvFlow',
  /** 总部-库存流水-门店库存流水 */
  WebStoreInvFlow = 'webStoreInvFlow',
  /** 总部-门店订货查询-按门店 */
  QueryOrdersByStore = 'queryOrdersByStore',
  /** 总部-门店订货查询-按大类 */
  QueryOrdersBySort = 'queryOrdersBySort',
  /** 总部-门店订货查询-按商品 */
  QueryOrdersByGoods = 'queryOrdersByGoods',
  /** 总部-门店订货查询-订货明细 */
  QueryOrdersDetail = 'queryOrdersDetail',
  /** 总部-门店配货查询 */
  OutDetailQueryForHead = 'outDetailQueryForHead',
  /** 总部-门店报损查询-按门店汇总 */
  StoreLossSumByStore = 'storeLossSumByStore',
  /** 总部-门店报损查询-按商品汇总 */
  StoreLossSumByGoods = 'storeLossSumByGoods',
  /** 总部-门店报损查询-按分类汇总 */
  StoreLossSumBySort = 'storeLossSumBySort',
  /** 总部-门店报损查询-按供应商汇总 */
  StoreLossSumBySupplier = 'storeLossSumBySupplier',
  /** 总部-门店报损查询-报损明细 */
  StoreLossDetail = 'storeLossDetail',
  /** 门店-营业款对账查询-营业收入 */
  StoreBusinessSale = 'storeBusinessSale',
  /** 门店-营业款对账查询-收银缴款 */
  StoreBusinessFundsCashier = 'storeBusinessFundsCashier',
  /** 门店-营业款对账查询-销售流水 */
  StoreBusinessFundsFlow = 'storeBusinessFundsFlow',
  /** 门店-营业款对账查询-充值流水 */
  StoreBusinessRechargeFlow = 'storeBusinessRechargeFlow',
  /** 门店-营业款对账查询-礼品卡发售流水 */
  StoreBusinessSaleCardFlow = 'storeBusinessSaleCardFlow',
  /** 门店-营业款对账查询-会员付费开卡流水 */
  StoreBusinessMemberCardFlow = 'storeBusinessMemberCardFlow',
  /** 门店-异常商品查询-负库存商品 */
  StoreGoodsNegativeInvInfo = 'storeGoodsNegativeInvInfo',
  /** 门店-异常商品查询-负毛利商品 */
  StoreGoodsNegativeProfitInfo = 'storeGoodsNegativeProfitInfo',
  /** 门店-异常商品查询-滞销商品 */
  StoreGoodsUnsoldInfo = 'storeGoodsUnsoldInfo',
  /** 门店-异常商品查询-异常锁定商品 */
  StoreGoodsLockInv = 'storeGoodsLockInv',
  /** 总部-门店商品库销售商品-信息查询 */
  StoreGoodsSaleHeadInfo = 'storeGoodsSaleHeadInfo',
  /** 总部-门店商品库库存商品-信息查询 */
  StoreGoodsInvHeadInfo = 'storeGoodsInvHeadInfo',
  /** 门店-门店商品库销售商品-信息查询 */
  StoreGoodsSaleInfo = 'storeGoodsSaleInfo',
  /** 门店-门店商品库库存商品-信息查询 */
  StoreGoodsInvInfo = 'storeGoodsInvInfo',
  /** 门店-库存查询-业务库存 */
  StoreBusinessInv = 'storeBusinessInv',
  /** 门店-库存流水查询-门店库存流水 */
  StoreInvFlow = 'storeInvFlow',
  /** 门店-订货管理-订货V2 */
  StoreRequireOrderQueryV2 = 'storeRequireOrderQueryV2',
  /** 总部-商品信息查询-换货商品查询 */
  GoodsInfoHeadExchange = 'goodsInfoHeadExchange',
  /** 门店-商品信息查询-换货商品查询 */
  GoodsInfoStoreExchange = 'goodsInfoStoreExchange',
  /** 总部-零售-员工拣货统计查询 */
  PickingEmployeeStat = 'pickingEmployeeStat',
  /** 门店-销售-员工拣货统计查询 */
  PickingEmployeeStatStore = 'pickingEmployeeStatStore',
  /** 总部-供应链查询-业务查询-门店调拨查询-按单据 */
  StoreTransferReportBill = 'storeTransferReportBill',
  /** 总部-供应链查询-业务查询-门店调拨查询-按明细 */
  StoreTransferReportDetail = 'storeTransferReportDetail',
  /** 总部-店务-订单管理-POS订单 */
  PosOrder = 'posOrder',
  /** 总部-供应链-财务报表-进销毛利报表 */
  JxMargin = 'jxMargin',
  /** 门店-店务-查询报表-进销毛利报表 */
  StoreJxMargin = 'storeJxMargin',
  /** 总部-供应链-仓库库存查询 */
  InvQueryWrh = 'invQueryWrh',
  /** 总部-供应链-门店库存查询 */
  InvQueryStore = 'invQueryStore',
  /** 总部-供应链-批次库存查询 */
  InvQueryBatch = 'invQueryBatch',
  /** 总部-供应链-效期库存查询 */
  InvQueryValidDate = 'invQueryValidDate',
  /** 门店-店务-效期库存查询 */
  StoreInvQueryValidDate = 'storeInvQueryValidDate',
  /** 总部-店务-查询报表-销售日报报表（按门店） */
  DrptStore = 'drptStore',
  /** 门店-店务-查询报表-销售日报报表（按门店） */
  StoreDrptStore = 'storeDrptStore',
  /** 总部-店务-查询报表-销售日报报表（按类别） */
  DrptSort = 'drptSort',
  /** 门店-店务-查询报表-销售日报报表（按类别） */
  StoreDrptSort = 'storeDrptSort',
  /** 总部-店务-查询报表-销售日报报表（按商品） */
  DrptSku = 'drptSku',
  /** 门店-店务-查询报表-销售日报报表（按商品） */
  StoreDrptSku = 'storeDrptSku',
  /** 总部-供应链-进销存报表-门店进销存日报（按门店） */
  JxcDailyStore = 'jxcDailyStore',
  /** 总部-供应链-进销存报表-门店进销存日报（按类别） */
  JxcDailyStoreSort = 'jxcDailyStoreSort',
  /** 总部-供应链-进销存报表-门店进销存日报（按单品） */
  JxcDailyStoreGoods = 'jxcDailyStoreGoods',
  /** 总部-供应链-进销存报表-门店进销存月报（按门店） */
  JxcMonthStore = 'jxcMonthStore',
  /** 总部-供应链-进销存报表-门店进销存月报（按类别） */
  JxcMonthStoreSort = 'jxcMonthStoreSort',
  /** 总部-供应链-进销存报表-门店进销存月报（按单品） */
  JxcMonthStoreGoods = 'jxcMonthStoreGoods',
  /** 总部-供应链-进销存报表-门店进销存汇总（按门店） */
  JxcSumStore = 'jxcSumStore',
  /** 总部-供应链-进销存报表-门店进销存汇总（按类别） */
  JxcSumStoreSort = 'jxcSumStoreSort',
  /** 总部-供应链-进销存报表-门店进销存汇总（按单品） */
  JxcSumStoreGoods = 'jxcSumStoreGoods',
  /** 总部-供应链-进销存报表-总部进销存日报（按仓库） */
  JxcDailyWrh = 'jxcDailyWrh',
  /** 总部-供应链-进销存报表-总部进销存日报（按类别） */
  JxcDailyWrhSort = 'jxcDailyWrhSort',
  /** 总部-供应链-进销存报表-总部进销存日报（按单品） */
  JxcDailyWrhGoods = 'jxcDailyWrhGoods',
  /** 总部-供应链-进销存报表-总部进销存月报（按仓库） */
  JxcMonthWrh = 'jxcMonthWrh',
  /** 总部-供应链-进销存报表-总部进销存月报（按类别） */
  JxcMonthWrhSort = 'jxcMonthWrhSort',
  /** 总部-供应链-进销存报表-总部进销存月报（按单品） */
  JxcMonthWrhGoods = 'jxcMonthWrhGoods',
  /** 总部-供应链-进销存报表-总部进销存汇总（按仓库） */
  JxcSumWrh = 'jxcSumWrh',
  /** 总部-供应链-进销存报表-总部进销存汇总（按类别） */
  JxcSumWrhSort = 'jxcSumWrhSort',
  /** 总部-供应链-进销存报表-总部进销存汇总（按单品） */
  JxcSumWrhGoods = 'jxcSumWrhGoods',
  /** 总部-店务-查询报表-商品销售财务报表（按类别） */
  DrptFinanceSort = 'drptFinanceSort',
  /** 总部-店务-查询报表-商品销售财务报表（按单品） */
  DrptFinanceSku = 'drptFinanceSku',
  /** 总部-店务-查询报表-商品销售财务报表（按门店） */
  DrptFinanceStore = 'drptFinanceStore',
  /** 总部-店务-礼品查询管理 */
  PrePayCard = 'prePayCard',
  /** 异常商品查询-不动销商品查询 */
  AbnormalNoSaleGoods = 'abnormalNoSaleGoods',
  /** 异常商品查询-不动销商品查询 */
  StoreAbnormalNoSaleGoods = 'storeAbnormalNoSaleGoods',
  /** 总部-促销-促销明细 */
  PromtionSku = 'promtionSku',
  /** 总部-促销-促销明细 */
  StorePromtionSku = 'storePromtionSku',
  /** 总部-促销-促销商品 */
  DrptPromtionSku = 'drptPromtionSku',
  /** 门店-促销-促销商品 */
  StoreDrptPromtionSku = 'storeDrptPromtionSku',
  /** 供应链-业务查询-采购订货参考 */
  PurchaseOrderReference = 'purchaseOrderReference',
  /** 门店配货查询 */
  StoreAllocOut = 'storeAllocOut',
  /** 门店退货查询 */
  StoreAllocBack = 'storeAllocBack',
  /** 总部-店务-查询报表-门店销售统计（按商品） */
  DrptSale = 'drptSale',
  /** 门店-店务-查询报表-门店销售统计（按商品） */
  StoreDrptSale = 'storeDrptSale',
  /** 总部-店务-查询报表-门店销售统计（按类别） */
  DrptSaleSort = 'drptSaleSort',
  /** 门店-店务-查询报表-门店销售统计（按类别） */
  StoreDrptSaleSort = 'storeDrptSaleSort',
  /** 总部-店务-查询报表-门店销售统计（按门店） */
  DrptSaleStore = 'drptSaleStore',
  /** 总部-店务-查询报表-盘点盈亏报表（汇总） */
  CheckInWrh = 'checkInWrh',
  /** 总部-店务-查询报表-盘点盈亏报表（明细） */
  CheckInDetail = 'checkInDetail',
  /** 总部-店务-查询报表-调拨报表（汇总） */
  TransferWrh = 'transferWrh',
  /** 总部-店务-查询报表-调拨报表（明细） */
  TransferDetail = 'transferDetail',
  /** 总部-供应商补差明细 */
  SupplierSubdiffReportDetail = 'supplierSubdiffReportDetail',
  /** 总部-损溢汇总 */
  LossGainReport = 'lossGainReport',
  /** 总部-损溢明细 */
  LossGainReportDetail = 'lossGainReportDetail',
  /** 总部-供应商台账汇总 */
  SupplierSbSum = 'supplierSbSum',
  /** 总部-供应商台账应付明细 */
  SupplierSbShouldPayDetail = 'supplierSbShouldPayDetail',
  /** 总部-供应商台账预付明细 */
  SupplierSbPrepayDetail = 'supplierSbPrepayDetail',
  /** 总部-供应商台账预付费用明细 */
  SupplierSbFeeDetail = 'supplierSbFeeDetail',
  /** 分拣管理-拣货查询报表 */
  PickingReport = 'pickingReport',
  /** 促销价查询报表-按商品 */
  PromoteSkuReport = 'promoteSkuReport',
  /** 促销价查询报表-按门店 */
  PromoteStoreReport = 'promoteStoreReport',
  /** 总部-进货报表-按供应商 */
  PurchaseSupplier = 'purchaseSupplier',
  /** 总部-进货报表-按收货组织 */
  PurchaseReceiver = 'purchaseReceiver',
  /** 总部-进货报表-按商品 */
  PurchaseSku = 'purchaseSku',
  /** 总部-进货报表-进货明细 */
  PurchaseDetail = 'purchaseDetail',
  /** 总部-进货报表-按门店 */
  PurchaseStore = 'purchaseStore',
  /** 授权管理-折扣授权管理 */
  DiscountFlow = 'discountFlow',
  /** WMS库存差异报表导出 */
  WmsInvCheck = 'wmsInvCheck',
  /** 总部-领用报表-领用汇总 */
  UseReportSum = 'useReportSum',
  /** 总部-领用报表-领用明细 */
  UseReportDetail = 'useReportDetail',
  /** 总部-财务-财务配货报表-按门店汇总 */
  DistInoutFlowStore = 'distInoutFlowStore',
  /** 总部-财务-财务配货报表-按门店商品汇总 */
  DistInoutFlowGoods = 'distInoutFlowGoods',
  /** 总部-财务-财务配货报表-配货明细 */
  DistInoutFlowAlloc = 'distInoutFlowAlloc',
  /** 总部-财务-财务配货报表-退货明细 */
  DistInoutFlowBack = 'distInoutFlowBack',
  /** 储值流水 */
  MemberBalanceFlowWork = 'memberBalanceFlowWork'
}

export enum ReportCptsEnum {
  /** CX打印模板WOS新/tenant05/采购订单.cpt */
  PurchaseOrder = 'purchaseOrder',
  /** CX打印模板WOS新/tenant05/采购进货单.cpt */
  PurchaseIn = 'purchaseIn',
  /** CX打印模板WOS新/tenant05/采购退货单.cpt */
  PurchaseBack = 'purchaseBack',
  /** CX打印模板WOS新/tenant05/门店进货单.cpt */
  StoreIn = 'storeIn',
  /** CX打印模板WOS新/tenant05/门店退货单.cpt */
  StoreReturn = 'storeReturn',
  /** CX打印模板WOS新/tenant05/配货单.cpt */
  WrhAlloc = 'wrhAlloc',
  /** CX打印模板WOS新/tenant05/拣货单.cpt */
  WrhPick = 'wrhPick',
  /** CX打印模板WOS新/tenant05/门店退仓单.cpt */
  WrhAllocBack = 'wrhAllocBack',
  /** CX打印模板WOS新/tenant05/付款单.cpt */
  Pay = 'pay',
  /** CX打印模板WOS新/tenant05/费用单.cpt */
  Fee = 'fee',
  /** CX打印模板WOS新/tenant05/预付款单.cpt */
  Prepay = 'prepay',
  /** CX打印模板WOS新/tenant05/对账单.cpt */
  Settle = 'settle',
  /** CX打印模板WOS新/tenant05/收款单.cpt */
  Receipt = 'receipt',
  /** CX打印模板WOS新/tenant05/门店批发单.cpt */
  WholesaleOut = 'wholesaleOut',
  /** CX打印模板WOS新/tenant05/门店批发退货单.cpt */
  WholesaleBack = 'wholesaleBack',
  /** CX打印模板WOS新/tenant05/门店报损单.cpt */
  StoreLoss = 'storeLoss',
  /** CX打印模板WOS新/tenant05/门店报溢单.cpt */
  StoreGain = 'storeGain',
  /** CX打印模板WOS新/tenant05/门店收货单.cpt */
  StoreReceived = 'storeReceived',
  /** CX打印模板WOS新/tenant05/门店调拨单.cpt */
  StoreTransfer = 'storeTransfer',
  /** CX打印模板WOS新/tenant05/门店领用单.cpt */
  StoreUse = 'storeUse',
  /** CX打印模板WOS新/tenant05/自采退货单.cpt */
  StoreInBack = 'storeInBack',
  /** CX打印模板WOS新/tenant05/直送退货单.cpt */
  DirectBack = 'directBack',
  /** CX打印模板WOS新/tenant05/仓库报损单.cpt */
  WrhLoss = 'wrhLoss',
  /** CX打印模板WOS新/tenant05/仓库报溢单.cpt */
  WrhGain = 'wrhGain',
  /** CX打印模板WOS新/tenant05/联营进货单.cpt */
  SellunionIn = 'sellunionIn',
  /** CX打印模板WOS新/tenant05/联营退货单.cpt */
  SellunionBack = 'sellunionBack',
  /** CX打印模板WOS新/tenant05/采购订单_供应商平台.cpt */
  SupplierPurchaseOrder = 'supplierPurchaseOrder',
  /** CX打印模板WOS新/tenant05/键盘设置_收银界面.cpt */
  KeyboardSettingsCash = 'keyboardSettingsCash',
  /** CX打印模板WOS新/tenant05/键盘设置_付款界面.cpt */
  KeyboardSettingsPay = 'keyboardSettingsPay',
  /** CX打印模板WOS新/tenant05/商品调价报表.cpt */
  PriceAdjustLaunchGoods = 'priceAdjustLaunchGoods',
  /** CX打印模板WOS新/tenant05/联营结算单.cpt */
  SellunionSettle = 'sellunionSettle',
  /** CX打印模板WOS新/tenant05/仓库批发单.cpt */
  WrhWholeSaleOut = 'wrhWholeSaleOut',
  /** CX打印模板WOS新/tenant05/仓库批发退货单.cpt */
  WrhWholeSaleBack = 'wrhWholeSaleBack',
  /** CX打印模板WOS新/tenant05/门店改价单.cpt */
  StoreAdjustPrice = 'storeAdjustPrice',
  /** CX打印模板WOS新/tenant05/订货记录.cpt */
  OrderGoodsRecord = 'orderGoodsRecord',
  /** CX打印模板WOS新/tenant05/仓配收货单.cpt */
  WrhAllocIn = 'wrhAllocIn',
  /** CX打印模板WOS新/tenant05/收货差异单.cpt */
  DistributionDiff = 'distributionDiff',
  /** CX打印模板WOS新/tenant05/退货申请.cpt */
  ApplyBack = 'applyBack',
  /** CX打印模板WOS新/tenant05/自采入库单.cpt */
  StoreMarketIn = 'storeMarketIn',
  /** CX打印模板WOS新/tenant05/自采退货单.cpt */
  StoreMarketBack = 'storeMarketBack',
  /** CX打印模板WOS新/tenant05/门店加工单.cpt */
  StoreRecipe = 'storeRecipe',
  /** CX打印模板WOS新/tenant05/门店分割单.cpt */
  StoreDivision = 'storeDivision',
  /** CX打印模板WOS新/tenant05/采购计划单.cpt */
  PurchasePlan = 'purchasePlan',
  /** CX打印模板WOS新/tenant05/配货订单.cpt */
  WrhAllocOrder = 'wrhAllocOrder',
  /** CX打印模板WOS新/tenant05/批发订单.cpt */
  WholesaleOrder = 'wholesaleOrder',
  /** CX打印模板WOS新/tenant05/盘点流水单.cpt */
  CheckFlow = 'checkFlow',
  /** CX打印模板WOS新/tenant05/仓库加工单.cpt */
  WrhRecipe = 'wrhRecipe',
  /** CX打印模板WOS新/tenant05/仓库分割单.cpt */
  WrhDivision = 'wrhDivision',
  /** CX打印模板WOS新/tenant05/预盘单.cpt */
  PreCheck = 'preCheck',
  /** CX打印模板WOS新/tenant05/生鲜分拣拣货单.cpt */
  Picking = 'picking',
  /** CX打印模板WOS新/tenant05/4000241429850/仓库领用单.cpt */
  WrhUse = 'wrhUse',
  /** CX打印模板WOS新/tenant05/批发结算单.cpt */
  WholesaleSettle = 'wholesaleSettle',
  /** CX打印模板WOS新/tenant05/门店批发结算单.cpt */
  StoreWholesaleSettle = 'storeWholesaleSettle',
  /** CX打印模板WOS新/tenant05/门店经销结算单.cpt */
  StoreSettle = 'storeSettle',
  /** CX打印模板WOS新/tenant05/门店费用单.cpt */
  StoreFee = 'storeFee',
  /** CX打印模板WOS新/tenant05/门店采购订单.cpt */
  StorePurchaseOrder = 'storePurchaseOrder',
  /** CX打印模板WOS新/tenant05/门店付款单.cpt */
  StorePay = 'storePay',
  /** CX打印模板WOS新/tenant05/门店预付款单.cpt */
  StorePrepay = 'storePrepay',
  /** CX打印模板WOS新/tenant05/门店收款单.cpt */
  StoreReceipt = 'storeReceipt',
  /** CX打印模板WOS新/tenant05/门店联营结算单.cpt */
  StoreSellUnionSettle = 'storeSellUnionSettle'
}

/** POS单据打印模板 */
export enum ReportPosCptsEnum {
  /** CX打印模板WOS新/tenant05/直送收货单_pos.cpt */
  DirectOut = 'directOut',
  /** CX打印模板WOS新/tenant05/直送退货单_pos.cpt */
  DirectBack = 'directBack',
  /** CX打印模板WOS新/tenant05/直配收货单_pos.cpt */
  AllocOut = 'allocOut',
  /** CX打印模板WOS新/tenant05/直配退货单_pos.cpt */
  AllocBack = 'allocBack',
  /** CX打印模板WOS新/tenant05/门店退仓单_pos.cpt */
  WrhAllocBack = 'wrhAllocBack',
  /** CX打印模板WOS新/tenant05/门店调拨单_pos.cpt */
  StoreTransfer = 'storeTransfer',
  /** CX打印模板WOS新/tenant05/4000241429850/线上订单_pos.cpt */
  OnlineOrder = 'onlineOrder',
  /** CX打印模板WOS新/tenant05/门店退仓通知单_pos.cpt */
  WrhAllocNoticeBackPos = 'wrhAllocNoticeBackPos'
}

export enum RequireOrderStatusEnum {
  /** 待处理 */
  Init = 'init',
  /** 已处理 */
  Finished = 'finished'
}

export enum RequireQueryOrdersDimensionEnum {
  /** 门店 */
  Store = 'store',
  /** 分类 */
  Sort = 'sort',
  /** 商品 */
  Goods = 'goods',
  /** 明细 */
  Detail = 'detail'
}

export enum RetailPriceAdjustStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待生效 */
  Launching = 'launching',
  /** 已生效 */
  Launched = 'launched'
}

/** 原价规则枚举 */
export enum RetailPriceRuleEnum {
  /** 门店零售价 */
  StoreRetailPrice = 'storeRetailPrice',
  /** 秤零售价 */
  WeighRetailPrice = 'weighRetailPrice'
}

export enum ReturnNotifyLimitTypeEnum {
  /** 支持全退 */
  All = 'all',
  /** 自定义 */
  Assign = 'assign'
}

export enum ReturnNotifyStoreScopeEnum {
  /** 全部门店 */
  All = 'all',
  /** 部分门店 */
  Assign = 'assign'
}

export enum ReturnOrderTypeEnum {
  /** 仓配退货 */
  Wrh = 'wrh',
  /** 门店退仓通知 */
  WrhNotice = 'wrhNotice',
  /** 直配退货 */
  Alloc = 'alloc'
}

export enum ReturnReasonEnum {
  /** 破损 */
  UnKnowWord8079ca0f = '破损',
  /** 不合格 */
  UnKnowWord6e5a1220 = '不合格',
  /** 临期 */
  UnKnowWord2cae0566 = '临期',
  /** 总部通知退货 */
  UnKnowWorde223b834 = '总部通知退货',
  /** 清场 */
  UnKnowWord6b68b505 = '清场',
  /** 退货原因名称恶霸霸霸霸霸 */
  UnKnowWord0dc9adc3 = '退货原因名称恶霸霸霸霸霸',
  /** 测试退货 */
  UnKnowWordfb410a51 = '测试退货',
  /** 质量问题 */
  UnKnowWordc440cdf7 = '质量问题'
}

export enum RevenueFlowBillClassTypeEnum {
  /** 销售 */
  Sale = 'sale',
  /** 销售退货 */
  SaleReturn = 'saleReturn',
  /** 冲账退货 */
  OffsetReturn = 'offsetReturn',
  /** 储值充值 */
  Recharge = 'recharge',
  /** 退储值 */
  RechargeRefund = 'rechargeRefund',
  /** 礼品卡销售 */
  SaleCard = 'saleCard',
  /** 付费会员开卡 */
  MemberCard = 'memberCard'
}

export enum RolePricePermEnumEnum {
  /** 进价 */
  UnKnowWordb16a59e8 = 'wosheading#cx.local.role.pric#inPrice',
  /** 成本价 */
  UnKnowWordaeb9c3b1 = 'wosheading#cx.local.role.pric#costPrice'
}

export enum RoleValidateFieldEnum {
  /** 角色编号 */
  RoleId = 'roleId',
  /** 角色名称 */
  RoleName = 'roleName',
  /** 分组编号 */
  GroupId = 'groupId',
  /** 分组名称 */
  GroupName = 'groupName'
}

/** 扣率生效历史类型 */
export enum RuleGoodsLogTypeEnum {
  /** 常规扣率 */
  Normal = 'normal',
  /** 特殊扣率 */
  Special = 'special'
}

export enum SaleBillClassEnum {
  /** 门店批发单 */
  WholeSaleOut = 'wholeSaleOut',
  /** 仓库批发单 */
  WrhWholeSaleOut = 'wrhWholeSaleOut',
  /** 门店批发退货单 */
  WholeSaleBack = 'wholeSaleBack',
  /** 仓库批发退货单 */
  WrhWholeSaleBack = 'wrhWholeSaleBack'
}

/** 销售商品加工排序枚举 */
export enum SaleDrptOrderEnum {
  /** 默认排序 */
  DefaultAsc = 'defaultAsc',
  /** 销量降序 */
  AllQtyDesc = 'allQtyDesc',
  /** 销量升序 */
  AllQtyAsc = 'allQtyAsc',
  /** 销售额降序 */
  AllTotalDesc = 'allTotalDesc',
  /** 销售额升序 */
  AllTotalAsc = 'allTotalAsc'
}

export enum SaleMethodEnum {
  /** 零售 */
  Retail = 'retail',
  /** 批发 */
  Whole = 'whole'
}

/** 锁定现金类枚举 */
export enum SalePayTypeEnum {
  /** 鼎付通 */
  HdPay = 'hdPay',
  /** 积分 */
  Score = 'score',
  /** 储值 */
  Balance = 'balance',
  /** 礼品卡 */
  Card = 'card',
  /** 电子礼品卡 */
  ElecGiftCard = 'elecGiftCard',
  /** 券支付 */
  Coupon = 'coupon',
  /** 杉德云POS */
  SandPay = 'sandPay',
  /** 桂林银行刷卡 */
  GlCardPay = 'glCardPay',
  /** 桂林银行积分 */
  GlScorePay = 'glScorePay',
  /** 提货券 */
  TakeGoodsCoupon = 'takeGoodsCoupon'
}

export enum SaleTypeEnum {
  /** 现货 */
  Instock = 'instock',
  /** 预售 */
  Presale = 'presale'
}

/** 商品搜索关键字类型 */
export enum SearchGoodsTypeEnum {
  /** 商品名称 */
  Name = 'name',
  /** 商品码 */
  Id = 'id'
}

export enum SecScreenCycleModeTypeEnum {
  /** 全部 */
  All = 'all',
  /** 每周 */
  Week = 'week',
  /** 每月 */
  Month = 'month'
}

export enum SecScreenDeviceTypeEnum {
  /** 收银机 */
  Pos = 'pos'
}

export enum SecScreenEffectStatusEnum {
  /** 生效中 */
  Effecting = 'effecting',
  /** 待生效 */
  NoEffect = 'noEffect',
  /** 已失效 */
  Effected = 'effected'
}

export enum SecScreenFormatStatusEnum {
  /** image/jpg */
  Jpg = 'JPG',
  /** image/png */
  Png = 'PNG',
  /** video/avi */
  Avi = 'AVI',
  /** video/mp4 */
  Mp4 = 'MP4',
  /** video/rmvb */
  Rmvb = 'RMVB'
}

export enum SecScreenMediaTypeEnum {
  /** 视频 */
  Video = 'video',
  /** 图片 */
  Image = 'image'
}

export enum SecScreenStoreScopeStatusEnum {
  /** 全部门店 */
  All = 'all',
  /** 无 */
  None = 'none',
  /** 指定门店 */
  Assign = 'assign'
}

export enum SellUnionAccountByDetailBillTypeEnum {
  /** 销售 */
  Sale = 'sale',
  /** 销售退货 */
  SaleReturn = 'saleReturn',
  /** 批发销售 */
  WholeSaleOut = 'wholeSaleOut',
  /** 批发退货 */
  WholeSaleBack = 'wholeSaleBack'
}

export enum SellUnionAccountByGoodsBillTypeEnum {
  /** 销售 */
  RetailOut = 'retailOut',
  /** 销售退货 */
  RetailBack = 'retailBack',
  /** 批发销售 */
  SaleOut = 'saleOut',
  /** 批发退货 */
  SaleBack = 'saleBack'
}

export enum SellbuyBillTypeEnum {
  /** 采购进货单 */
  PurchaseIn = 'purchaseIn',
  /** 采购退货单 */
  PurchaseBack = 'purchaseBack',
  /** 门店进货单(直送) */
  DirectIn = 'directIn',
  /** 门店进货单(直配) */
  AllocIn = 'allocIn',
  /** 门店退货单(直送) */
  DirectBack = 'directBack',
  /** 门店退货单(直配) */
  AllocBack = 'allocBack',
  /** 费用单 */
  Fee = 'fee'
}

export enum SellbuySettleGoodsBillClassEnum {
  /** 门店进货单(直送) */
  DirectIn = 'directIn',
  /** 门店进货单(直配) */
  AllocIn = 'allocIn',
  /** 门店退货单(直送) */
  DirectBack = 'directBack',
  /** 门店退货单(直配) */
  AllocBack = 'allocBack',
  /** 采购进货单 */
  PurchaseIn = 'purchaseIn',
  /** 采购退货单 */
  PurchaseBack = 'purchaseBack'
}

export enum SellbuySettleInvoiceTypeEnum {
  /** 普通发票 */
  General = 'general',
  /** 小发票 */
  Small = 'small'
}

export enum SellbuySettlePayStatusEnum {
  /** 待付款 */
  UnPay = 'unPay',
  /** 已付款 */
  Payed = 'payed',
  /** 部分付款 */
  PartPay = 'partPay'
}

export enum SellbuySettleStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已审核 */
  Approved = 'approved',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum SellunionAgreementSettleModeEnum {
  /** 自然月末结算 */
  Month = 'month',
  /** 指定周期结算 */
  Custom = 'custom'
}

export enum SellunionAgreementStatusEnum {
  /** 未生效 */
  Init = 'init',
  /** 生效中 */
  Effected = 'effected',
  /** 已失效 */
  Expired = 'expired',
  /** 已终止 */
  Terminated = 'terminated'
}

export enum SellunionAgreementStoreScopeEnum {
  /** 全部门店 */
  All = 'all',
  /** 部分门店 */
  Assign = 'assign'
}

export enum SellunionBackStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已完成 */
  Finished = 'finished'
}

export enum SellunionInStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已完成 */
  Finished = 'finished'
}

export enum ServiceOrderChannlTypeEnum {
  /** POS */
  Pos = 'pos',
  /** 小程序 */
  WeimobOnline = 'weimobOnline'
}

export enum ServiceOrderRevenueChannlTypeEnum {
  /** POS */
  Pos = 'pos',
  /** 小程序 */
  Weimob = 'weimob'
}

export enum ServiceOrderTypeEnum {
  /** 储值充值 */
  Recharge = 'recharge',
  /** 退储值 */
  RechargeRefund = 'rechargeRefund',
  /** 礼品卡销售 */
  SaleCard = 'saleCard'
}

export enum SettleBatchStatusEnum {
  /** 待开始 */
  Init = 'init',
  /** 进行中 */
  Doing = 'doing',
  /** 已完成 */
  Finished = 'finished',
  /** 失败 */
  Failed = 'failed'
}

export enum SettleNoEnum {
  /** 202305 */
  Bill = 'bill'
}

export enum SortClassEnum {
  /** 大类 */
  Big = 'big',
  /** 中类 */
  Medium = 'medium',
  /** 小类 */
  Small = 'small',
  /** 无前缀 */
  None = 'none'
}

/** 分类维度 */
export enum SortDimensionEnum {
  /** 按大类统计 */
  Big = 'big',
  /** 按中类统计 */
  Medium = 'medium',
  /** 按小类统计 */
  Small = 'small'
}

export enum StandingBookBillTypeEnum {
  /** 采购进货单 */
  PurchaseIn = 'purchaseIn',
  /** 采购退货单 */
  PurchaseBack = 'purchaseBack',
  /** 门店进货单(直送) */
  DirectIn = 'directIn',
  /** 门店进货单(直配) */
  AllocIn = 'allocIn',
  /** 门店退货单(直送) */
  DirectBack = 'directBack',
  /** 门店退货单(直配) */
  AllocBack = 'allocBack',
  /** 费用单 */
  Fee = 'fee',
  /** 结算单 */
  Union = 'union',
  /** 预付款单 */
  Prepay = 'prepay'
}

export enum StockEnum {
  /** 负库存 */
  Negative = 'negative',
  /** 零库存 */
  Zero = 'zero',
  /** 低库存 */
  Low = 'low'
}

export enum StoreBackStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已退货 */
  Returned = 'returned',
  /** 已作废 */
  Invalid = 'invalid'
}

/** 门店退货状态枚举 */
export enum StoreBackViewStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待退货 */
  Returning = 'returning',
  /** 已退货 */
  Returned = 'returned',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Invalid = 'invalid'
}

export enum StoreCustomerAccountBillTypeEnum {
  /** 门店批发单 */
  WholeSaleOut = 'wholeSaleOut',
  /** 门店批发退货单 */
  WholeSaleBack = 'wholeSaleBack'
}

export enum StoreDirectStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待收货 */
  Verified = 'verified',
  /** 已收货 */
  Received = 'received',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Abolished = 'abolished'
}

export enum StoreGainReasonEnum {
  /** 盘盈 */
  UnKnowWordfc27833c = '盘盈',
  /** 出清 */
  UnKnowWord0b9ad0cd = '出清',
  /** 分拣出库 */
  UnKnowWord3913cbf2 = '分拣出库',
  /** 报溢原因名称恶霸霸霸霸霸 */
  UnKnowWord167655de = '报溢原因名称恶霸霸霸霸霸',
  /** 返工 */
  UnKnowWord98e01c83 = '返工'
}

export enum StoreGainStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已同意 */
  Approved = 'approved',
  /** 已拒绝 */
  Rejected = 'rejected'
}

export enum StoreInSaveTargetStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待收货 */
  Verified = 'verified',
  /** 已收货 */
  Received = 'received',
  /** 已作废 */
  Abolished = 'abolished'
}

export enum StoreInStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已审核 */
  Verified = 'verified',
  /** 已收货 */
  Received = 'received',
  /** 已作废 */
  Abolished = 'abolished',
  /** 已完成 */
  Finished = 'finished'
}

export enum StoreInTypeEnum {
  /** 仓配订单 */
  Wrh = 'wrh',
  /** 直送订单 */
  Direct = 'direct',
  /** 直配订单 */
  Alloc = 'alloc',
  /** 市采入库 */
  Market = 'market'
}

export enum StoreIncomeStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已提交 */
  Submited = 'submited'
}

export enum StoreLevelEnum {
  /** A */
  A = 'A',
  /** B */
  B = 'B',
  /** C */
  C = 'C'
}

export enum StoreLockStatusEnum {
  /** 已冻结 */
  Locked = 'locked',
  /** 未冻结 */
  Unlock = 'unlock'
}

export enum StoreLossReasonEnum {
  /** 损坏 */
  UnKnowWord6ef780bf = '损坏',
  /** 过期 */
  UnKnowWord584cd195 = '过期',
  /** 丢失 */
  UnKnowWord162e219f = '丢失',
  /** 出清 */
  UnKnowWord0b9ad0cd = '出清',
  /** 线上已发货订单仅退款 */
  UnKnowWordfaee5eb5 = '线上已发货订单仅退款',
  /** 销售损耗 */
  UnKnowWord4e79ed5b = '销售损耗',
  /** 报损原因名称恶霸霸霸霸霸 */
  UnKnowWordcf142c3a = '报损原因名称恶霸霸霸霸霸',
  /** 报损的原因名字非常非常长 */
  UnKnowWord35013d2e = '报损的原因名字非常非常长',
  /** ces */
  Ces = 'ces',
  /** 送礼 */
  UnKnowWordfd735e8a = '送礼',
  /** 试吃 */
  UnKnowWord6a16f102 = '试吃'
}

export enum StoreLossStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已同意 */
  Approved = 'approved',
  /** 已拒绝 */
  Rejected = 'rejected'
}

export enum StoreMarketStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已收货 */
  Received = 'received',
  /** 已作废 */
  Abolished = 'abolished'
}

export enum StorePaymentSettingsEnum {
  /** 全部门店明缴 */
  AllLight = 'allLight',
  /** 全部门店暗缴 */
  AllDark = 'allDark',
  /** 部分门店暗缴（未选择的门店明缴） */
  PartDark = 'partDark'
}

export enum StorePaymentTypeEnum {
  /** 按日缴款 */
  ByDay = 'byDay',
  /** 按班次缴款 */
  ByShift = 'byShift',
  /** 不缴款 */
  NoPayment = 'noPayment'
}

/** 拣货售罄后生成报损报溢单的状态 */
export enum StorePickingOutOrderStatusEnum {
  /** 待审核 */
  Applyed = 'applyed',
  /** 已审核 */
  Approved = 'approved'
}

export enum StoreProcessStatusEnum {
  /** 待审核 */
  Init = 'init',
  /** 已审核 */
  Approved = 'approved'
}

/** 门店补货状态 */
export enum StoreReplenishStatusEnum {
  /** 启用 */
  Enabled = 'enabled',
  /** 停用 */
  Disabled = 'disabled'
}

/** 退货单历史单据状态 */
export enum StoreReturnHisStatusEnum {
  /** 待收货 */
  Sended = 'sended',
  /** 已收货 */
  Returned = 'returned',
  /** 已完成 */
  Finished = 'finished'
}

export enum StoreReturnNotifyStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已生效 */
  Launched = 'launched',
  /** 已过期 */
  Expired = 'expired'
}

export enum StoreReturnSourceEnum {
  /** 总部通知退货 */
  HqNotify = 'hqNotify',
  /** 门店主动申请 */
  StoreActive = 'storeActive'
}

export enum StoreReturnStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已同意 */
  Approved = 'approved',
  /** 已拒绝 */
  Rejected = 'rejected'
}

export enum StoreStatusEnum {
  /** 使用中 */
  Enabled = 'enabled',
  /** 停用 */
  Disabled = 'disabled'
}

export enum StoreTransferStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已发货 */
  Sended = 'sended',
  /** 待收货 */
  Receiving = 'receiving',
  /** 已收货 */
  Received = 'received',
  /** 已作废 */
  Abolished = 'abolished'
}

export enum StoreTypeEnum {
  /** 直营 */
  Direct = 'direct',
  /** 加盟 */
  Join = 'join'
}

export enum StoreUseStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已同意 */
  Agreed = 'agreed',
  /** 已拒绝 */
  Rejected = 'rejected'
}

/** 补差协议提单方式类型 */
export enum SubdiffAgreementLadingModeEnum {
  /** 按日生成 */
  Day = 'day',
  /** 按结转日生成 */
  Settle = 'settle',
  /** 按截止日期生成 */
  End = 'end'
}

/** 补差协议状态 */
export enum SubdiffAgreementStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Submited = 'submited',
  /** 已审核 */
  Approved = 'approved',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Aborted = 'aborted'
}

/** 补差单来源 */
export enum SubdiffSourceEnum {
  /** 协议生成 */
  Agreement = 'agreement',
  /** 导入 */
  Import = 'import',
  /** 手工创建 */
  Manual = 'manual'
}

/** 补差单状态 */
export enum SubdiffStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Submited = 'submited',
  /** 已审核 */
  Approved = 'approved',
  /** 已作废 */
  Aborted = 'aborted',
  /** 已完成 */
  Finished = 'finished'
}

export enum SupplierAgreementEffectStatusEnum {
  /** 未生效 */
  Init = 'init',
  /** 已生效 */
  Effected = 'effected',
  /** 已终止 */
  Terminated = 'terminated',
  /** 已失效 */
  Expired = 'expired'
}

export enum SupplierAgreementQueryBusinessEnum {
  /** 采购订单 */
  PurchaseOrder = 'purchaseOrder',
  /** 采购进货 */
  PurchaseIn = 'purchaseIn',
  /** 采购退货 */
  PurchaseBack = 'purchaseBack',
  /** 直配进货 */
  AllocIn = 'allocIn',
  /** 直配退货 */
  AllocBack = 'allocBack',
  /** 直送进货 */
  DirectIn = 'directIn',
  /** 直送退货 */
  DirectBack = 'directBack'
}

export enum SupplierAuthEnumEnum {
  /** 启用 */
  Enabled = 'enabled',
  /** 禁用 */
  Disabled = 'disabled'
}

export enum SupplierBackPriceEnum {
  /** 采购协议 */
  SupplierAgreement = 'supplierAgreement',
  /** 最新进价 */
  NewPurchasePrice = 'newPurchasePrice'
}

export enum SupplierBillStatusEnum {
  /** 已收货 */
  Received = 'received',
  /** 已退货 */
  Backed = 'backed',
  /** 已退货 */
  Sended = 'sended',
  /** 已完成 */
  Finished = 'finished'
}

export enum SupplierBillTypeEnum {
  /** 采购进货单 */
  PurchaseIn = 'purchaseIn',
  /** 采购退货单 */
  PurchaseBack = 'purchaseBack',
  /** 门店进货单(直送) */
  DirectIn = 'directIn',
  /** 门店进货单(直配) */
  AllocIn = 'allocIn',
  /** 门店退货单(直送) */
  DirectBack = 'directBack',
  /** 门店退货单(直配) */
  AllocBack = 'allocBack'
}

export enum SupplierContractStatusEnum {
  /** 未生效 */
  Init = 'init',
  /** 已生效 */
  Effected = 'effected',
  /** 已过期 */
  Expired = 'expired'
}

export enum SupplierFeeStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已审核 */
  Approved = 'approved',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Abolished = 'abolished'
}

export enum SupplierOrderTypeEnum {
  /** 创建时间降序 */
  CreatedDesc = 'createdDesc',
  /** 创建时间升序 */
  CreatedAsc = 'createdAsc',
  /** 更新时间降序 */
  LastModifiedDesc = 'lastModifiedDesc',
  /** 更新时间升序 */
  LastModifiedAsc = 'lastModifiedAsc',
  /** 编码降序 */
  IdDesc = 'idDesc',
  /** 编码升序 */
  IdAsc = 'idAsc'
}

export enum SupplierPrepayPayTypeEnum {
  /** 现金 */
  Cash = 'cash',
  /** 支票 */
  Check = 'check',
  /** 银行汇款 */
  BankRemit = 'bankRemit',
  /** 汇款 */
  Remit = 'remit',
  /** 其它 */
  Others = 'others'
}

export enum SupplierPrepayStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已审核 */
  Approved = 'approved',
  /** 已付款 */
  Payed = 'payed',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum SupplierPurchaseModelsEnum {
  /** 统采 */
  AllowHeadPurchase = 'allowHeadPurchase',
  /** 店采 */
  AllowStorePurchase = 'allowStorePurchase',
  /** 自采 */
  IsStoreIn = 'isStoreIn'
}

export enum SupplierPurchaseOrderStatusEnum {
  /** 待确认 */
  Unsure = 'unsure',
  /** 已确认 */
  Sure = 'sure',
  /** 已完成 */
  Finished = 'finished'
}

/** 供应商关系权限 */
export enum SupplierRelationRolesEnum {
  /** 采购单 */
  Purchase = 'purchase',
  /** 结算单 */
  Settle = 'settle',
  /** 费用单 */
  Fee = 'fee',
  /** 进退货单 */
  PurchaseInout = 'purchaseInout',
  /** 付款单 */
  Pay = 'pay',
  /** 联营销售(仅数量) */
  JointorCount = 'jointor_count',
  /** 联营销售(含金额) */
  JointorAmount = 'jointor_amount',
  /** 联营库存 */
  JointorStock = 'jointor_stock'
}

/** 供应商关系状态 */
export enum SupplierRelationStatusEnum {
  /** 申请中 */
  Applying = 'applying',
  /** 已授权 */
  Granted = 'granted',
  /** 已冻结 */
  Frozen = 'frozen'
}

/** 供应商台账变动类型 */
export enum SupplierSbChangeTypeEnum {
  /** 增加 */
  Add = 'add',
  /** 减少 */
  Sub = 'sub'
}

/** 供应商台账费用明细单据类型 */
export enum SupplierSbFeeBillTypeEnum {
  /** 费用单 */
  Fee = 'fee',
  /** 付款单 */
  Pay = 'pay',
  /** 收款单 */
  Receipt = 'receipt'
}

/** 供应商台账预付明细单据类型 */
export enum SupplierSbPrepayBillTypeEnum {
  /** 预付款单 */
  Prepay = 'prepay',
  /** 付款单 */
  Pay = 'pay'
}

/** 供应商台账应付明细单据类型 */
export enum SupplierSbShouldPayBillTypeEnum {
  /** 采购进货单 */
  PurchaseIn = 'purchaseIn',
  /** 采购退货单 */
  PurchaseBack = 'purchaseBack',
  /** 门店进货单(直送) */
  DirectIn = 'directIn',
  /** 门店进货单(直配) */
  AllocIn = 'allocIn',
  /** 门店退货单(直送) */
  DirectBack = 'directBack',
  /** 门店退货单(直配) */
  AllocBack = 'allocBack',
  /** 联营结算单 */
  Union = 'union',
  /** 付款单 */
  Pay = 'pay'
}

export enum SupplierSellUnionSettleInvoiceMarkTypeEnum {
  /** 已登记 */
  IsMark = 'isMark',
  /** 未登记 */
  NoMark = 'noMark'
}

export enum SupplierSellUnionSettleInvoiceTypeEnum {
  /** 普通发票 */
  General = 'general',
  /** 小发票 */
  Small = 'small'
}

export enum SupplierSellUnionSettlePayMarkTypeEnum {
  /** 已付款 */
  IsPay = 'isPay',
  /** 未付款 */
  NoPay = 'noPay',
  /** 部分付款 */
  PartPay = 'partPay'
}

export enum SupplierSellUnionSettleStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已审核 */
  Approved = 'approved',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum SupplierSettleBatchStatusEnum {
  /** 执行中 */
  Doing = 'doing',
  /** 已完成 */
  Done = 'done'
}

export enum SupplierSettleBatchTypeEnum {
  /** 按对账 */
  ByBill = 'byBill',
  /** 按销出账 */
  BySale = 'bySale'
}

/** 供应商结算模式 */
export enum SupplierSettleModeEnum {
  /** 总部结算 */
  Head = 'head',
  /** 门店结算 */
  Store = 'store'
}

export enum SupplierSettleTypeEnum {
  /** 按对账 */
  ByOrder = 'byOrder',
  /** 按销售 */
  BySale = 'bySale',
  /** 现结 */
  ByNow = 'byNow'
}

export enum SupplierStatusEnum {
  /** 未合作 */
  Nocooperate = 'nocooperate',
  /** 已合作 */
  Cooperated = 'cooperated',
  /** 已终止 */
  Terminated = 'terminated'
}

export enum SupplierTypeEnum {
  /** 经销 */
  Distributor = 'distributor',
  /** 联营 */
  Jointor = 'jointor',
  /** 租赁 */
  Rent = 'rent'
}

export enum SupportStatusEnum {
  /** 支持 */
  Supported = 'supported',
  /** 不支持 */
  UnSupport = 'unSupport'
}

export enum SystemIdEnumEnum {
  /** 总部 */
  Hq = 'hq',
  /** 门店 */
  Store = 'store',
  /** 收银机 */
  Pos = 'pos',
  /** 价格权限 */
  Price = 'price'
}

export enum TagTypeEnum {
  /** 新品 */
  NewGoods = 'newGoods',
  /** 7天内改价商品 */
  ChangePriceGoods = 'changePriceGoods',
  /** 未来7天促销商品 */
  PromotionGoods = 'promotionGoods'
}

export enum TargetsSortTypeEnum {
  /** 日均销量设置 */
  DailySales = 'dailySales',
  /** 畅销商品设置 */
  Salable = 'salable',
  /** 滞销商品设置 */
  Unsalable = 'unsalable'
}

export enum TemplateTypeEnum {
  /** 门店订货 */
  Require = 'require',
  /** 价签打印 */
  PriceTagPrint = 'priceTagPrint'
}

export enum ThirdPlatformDeliverLogisticsStatusEnum {
  /** 配送平台待发单 */
  UnSend = 'UN_SEND',
  /** 骑手待接单 */
  UnTake = 'UN_TAKE',
  /** 骑手已接单 */
  Took = 'TOOK',
  /** 骑手已到店 */
  InStore = 'IN_STORE',
  /** 配送中 */
  Delivering = 'DELIVERING',
  /** 配送完成 */
  Delivered = 'DELIVERED',
  /** 配送取消 */
  Canceled = 'CANCELED',
  /** 配送失败 */
  Fail = 'FAIL'
}

export enum TicketBusinessTypeEnum {
  /** 销售小票 */
  Sale = 'sale',
  /** 退货小票 */
  SaleReturn = 'saleReturn',
  /** 冲账小票 */
  OffsetReturn = 'offsetReturn'
}

export enum TicketTemplateDigitalNumberEnum {
  /** 仅数字 */
  Number = 'number',
  /** 条形码+数字 */
  DigitalNumber = 'digitalNumber'
}

export enum TicketTemplateDigitalNumberPositionEnum {
  /** 顶部 */
  Top = 'top',
  /** 底部 */
  Bottom = 'bottom'
}

export enum TicketTemplateFieldTypeEnum {
  /** 文本 */
  Text = 'text',
  /** 金额 */
  Amount = 'amount',
  /** 时间 */
  Date = 'date',
  /** 数值 */
  Number = 'number',
  /** 图片 */
  Image = 'image'
}

export enum TicketTemplateModeEnum {
  /** 快捷设置 */
  Simple = 'simple',
  /** 高级设置 */
  Advance = 'advance'
}

export enum TitleBizTypeEnum {
  /** 默认情况 */
  Default = 'default',
  /** 采购单 */
  PurchaseOrder = 'purchaseOrder',
  /** 商品档案 */
  GoodsInfo = 'goodsInfo'
}

export enum TransactionChannelTypeEnum {
  /** POS */
  Pos = 'pos',
  /** 自助POS */
  Selfpos = 'selfpos',
  /** 小程序 */
  Weimob = 'weimob',
  /** 美团 */
  Meituan = 'meituan',
  /** 饿了么 */
  Eleme = 'eleme',
  /** 京东到家 */
  Jddj = 'jddj'
}

export enum TransferBillClassEnum {
  /** 调出单 */
  Out = 'out',
  /** 调入单 */
  In = 'in'
}

export enum TransferReasonEnum {
  /** 缺货 */
  UnKnowWord14539eef = '缺货',
  /** 滞销 */
  UnKnowWord9f01a17e = '滞销',
  /** 线上转单 */
  UnKnowWord89ec1f1e = '线上转单',
  /** 调拨原因名称恶霸霸霸霸霸 */
  UnKnowWord049a5719 = '调拨原因名称恶霸霸霸霸霸',
  /** 0207版本测试1 */
  UnKnowWorde612f889 = '0207版本测试1',
  /** 调配 */
  UnKnowWord3a1e25a3 = '调配'
}

export enum UnderLineEnum {
  /** 无 */
  None = 'none',
  /** 上划线 */
  Top = 'top',
  /** 中划线 */
  Center = 'center',
  /** 下划线 */
  Bottom = 'bottom'
}

/** 礼品卡类型限用次数 */
export enum UseCountTypeEnum {
  /** 不限制 */
  Unlimited = 'unlimited',
  /** 限一次性使用 */
  One = 'one'
}

export enum UserFormTypeEnum {
  /** 一级列表 */
  ListType = 'listType',
  /** 详情页表格 */
  DetailForm = 'detailForm',
  /** 查询列表 */
  QueryForm = 'queryForm'
}

/** 效期库存查询排序枚举 */
export enum ValidDateInvOrderEnum {
  /** 默认排序 */
  CreatedDesc = 'createdDesc',
  /** 到效期升序 */
  ValidDateAsc = 'validDateAsc',
  /** 到效期降序 */
  ValidDateDesc = 'validDateDesc'
}

export enum WarehouseStatusEnum {
  /** 可用 */
  Enable = 'enable',
  /** 禁用 */
  Disable = 'disable'
}

export enum WeimobPaymentChannelEnum {
  /** 支付宝 */
  AliPay = 'aliPay',
  /** 微信支付 */
  WeiXin = 'weiXin',
  /** 银行卡 */
  Wm3 = 'wm3',
  /** 现金 */
  Cash = 'cash',
  /** 线下支付 */
  Wm5 = 'wm5',
  /** 无需支付 */
  Wm6 = 'wm6',
  /** 发送好友 */
  Wm7 = 'wm7',
  /** 线下聚合 */
  Wm8 = 'wm8',
  /** 百度聚合 */
  Wm9 = 'wm9',
  /** 自定义支付 */
  Wm10 = 'wm10',
  /** 境外支付 */
  Wm11 = 'wm11',
  /** 其它 */
  Ohters = 'ohters'
}

/** 微盟必填信息缺失 */
export enum WeimobRequiredLackEnum {
  /** 无图 */
  NoImage = 'noImage',
  /** 无分组 */
  NoGroup = 'noGroup'
}

export enum WholeSaleBackStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  ToApproved = 'toApproved',
  /** 已退货 */
  Returned = 'returned',
  /** 已作废 */
  OutOfDate = 'outOfDate',
  /** 已完成 */
  Finished = 'finished'
}

export enum WholeSaleOutInvoiceTypeEnum {
  /** 普通发票 */
  General = 'general',
  /** 小发票 */
  Small = 'small'
}

export enum WholeSaleOutSettleTypeEnum {
  /** 现结 */
  ByNow = 'byNow',
  /** 账期结算 */
  ByPeriod = 'byPeriod',
  /** 账户余额结算 */
  ByAccount = 'byAccount'
}

export enum WholeSaleOutStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  ToApproved = 'toApproved',
  /** 待发货 */
  ToDelivered = 'toDelivered',
  /** 待收货 */
  ToReceived = 'toReceived',
  /** 已收货 */
  Received = 'received',
  /** 已作废 */
  OutOfDate = 'outOfDate',
  /** 已完成 */
  Finished = 'finished'
}

export enum WholeSaleReportDimensionEnum {
  /** 门店 */
  Store = 'store',
  /** 分类 */
  Sort = 'sort',
  /** 商品 */
  Goods = 'goods',
  /** 客户 */
  Customer = 'customer',
  /** 明细 */
  Detail = 'detail',
  /** 仓库 */
  Wrh = 'wrh'
}

/** 批发销售统计报表发货主体类型 */
export enum WholeSaleSenderSubjectEnum {
  /** 门店 */
  Store = 'store',
  /** 仓库 */
  Wrh = 'wrh'
}

export enum WholesaleBillTypeEnum {
  /** 仓库批发单 */
  WrhOut = 'wrhOut',
  /** 仓库批发退货单 */
  WrhBack = 'wrhBack',
  /** 门店批发单 */
  Out = 'out',
  /** 门店批发退货单 */
  Back = 'back'
}

/** 批发订单转单类型 */
export enum WholesaleOrderOutTypeEnum {
  /** 仓库批发单 */
  Wrh = 'wrh',
  /** 门店批发单 */
  Store = 'store'
}

/** 批发订单结算类型 */
export enum WholesaleOrderSettleTypeEnum {
  /** 账期结算 */
  ByPeriod = 'byPeriod',
  /** 现结 */
  ByNow = 'byNow',
  /** 账户余额结算 */
  ByAccount = 'byAccount'
}

/** 批发订单来源单据类型 */
export enum WholesaleOrderSourceClassEnum {
  /** 手工创建 */
  Manual = 'manual',
  /** 订货商城 */
  Omall = 'omall',
  /** 导入 */
  Import = 'import'
}

/** 批发订单状态 */
export enum WholesaleOrderStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Submited = 'submited',
  /** 已审核 */
  Approved = 'approved',
  /** 进行中 */
  Doing = 'doing',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum WholesaleSettleBatchStatusEnum {
  /** 待开始 */
  Init = 'init',
  /** 进行中 */
  Doing = 'doing',
  /** 已完成 */
  Finished = 'finished',
  /** 失败 */
  Failed = 'failed'
}

export enum WholesaleSettleStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已审核 */
  Approved = 'approved',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Aborted = 'aborted'
}

/** WMS启用枚举 */
export enum WmsConfigEnum {
  /** 启用 */
  Enable = 'enable',
  /** 不启用 */
  Disabled = 'disabled'
}

/** 总库存区间查询类型枚举 */
export enum WmsInvCheckAllStockQueryTypeEnum {
  /** 库存数量<0 */
  GreaterZero = 'greaterZero',
  /** 库存数量>0 */
  LetterZero = 'letterZero',
  /** 库存数量=0 */
  EqualZero = 'equalZero',
  /** 库存数量≠0 */
  NotEqualZero = 'notEqualZero',
  /** 自定义 */
  Custom = 'custom'
}

export enum WrhAllocBackStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待退货 */
  Returning = 'returning',
  /** 待收货 */
  Sended = 'sended',
  /** 已收货 */
  Returned = 'returned',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Abolished = 'abolished'
}

/** 门店退仓通知单状态枚举 */
export enum WrhAllocNoticeBackStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待收货 */
  Sended = 'sended',
  /** 收货中 */
  Receiving = 'receiving',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Aborted = 'aborted'
}

/** 配货通知单业务类型 */
export enum WrhAllocOrderBusinessTypeEnum {
  /** 批发 */
  Wholesale = 'wholesale',
  /** 配货 */
  AllocOut = 'allocOut'
}

export enum WrhAllocOrderSourceEnum {
  /** 提单 */
  Lading = 'lading',
  /** 手工 */
  Creation = 'creation',
  /** 分货 */
  Allot = 'allot',
  /** 批发订单 */
  WholeSaleOrder = 'wholeSaleOrder',
  /** 导入 */
  Import = 'import',
  /** 合并 */
  Merge = 'merge'
}

export enum WrhAllocOrderStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待配货 */
  Submited = 'submited',
  /** 配货中 */
  Allocating = 'allocating',
  /** 已配货 */
  Allocated = 'allocated',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum WrhAllocOutRuleEnum {
  /** 门店等级 */
  StoreGrade = 'storeGrade',
  /** 先到先得 */
  FirstCome = 'firstCome',
  /** 按比例分配 */
  Proration = 'proration'
}

export enum WrhAllocOutSourceEnum {
  /** 新增 */
  Create = 'create',
  /** 导入 */
  Import = 'import',
  /** 分货 */
  Allot = 'allot',
  /** 提单 */
  Lading = 'lading',
  /** 越库 */
  Cross = 'cross',
  /** WMS */
  Wms = 'WMS',
  /** 合并 */
  Merge = 'merge',
  /** 复制 */
  Copy = 'copy'
}

/** 仓库配货模式 */
export enum WrhDistModeEnum {
  /** 零售云配送 */
  RetailCloud = 'retailCloud',
  /** WMS */
  Wms = 'wms'
}

/** 仓库零售云配货类型 */
export enum WrhDistTypeEnum {
  /** 普通配送 */
  Normal = 'normal',
  /** 分拣配送 */
  Picking = 'picking'
}

export enum WrhGainStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已同意 */
  Approved = 'approved',
  /** 已拒绝 */
  Rejected = 'rejected'
}

export enum WrhLockStatusEnum {
  /** 已冻结 */
  Locked = 'locked',
  /** 未冻结 */
  Unlock = 'unlock'
}

export enum WrhLossStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已同意 */
  Approved = 'approved',
  /** 已拒绝 */
  Rejected = 'rejected'
}

export enum WrhOutStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待发货 */
  Approved = 'approved',
  /** 待收货 */
  Sended = 'sended',
  /** 已收货 */
  Received = 'received',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Abolish = 'abolish'
}

/** 仓库商品拣货类型 */
export enum WrhPickingTypeEnum {
  /** 标品拣货 */
  Sandard = 'sandard',
  /** 称重分拣 */
  Weight = 'weight'
}

/** 仓库加工单状态 */
export enum WrhProcessStatusEnum {
  /** 待审核 */
  Init = 'init',
  /** 已审核 */
  Approved = 'approved'
}

export enum WrhTransferStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Aborted = 'aborted'
}

/** 仓库领用核算方式 */
export enum WrhUseAccountTypeEnum {
  /** 核算部门 */
  Dept = 'dept',
  /** 门店 */
  Store = 'store'
}

/** 仓库领用状态 */
export enum WrhUseStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 已同意 */
  Agreed = 'agreed',
  /** 已拒绝 */
  Rejected = 'rejected'
}

export enum WrhWholeSaleBackStatusEnum {
  /** 待提交 */
  Init = 'init',
  /** 待退货 */
  Applyed = 'applyed',
  /** 已退货 */
  Returned = 'returned',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Aborted = 'aborted'
}

export enum WrhWholeSaleOutModifyStatusEnumEnum {
  /** 待提交 */
  Init = 'init',
  /** 待审核 */
  Applyed = 'applyed',
  /** 待发货 */
  Approved = 'approved',
  /** 待收货 */
  Sended = 'sended',
  /** 已收货 */
  Received = 'received',
  /** 已完成 */
  Finished = 'finished',
  /** 已作废 */
  Aborted = 'aborted'
}
