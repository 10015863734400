import { getMerchantPackType } from './utils/utils';
import { MerchantPackTypeEnum } from '@/dataModel/dict/enum';
/**
 *  系统配置文件
 */
const config = {
  system: 'pos', // 用于路由url得子应用名称
  systemId: 'wosheading' // 用于权限code的前缀
};

/** 处于本地开发环境 */
export const isDevelopment = process.env.NODE_ENV === 'development';

/** 是否 Qa 环境 */
export const isQa = window.location.hostname === 'master.saas.weimobqa.com';

/** 是否 生产 环境 */
export const isProd = window.location.hostname === 'master.weimob.com';

/** 组件类名前缀覆盖 */
export const antComponentPrefix = 'cx';

/** 海鼎子应用顶层节点类名 */
export const appRootClassName = 'cx-heading';

/** 微盟虫洞后台 accessKey */
export const WORMHOLE_ACCESS_KEY = '100112863';

/** wos微盟虫洞后台 accessKey */
export const WORMHOLE_ACCESS_KEY_WOS = isProd ? 100117097 : 12023349;

/** 请求路径前缀 */
// export const apiUrl = '/api3/heading/cx/main';
// export const apiUrl = '/api3/wosheading/cx/main';
// export const posUrl = '/api3/wosheading/cx/posconfig';
// export const cardUrl = '/api3/wosheading/cx/card';
// export const settingUrl = '/api3/wosheading/cx/posdata';
// export const onlineOrderUrl = '/api3/wosheading/cx/online/order';
// export const onlineGoodsUrl = '/api3/wosheading/cx/online/goods';
export const apiUrl = 'https://api-gw1.fatdog100.com:8445/cx-main/heading/cx/main';
export const posUrl = 'https://api-gw1.fatdog100.com:8445/cx-pos-config/heading/cx/posconfig';
export const cardUrl = 'https://api-gw1.fatdog100.com:8445/cx-card/heading/cx/card';
export const onlineOrderUrl = 'https://api-gw1.fatdog100.com:8445/cx-online-order/heading/cx/online/order';
export const onlineGoodsUrl = 'https://api-gw1.fatdog100.com:8445/cx-online-goods/heading/cx/online/goods';
export const settingUrl = 'https://api-gw1.fatdog100.com:8445/cx-pos-data/heading/cx/posdata';
export const VITE_FETCH_BASE_URL = 'https://api-gw1.fatdog100.com';
export const UPLOAD_FILE_URL = 'https://api-gw1.fatdog100.com:8445'

/** cdn 资源静态路径前缀 */
export const cdnPrefix: string = {
  // qa
  'master.saas.weimobqa.com': 'https://cdn2.weimob.com/saas/@qa/saas-fe-heading-cxweb-stc',
  // 生产
  'master.weimob.com': 'https://cdn2.weimob.com/saas/@assets/saas-fe-heading-cxweb-stc'
}[window.location.hostname];

/**系统中数值的范围 */
export const numericalRange = {
  overMaxMessage: '超出最大数量',
  /**数量 */
  qty: {
    /**散称 */
    bulkRange: {
      min: 0,
      max: 99999
    },
    /**非散称 */
    exceptBulkRange: {
      min: 0,
      max: 99999
    }
  }
};

/**系统中查询日期的设定 */
export const queryDateInfo = {
  /** 可以连续选择几个月 */
  continuousMonthNum: 3,
  /**必填警告 */
  requiredMessage: '请选择查询日期',
  /**日期是否可以清空 暂定不能，如果设定为可以需要去兼容 1清空日期后切换tab栏的查询 2接口要求日期必填 */
  allowClear: false
};

/** 表格列最小宽度 */
export const maxColumnWidth = 120;

/** 设计规范 Table 输入框等控件宽度，适用于 Input/Select 等基于这类组件的表单控件，必须指定宽度 style.width */
export const tableInputWith = {
  /** 常规 */
  normal: 88,
  /** 库存单位/订货单位 等 带单位的输入框 */
  stockUnit: 98,
  /** 单位选择下拉框 */
  unitSelect: 98,
  /** 金额/价格 输入框 */
  amount: 96,
  /** 数量 */
  qty: 82,
  /** 供应商信息 */
  supplier: 160,
  /**商品编码 */
  goodsId: 134,
  /**商品条码 */
  barCode: 108,
  /**商品plu码 */
  plu: 106
};

/** 一级列表单元格固定宽度值，常用于 column.width 配置，本宽度不包含单元格 padding */
export const cellFixedWidth = {
  /** 序号 */
  orderNo: 26,
  /** 商品信息 */
  goodsInfo: 200,
  /** 门店信息 */
  storeInfo: 160,
  /** 退货原因 */
  returnGoodsReason: 140,
  /** 地址 */
  address: 140,
  /** 备注 */
  remark: 140,
  /** 员工(采购员/业务员 等) */
  user: 96,
  /** 仓库 */
  warehouse: 140,
  /** 联系人 */
  contactor: 134,
  /** 客户信息 */
  customer: 160,
  /** 供应商信息 */
  supplier: 160,
  /** 操作信息(创建信息/更新信息) */
  operateInfo: 144,
  /**日期 */
  date: 112 + 10, // 存在秒时会触发省略号提示
  /**期号  */
  settleNo: 64,
  /** 单据编码 */
  billId: 100,
  largeWidth: 240
};

/**
 * 报表常见字段的列宽 包含padding，width = 内容宽度 + 2*padding，padding为8px
 *
 * */
export const cellWidth = {
  /** 日期 */
  date: {
    /** YYYY/MM/DD */
    day: 86,
    /** YYYY/MM/DD HH:mm */
    minute: 122
  },
  /** 商品 */
  goods: {
    /** 名称 */
    goodsName: 218,
    /** 条码 */
    goodsBarcode: 108,
    /** 编码 */
    goodsId: 98,
    /** 大类 */
    bigSort: 90,
    /** 中类 */
    mediumSort: 90,
    /** 小类 */
    smallSort: 90,
    /** 大类携带id */
    bigSortWithId: 112,
    /** 中类携带id */
    mediumSortWithId: 126,
    /** 小类 携带id*/
    smallSortWithId: 140,
    /** 商品状态 */
    goodsStatus: 68,
    /** 销量 */
    goodsSellQty: 70
  },
  /** 供应商 */
  supplier: {
    /** 名称 */
    supplierName: 218
  },
  /** 门店 */
  store: {
    /** 名称 */
    storeName: 218
  },
  /** 仓库 */
  warehouse: {
    /** 名称 */
    warehouseName: 142
  },
  /** 金额/优惠金额 */
  amount: {
    /** 默认 */
    default: 90,
    /** 冲账 */
    offsetReturn: 90,
    /** 退货 */
    returnGoods: 90,
    /** 手工改价 */
    manualChangePrice: 90,
    /** 手工折扣 */
    manualDiscount: 90,
    /** 整单折扣 */
    fullDiscount: 90,
    /** 手工赠品 */
    manualGift: 90,
    /** 挂单 */
    handUp: 90,
    /** 整单取消 */
    fullCancel: 90,
    /** 删除商品 */
    deleteGoods: 90,
    /** 退储值 */
    returnValue: 90,
    /** 总操作 */
    totalOperation: 90,
    /** 销售额 */
    saleAmount: 90,
    /** 销售成本 */
    saleCost: 90,
    /** 销售毛利额 */
    saleGross: 90,
    /** 零售价 */
    retailPrice: 70
  },
  /** 共有通用的 */
  common: {
    /** 次数 */
    count: '42'
  },
  /** 单元格padding 非报表类的表格可能需要减去这个padding距离 */
  cellPadding: 2 * 8
};

/**表格的单元格两边的padding 2 * 8 */
export const tableCellPadding = 2 * 8;

/**固定列宽模式下 表格的单元格会多出4px的margin-right 需要额外补偿 */
export const tableCellErrorMargin = 4;

/**最小列宽模式下 单元格的宽度 = 需要的宽度减掉4px*/
export const tableCellReduceWitdh = 4;

export const cellWidthSetting = {
  /** 商品 */
  goods: {
    /** 商品名称 */
    goodsName: {
      width: getMerchantPackType() === MerchantPackTypeEnum.Fresh ? 90 : 160,
      minWidth:
        getMerchantPackType() === MerchantPackTypeEnum.Fresh
          ? 90 - tableCellReduceWitdh
          : 160 - tableCellReduceWitdh - 32,
      maxWdith: 204,
      ellipsis: false
    },
    /** 规格 */
    goodsSpec: {
      width: 56 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      minWidth: 56 - tableCellReduceWitdh,
      ellipsis: false
    },
    /** 编码 */
    goodsId: {
      width: 92 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 条码 */
    goodsBarcodes: {
      width: 100 + tableCellPadding + tableCellErrorMargin,
      maxWdith: 120 + tableCellPadding + tableCellErrorMargin,
      ellipsis: true
    },
    /** PLU码 */
    goodsPlu: {
      width: 40 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 单位 */
    goodsMuint: {
      width: 42,
      maxWdith: null,
      ellipsis: true
    },
    /** 销售单位 */
    saleGoodsMuint: {
      width: 80 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 商品状态 */
    goodsStatus: {
      width: 54 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true,
      minWidth: 54 - tableCellReduceWitdh
    },
    /** 配送方式 */
    goodsSend: {
      width: 70,
      maxWdith: null,
      ellipsis: true
    },
    /** 经营方式 */
    goodsMode: {
      width: 70,
      maxWdith: null,
      ellipsis: true
    },
    /** 商品类型 */
    goodsType: {
      width: 62 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 税率 */
    goodsTaxRate: {
      width: 64 + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 毛利率  */
    goodsGrossRate: {
      width: 64 + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    }
  },
  /** 供应商 */
  supper: {
    /** 名称 */
    supperName: {
      width: 218,
      maxWdith: 202,
      minWidth: 96 - tableCellReduceWitdh,
      ellipsis: false
    },
    /** 编码 */
    supperId: {
      width: 36 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 类型 */
    supperType: {
      width: 82,
      maxWdith: null,
      ellipsis: true
    },
    /** 状态 */
    supperStatus: {
      width: 66,
      maxWdith: null,
      ellipsis: true
    },
    /** 编码+名称 */
    supperIdAndName: {
      width: 96,
      maxWdith: 202,
      minWidth: 96 - tableCellReduceWitdh,
      ellipsis: false
    }
  },
  /** 客户 */
  customer: {
    /** 名称 */
    customerName: {
      width: 130,
      maxWdith: null,
      ellipsis: false
    },
    /** 编码 */
    customerId: {
      width: 52,
      maxWdith: null,
      ellipsis: false
    },
    /** 状态 */
    customerStatus: {
      width: 68,
      maxWdith: null,
      ellipsis: true
    },
    /** 编码+名称 */
    customerIdAndName: {
      width: 180 + tableCellPadding + tableCellErrorMargin,
      minWidth: 196,
      maxWdith: null,
      ellipsis: true
    }
  },
  /** 门店 */
  store: {
    /** 名称 */
    storeName: {
      width: 124 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 编码 */
    storeId: {
      width: 36 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      minWidth: 36 - tableCellReduceWitdh,
      ellipsis: false
    },
    /** 编码+名称 */
    storeNameAndId: {
      width: 160 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 门店店型 */
    storeModel: {
      width: 96 + tableCellPadding + tableCellErrorMargin,
      maxWidth: null,
      ellipsis: true
    }
  },
  /** 人员 */
  person: {
    /** 姓名 */
    name: {
      minWidth: 50 - tableCellReduceWitdh,
      ellipsis: false
    }
  },
  /** 分类 */
  sort: {
    /** 大类名称 */
    bigName: {
      width: 52 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: false
    },
    /** 大类编码 */
    bigId: {
      width: 20 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 中类名称 */
    middleName: {
      width: 68,
      maxWdith: null,
      minWidth: 52 - tableCellReduceWitdh,
      ellipsis: false
    },
    /** 中类编码 */
    middleId: {
      width: 34 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 小类名称 */
    smallName: {
      width: 92,
      maxWdith: null,
      minWidth: 76 - tableCellReduceWitdh,
      ellipsis: false
    },
    /** 小类编码 */
    smallId: {
      width: 48 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 大类编码+名称 */
    bigNameAndId: {
      width: 88,
      maxWdith: null,
      minWidth: 72 - tableCellReduceWitdh,
      ellipsis: false
    },
    /** 中类编码+名称 */
    middleNameAndId: {
      width: 102,
      maxWdith: null,
      minWidth: 86 - tableCellReduceWitdh,
      ellipsis: false
    },
    /** 小类编码+名称 */
    smallNameAndId: {
      width: 140,
      maxWdith: null,
      minWidth: 124 - tableCellReduceWitdh,
      ellipsis: false
    }
  },
  /** 仓库 */
  wrh: {
    /** 名称 */
    wrhName: {
      width: 100 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 编码 */
    wrhId: {
      width: 36 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 编码+名称 */
    wrhNameAndId: {
      width: 136 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    }
  },
  /** 单头属性 */
  tableHeade: {
    /** 序号 */
    orderNum: {
      width: 26 + tableCellPadding + tableCellErrorMargin,
      ellipsis: true
    },
    /** 单号 */
    billNo: {
      width: 110 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 期号  */
    dateNo: {
      width: 60,
      maxWdith: null,
      ellipsis: true
    },
    /** 状态 */
    status: {
      width: 68,
      maxWdith: null,
      ellipsis: true
    },
    /** 类型 */
    type: {
      width: 84,
      maxWdith: null,
      ellipsis: true
    },
    /** 品项数 */
    qty: {
      width: 56,
      maxWdith: null,
      ellipsis: true
    },
    /** 备注 */
    remark: {
      width: 140 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    }
  },
  /** 数量 */
  num: {
    /** 销量 */
    saleNum: {
      width: 56 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 次数 */
    time: {
      width: 44,
      maxWdith: null,
      ellipsis: true
    }
  },
  /** 价格+金额  */
  price: {
    /** 进价类，如协议价、进货价、成本价等 万小数点后4位 */
    numFixFour: {
      width: 62 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    /** 售价类，如零售价、会员价、批发价 */
    numFixTwo: {
      width: 54 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    }
  },
  /** 金额 */
  amount: {
    /** 金额类，如销售额等 百万位+小数点后两位 */
    numFixTwo: {
      width: 70,
      maxWdith: null,
      ellipsis: true
    }
  },
  /** 日期 */
  date: {
    /** YYYY-MM-DD */
    date: {
      width: 68 + tableCellPadding + tableCellErrorMargin,
      maxWidth: null,
      ellipsis: true
    },
    /** YYYY-MM-DD HH:mm  */
    dateMinute: {
      width: 110 + tableCellPadding + tableCellErrorMargin,
      maxWidth: null,
      ellipsis: true
    },
    /** YYYY-MM-DD HH:mm:ss  */
    dateSecond: {
      width: 118 + tableCellPadding + tableCellErrorMargin,
      maxWidth: null,
      ellipsis: true
    },
    /** 范围 */
    dateRange: {
      width: 202 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    }
  },
  /** 操作信息 */
  operation: {
    /** 制单信息 */
    makeTime: {
      width: 110 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    createTime: {
      width: 110 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    },
    updateTime: {
      width: 110 + tableCellPadding + tableCellErrorMargin,
      maxWdith: null,
      ellipsis: true
    }
  },
  /** 促销活动  */
  promoteActive: {
    /** 活动类型 */
    promoteType: {
      width: 72,
      maxWdith: null,
      ellipsis: true
    },
    /** 活动说明 */
    promoteDesc: {
      width: 120,
      maxWdith: null,
      ellipsis: true
    }
  }
};
/** table中的Popover 层级高度 */
export const tablePopoverIndex = 60;

/** 查询报表合计列 三个字及以下带括号（举例：毛利额 (含税)）和不带括号的列（举例：配进税额）的列宽为114px，含padding */
export const ReportSummaryColumnWidth = {
  amount: 94
};

/**
 * 状态颜色
 */
export const statusColorMap = {
  purple: ['待提交', '待接单', '已提交', '未出售', '待启动'],
  orange: [
    '待审核',
    '待配货',
    '待发货',
    '待开始',
    '待付款',
    '部分付款',
    '待生效',
    '待确认',
    '冻结中',
    '已挂失',
    '暂停营业',
    '未合作',
    '未授权',
    '未生效',
    '未开始',
    '直送',
    '供应商待确认',
    '待提货',
    '已接单',
    '已提货',
    '待自提',
    '待配送',
    '未处理',
    '待复盘',
    '客户待确认',
    '部分收款',
    '待收款',
    '预设',
    '待拣货',
    '待成团',
    '申请中',
    '待退货',
    '启动中',
    '完成中'
  ],
  tangerine: ['待收货', '启动完成'],
  blue: [
    '已发出',
    '已收货',
    '已审核',
    '进行中',
    '使用中',
    '营业中',
    '正常',
    '生效中',
    '直配',
    '已发货',
    '配送中',
    '已开通',
    '已开启',
    '首选',
    '启用中',
    '审核通过',
    '立即新增',
    '已退货',
    '已拣货',
    'wms',
    '波次确认',
    '配货中'
  ],
  green: [
    '在线',
    '已同意',
    '已完成',
    '已结束',
    '已处理',
    '已生效',
    '已付款',
    '已确认',
    '已入库',
    '新品',
    '已启用',
    '已合作',
    '启用',
    '导出成功',
    '已出账',
    '未使用',
    '休息中',
    '已盘入',
    '供应商已确认',
    '支持',
    '已收款',
    '已上架',
    '已自提',
    '配送完成',
    '已激活',
    '允许盘入',
    '已复盘',
    '客户已确认',
    '成功',
    '传秤中',
    '已上架',
    '试算中',
    '同意',
    '已授权',
    '拣货',
    '已配货',
    '已打印'
  ],
  red: [
    '已拒绝',
    '已过期',
    '停用',
    '已停用',
    '打回冲盘',
    '导出失败',
    '未出账',
    '未收款',
    '异常',
    '未完成',
    '打回重盘',
    '已禁用',
    '已下架',
    '已退款',
    '拒绝盘入并打回重新盘',
    '失败',
    '拒绝',
    '已退货',
    '启动失败'
  ],
  gray: [
    '已作废',
    '已关闭',
    '已取消',
    '停业',
    '离线',
    '断开',
    '已终止',
    '未开通',
    '未启用',
    '已冻结',
    '已使用',
    '即将上线',
    '未打印'
  ]
};

export default {
  ...config
};

export const commonText = {
  noGoodsRepeat: '商品已添加,请重新输入选择！'
};

export enum JumpRouterTypeEnum {
  /** 供应链 */
  Wosheading = 'wosheading',
  /** 店务 */
  Wosheadingsop = 'wosheadingsop',
  /** 报表 */
  Wosheadingreport = 'wosheadingreport',
  /** 收银 */
  Wosheadingpos = 'wosheadingpos'
}

export enum ProductIdEnum {
  /** 供应链 */
  Wosheading = '507277',
  /** 店务 */
  Wosheadingsop = '507300',
  /** 收银 */
  Wosheadingpos = '507271'
}
