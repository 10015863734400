import { createRouteConfig, dynamicWrapper } from '@/utils/utils';
import { headquartersBackend } from '../routers.map';

const { ticketTemplate, sale, secscreen, paymentMethod, posSettings, keyboardSettings, wholeOrderDiscountRules } =
  headquartersBackend.retail;
const routerConfig = (app: any) => [
  {
    ...createRouteConfig('小票模板', ticketTemplate.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/TicketTemplate/List'))
  },
  {
    ...createRouteConfig('编辑模板', ticketTemplate.edit),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/TicketTemplate/Edit'))
  },
  {
    ...createRouteConfig('新增模板', ticketTemplate.add),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/TicketTemplate/Edit'))
  },
  {
    ...createRouteConfig('查看参数说明', ticketTemplate.explain),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/TicketTemplate/Explain'))
  },
  {
    ...createRouteConfig('销售面板设置', sale.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/Sale/List'))
  },
  {
    ...createRouteConfig('设置模板商品', sale.edit),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/Sale/Edit'))
  },
  {
    ...createRouteConfig('设置模板商品', sale.saleSelfEdit),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/Sale/SaleSelfEdit'))
  },
  {
    ...createRouteConfig('模板详情', sale.saleSelfDetail),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/Sale/SaleSelfDetail'))
  },
  {
    ...createRouteConfig('模板详情', sale.detail),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/Sale/Detail'))
  },
  {
    ...createRouteConfig('副屏设置', secscreen.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/Secscreen/List'))
  },
  {
    ...createRouteConfig('广告方案详情', secscreen.detail),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/Secscreen/Detail'))
  },
  {
    ...createRouteConfig('新增广告方案', secscreen.add),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/Secscreen/Add'))
  },
  {
    ...createRouteConfig('编辑广告方案', secscreen.edit),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/Secscreen/Edit'))
  },
  {
    ...createRouteConfig('素材管理', secscreen.materialManage),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/Secscreen/MaterialManage'))
  },
  {
    ...createRouteConfig('付款方式', paymentMethod.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/PaymentMethod/List'))
  },
  {
    ...createRouteConfig('收银机参数设置', posSettings.index),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/PosSettings'))
  },
  {
    ...createRouteConfig('键盘设置', keyboardSettings.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/KeyboardSettings/index'))
  },
  {
    ...createRouteConfig('打折权管理', wholeOrderDiscountRules.list),
    component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/WholeOrderDiscountRules/List'))
  },
  {
    ...createRouteConfig('整单折扣流水', wholeOrderDiscountRules.detail),
    component: dynamicWrapper(
      app,
      [],
      () => import('@/pages/HeadquartersBackend/Retail/WholeOrderDiscountRules/Detail')
    )
  }
  /*  {
     ...createRouteConfig('收银流水查询', cashierSearch.index),
     component: dynamicWrapper(app, [], () => import('@/pages/HeadquartersBackend/Retail/CashierSearch'))
   }, */
];

export default routerConfig;
