const ctx = (require as any).context('./', false, /\.ts$/);

const routeModulesMap: Record<string, any> = ctx.keys().reduce((prev, path: string) => {
  if (path.includes('./index.ts')) return prev;

  const key = path.slice(2, -3);
  return { ...prev, [key]: ctx(path) };
}, {});

export default app =>
  Object.values(routeModulesMap)
    .map(createRouteConfig => createRouteConfig.default(app))
    .flat();
