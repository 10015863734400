import { RedirectProps } from 'react-router';
import { headquartersBackend, storeBackend, supplierBackend } from './routers.map';
import { createRouteConfig, dynamicWrapper, jumpRouter } from '../utils/utils';
import NotFound from './404';
import config from '@/config';
import Demo from './demo';
import BusinessPages from './routers.pages';

const isDev = process.env.NODE_ENV === 'development';

const backendRedirect: {
  path: string;
  exact: boolean;
  redirect: RedirectProps;
}[] = [
  {
    path: '/headquarters-backend',
    exact: true,
    redirect: { to: jumpRouter(headquartersBackend.retail.posSettings.index) }
  },
  {
    path: '/store-backend',
    exact: true,
    redirect: { to: jumpRouter(storeBackend.equipment.ews.list) }
  },
  {
    path: '/supplier-backend',
    exact: true,
    redirect: { to: jumpRouter(supplierBackend.order.purchaseOrder.list) }
  }
];

/**
 * 主路由配置
 *
 * path 路由地址
 * name 页面名称(面包屑显示)
 * menuCode 菜单权限code 格式app.路由模块.页面  例如 app.list.cardList
 * component 组件 采用动态组件的方式引入，dynamicWrapper（app,models,component) app固定值，models为数组可写多个，component 组件() => import('xxx'))
 */
export const getNavData = (app: any) => {
  const pageRoutes = [
    ...backendRedirect,
    /** 以下是各个模块的路由文件，app必
     * 须传入，否则路由无效 */
    ...BusinessPages(app),
    /** 404 路由必须放在最后 */
    NotFound(app)
  ];

  if (isDev) {
    pageRoutes.unshift(...Demo(app));
  }

  // console.log(
  //   '  ______  ',
  //   JSON.stringify(
  //     pageRoutes.map(({ name, menuCode, path }) => ({ name, menuCode, path })),
  //     null,
  //     2
  //   )
  // );
  return [
    {
      path: `/bos/products/${config.system}/:bosId/store`,
      name: '',
      menuCode: '',
      layout: 'BasicLayout', // layout 固定值 不可修改
      component: dynamicWrapper(app, [], () => import('@/layouts/BasicLayout')),
      children: [
        {
          ...createRouteConfig('切换门店', '/store-change'),
          component: dynamicWrapper(app, [], () => import('@/pages/StoreChange'))
        }
      ]
    },
    {
      // path: `/app/${config.system}/:pid/:storeId?(/)?/`, // 入口文件
      path: `/bos/products/${config.system}/:bosId/:key?(/)?/`, // 入口文件
      layout: 'BasicLayout', // layout 固定值 不可修改
      name: '',
      menuCode: '',
      component: dynamicWrapper(app, [import('@/models/global')], () => import('@/layouts/BasicLayout')),
      children: pageRoutes
    }
  ];
};
