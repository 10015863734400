import { dynamicWrapper, createRouteConfig } from '@/utils/utils';
import { storeBackend } from '../routers.map';

const { system } = storeBackend;

const routerConfig = (app: any) => [
  {
    ...createRouteConfig('POS操作日志', system.posLog.index),
    component: dynamicWrapper(app, [], () => import('@/pages/StoreBackend/System/PosLog'))
  }
];

export default routerConfig;
